import React, { ChangeEvent, Fragment, ReactElement, useEffect } from "react";
import {
    formStates, getFormattedDate, getValueLoc, inicializePostalCode, validateAlphaNumeric, CapitalizefirstLetter,
    validateFormEmpty, validateNumberFormEmpty, tariffPaqueteria, TariffPaqueteriaLocations
} from "aseguisShared"
import NumberFormat from 'react-number-format';
import PostalCodesAutocomplete from "../PostalCodes/PostalCodesAutocomplete";
import Swal from "sweetalert2";

interface infoToPaqueteriaTariff {
    allTariff: tariffPaqueteria[]
    formTariff: tariffPaqueteria
    setFormTariff: (tariff: tariffPaqueteria) => void

    from: string
    formState: number

    setErrorForm: (value: boolean) => void,
}

function PaqueteriaTariffs(props: infoToPaqueteriaTariff): ReactElement {

    function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
        props.setFormTariff({
            ...props.formTariff,
            description: value
        });
    }

    function thereIsErrorOnTariffs(): boolean {
        for (var tariff of props.formTariff.tariffs) {
            if (tariff.hasta === 0 || tariff.value === 0) {
                return true;
            }
        }
        return false;
    }

    const validateLocations = (TariffPaqueteriaLocations: TariffPaqueteriaLocations[]) => {
        for (const location of TariffPaqueteriaLocations) {
            if (getValueLoc(location.locationType, location.postalCode).length < 2) {
                return true
            }
        }
        if (TariffPaqueteriaLocations.length < 1) return true
        return false
    }

    useEffect(() => {
        if (
            ((props.formState !== formStates.none)) &&
            (
                (validateLocations(props.formTariff.tariffLocations)) ||
                (props.formTariff.description.length < 2) ||
                (props.formTariff.tariffs.length === 0) ||
                (thereIsErrorOnTariffs() === true)
            )
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [props.formTariff, thereIsErrorOnTariffs])
    
    const listTariffs: JSX.Element[] = [...props.allTariff].reverse().map((tariff, index) => {
        if (tariff && props.formTariff && tariff.id === props.formTariff.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={tariff.id} style={{ height: "40px" }}>
                    <td><input
                        type="text"
                        onChange={onValueChanged}
                        value={props.formTariff.description}
                        className={validateFormEmpty(props.formTariff.description, 2)}
                        name="description" />
                    </td>
                    <td>
                        <label>{getFormattedDate(tariff.fecAlta)}</label>
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={(tariff && props.formTariff && tariff.id === props.formTariff.id) ? "table-row selected" : "table-row"}
                    style={{ height: "40px", cursor: "pointer" }}
                    onClick={() => {
                        if (props.formState === formStates.add) {
                            props.setFormTariff({
                                ...tariff,
                                id: props.allTariff.length + 1,
                                description: "",
                                fecAlta: new Date(),
                            });
                        } else {
                            props.setFormTariff(tariff);
                        }
                    }}
                    key={tariff.id}
                >
                    <td>{tariff.description}</td>
                    <td>{getFormattedDate(tariff.fecAlta)}</td>
                </tr>
            )
        }
    });

    const listTariffsForm: JSX.Element[] = [...props.formTariff.tariffs].map((tariff, index) => {
        return (
            <tr
                className="table-row" key={index} style={{ height: "40px" }}>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={0}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={tariff.hasta}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={""} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={tariff.value}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"} />
                </td>
            </tr>
        )
    });

    const addFormTariff: JSX.Element[] = [...props.formTariff.tariffs].map((tariff, index) => {
        return (
            <tr className="table-row"
                style={{ height: "40px" }}
                key={index}
            >
                <td>
                    <NumberFormat
                        placeholder="0"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" "}
                        value={tariff.hasta === 0 ? "" : tariff.hasta}
                        className={validateNumberFormEmpty(tariff.hasta)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            hasta: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" \u20AC"}
                        value={tariff.value}
                        className={validateNumberFormEmpty(tariff.value)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            value: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.filter((tariffForm, index2) => index2 !== index)
                            });
                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });

    const listTariffsLocationsForm: JSX.Element[] = props.formTariff.tariffLocations.map((tariffLocation, index) => {
        return (
            <tr className="table-row" key={index} style={{ height: "40px" }}>
                <td>
                    {getValueLoc(tariffLocation.locationType, tariffLocation.postalCode)}
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={tariffLocation.reissues}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" %"} />
                </td>
            </tr>
        )
    });

    const addLocationFormTariff: JSX.Element[] = [...props.formTariff.tariffLocations].map((locTariff, index) => {
        return (
            <tr className="table-row"
                style={{ height: "40px" }}
                key={index}
            >
                <td>
                    <PostalCodesAutocomplete
                        Suggestionswidth="200"
                        location={getValueLoc(locTariff.locationType, locTariff.postalCode)}
                        country={locTariff.postalCode.idPais}
                        onClick={(postalCode, locationType) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffLocations: props.formTariff.tariffLocations.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            postalCode: postalCode,
                                            locationType: locationType
                                        }
                                    }
                                    return tariff2;
                                }),
                            });

                        }}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" %"}
                        value={locTariff.reissues}
                        className="form-control"
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffLocations: props.formTariff.tariffLocations.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            reissues: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffLocations: props.formTariff.tariffLocations.filter((tariffForm, index2) => index2 !== index)
                            });
                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });


    return (

        <Fragment>
            <div className="card-body">
                <div className="row">

                    <div className="form-group col-md-4">
                        <h6 className="mb-2 font-weight-bold text-green">{"Historial (" + props.allTariff.length + ")"}</h6>

                        {/* HISTORIAL */}

                        <div className="table-responsive portlet-700">
                            <table className="table" style={{ height: "684px" }}>
                                <thead className="thead-light">
                                    <tr style={{ height: "40px" }}>
                                        <th scope="col" style={{ zIndex: "2" }}>Descripción</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Fecha de alta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.formState === formStates.add &&
                                        <tr className="table-row" style={{ height: "40px" }}>
                                            <td><input
                                                type="text"
                                                onChange={onValueChanged}
                                                value={props.formTariff.description}
                                                className={validateFormEmpty(props.formTariff.description, 2)}
                                                name="description" />
                                            </td>
                                            <td>
                                                <label>{getFormattedDate(props.formTariff.fecAlta)}</label>
                                            </td>
                                        </tr>
                                    }
                                    {(props.allTariff.length > 0 || props.formState === formStates.add || props.formState === formStates.edit) ?
                                        listTariffs
                                        :
                                        <tr className="table-row" style={{ cursor: "default", height: "40px" }}>
                                            <td>No existen tarifas</td>
                                            <td></td>
                                        </tr>
                                    }
                                    {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                    <tr className="table-row" style={{ cursor: "default" }}>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive portlet-600">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr style={{ height: "62px" }}>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>


                    {/* VALORES */}

                    {((props.formTariff.tariffs.length > 0) || (props.formState === formStates.add) || (props.formState === formStates.edit)) &&
                        <div className="form-group col-md-4">

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h6 className="mb-2 font-weight-bold text-green">{"Valores (" + props.formTariff.tariffs.length + ")"}</h6>
                                {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff.tariffs.length > 0) &&
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Porcentaje a aumentar tarifa",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0", // Opcional: establece un mínimo
                                                    max: "100",
                                                    step: "0.01" // Permite decimales en el input
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Aumentar",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (percentageIncrease) => {
                                                    if (percentageIncrease === null || percentageIncrease === "") {
                                                        Swal.showValidationMessage("Por favor ingrese un porcentaje válido");
                                                        return;
                                                    }
                                                    const increaseFactor = 1 + (parseFloat(percentageIncrease) / 100);
                                                    return { increaseFactor: increaseFactor };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    props.setFormTariff({
                                                        ...props.formTariff,
                                                        tariffs: props.formTariff.tariffs.map((tariff, index) => {
                                                            return {
                                                                ...tariff,
                                                                value: parseFloat((tariff.value * result.value.increaseFactor).toFixed(8)),
                                                            }
                                                        }),
                                                        tariffLocations: props.formTariff.tariffLocations.map((tariffLoc, index) => {
                                                            return {
                                                                ...tariffLoc,
                                                                reissues: tariffLoc.reissues ? Math.ceil((tariffLoc.reissues * result.value.increaseFactor) * 100) / 100 : 0,
                                                            }
                                                        }),
                                                        minImport: Math.ceil((props.formTariff.minImport * result.value.increaseFactor) * 100) / 100,
                                                    });
                                                    const percentageIncrease = (result.value.increaseFactor * 100 - 100).toFixed(2);
                                                    Swal.fire({
                                                        title: `Tarifas aumentadas en un ${percentageIncrease}%`,
                                                        icon: "success"
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <i className="fa fa-plus"></i> Aumentar Tarifas
                                    </button>
                                }
                            </div>

                            <div className={"table-responsive portlet-700"}>
                                <table className="table" style={{ height: "684px" }}>
                                    <thead className="thead-light">

                                        {/* VALOR MINIMO */}

                                        <tr>
                                            <th scope="col"
                                                style={{ verticalAlign: "baseline" }}
                                            >
                                                Valor Mínimo:
                                            </th>
                                            <th
                                                style={{ verticalAlign: "baseline" }}
                                                scope="col"
                                            >
                                                {props.formState !== formStates.none ?
                                                    <NumberFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={8}
                                                        fixedDecimalScale={false}
                                                        allowNegative={false}
                                                        suffix={" \u20AC"}
                                                        value={props.formTariff.minImport}
                                                        className="form-control undefined"
                                                        onValueChange={(value) => {
                                                            props.setFormTariff({
                                                                ...props.formTariff,
                                                                minImport: value.floatValue ? value.floatValue : 0
                                                            });
                                                        }} />
                                                    :
                                                    <NumberFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                        decimalScale={8}
                                                        fixedDecimalScale={false}
                                                        allowNegative={false}
                                                        value={props.formTariff.minImport}
                                                        className="form-control undefined"
                                                        renderText={value => <div>{value}</div>}
                                                        suffix={" \u20AC"} />
                                                }
                                            </th>

                                            {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffs) &&
                                                <th scope="col" style={{ verticalAlign: "baseline" }}></th>
                                            }

                                        </tr>

                                        {/* VALORES */}

                                        <tr style={{ height: "40px" }}>
                                            <th scope="col" style={{ zIndex: "2" }}>{"Hasta " + props.from}</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Valor</th>
                                            {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffs) &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {props.formState === formStates.none && props.formTariff && props.formTariff.tariffs &&
                                            listTariffsForm
                                        }
                                        {(props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffs &&
                                            addFormTariff
                                        }
                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="table">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr style={{ height: "62px" }}>
                                            <th scope="col">
                                                <input
                                                    name="clientAddress"
                                                    type="checkbox"
                                                    checked={props.formTariff.multiplicateValue}
                                                    className="form-control"
                                                    onChange={() => {
                                                        if (props.formState === formStates.add || props.formState === formStates.edit) {
                                                            props.setFormTariff({
                                                                ...props.formTariff,
                                                                multiplicateValue: !props.formTariff.multiplicateValue
                                                            });
                                                        }
                                                    }}
                                                />
                                            </th>
                                            <th scope="col" style={{ verticalAlign: "inherit" }}>
                                                <span>
                                                    {"Multiplicar valor por " + props.from}
                                                </span>
                                            </th>
                                            <th scope="col">
                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    <>
                                                        <div className="right">
                                                            {/* AÑADIR FILA A TARIFA*/}
                                                            <button className="btn btn-success btn-green"
                                                                onClick={() => {
                                                                    props.setFormTariff({
                                                                        ...props.formTariff,
                                                                        tariffs: [...props.formTariff.tariffs, { hasta: 0, value: 0 }]
                                                                    })

                                                                }}>
                                                                <i className="fas fa fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                        </div>
                    }


                    {/*******LOCATIONS*******/}

                    {((props.formTariff.tariffLocations.length > 0) || (props.formState === formStates.add) || (props.formState === formStates.edit)) &&
                        <div className="form-group col-md-4">
                            <h6 className="mb-2 font-weight-bold text-green">{"Zonas (" + props.formTariff.tariffLocations.length + ")"}</h6>

                            <div className="table-responsive portlet-700">
                                <table className="table" style={{ height: "684px" }}>

                                    <thead className="thead-light">

                                        <tr style={{ height: "40px" }}>

                                            <th scope="col" style={{ zIndex: "2" }}>{"Localización"}</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Reexpedición (+%)</th>
                                            {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffLocations) &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {props.formState === formStates.none && props.formTariff && props.formTariff.tariffLocations &&
                                            listTariffsLocationsForm
                                        }

                                        {(props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffLocations &&
                                            addLocationFormTariff
                                        }

                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="table">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr style={{ height: "62px" }}>
                                            <th scope="col">
                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    <div className="right">
                                                        {/* AÑADIR FILA A TARIFA*/}
                                                        <button className="btn btn-success btn-green"
                                                            onClick={() => {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    tariffLocations: [...props.formTariff.tariffLocations, { postalCode: inicializePostalCode, fecAlta: new Date(), locationType: "", reissues: 0 }]
                                                                })
                                                            }}>
                                                            <i className="fas fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                        </div>
                    }

                </div>
            </div>

        </Fragment>

    );

}

export default PaqueteriaTariffs;