"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tiposRegimenFiscal = exports.whatTariffUseTypes = exports.tariffsTypes = exports.reviRepaTypes = exports.showListAgrupa = exports.estadoTrazaEnum = exports.cobrarRetornoTypes = exports.locationType = exports.albaranesPaqueteriaQueMostrar = exports.vencimientosFacturas = exports.formasDePago = exports.plazosEntrega = exports.usersTypes = exports.tiposArticuladosVehiculos = exports.tiposEstructuraVehiculos = exports.ErrorMessagesTypes = exports.UserPermissions = exports.Periodicidades = exports.cargaDescargaEnum = exports.queFacturarTipos = exports.formStates = exports.addingOrEditing = exports.loading = void 0;
var loading;
(function (loading) {
    loading[loading["pending"] = 0] = "pending";
    loading[loading["done"] = 1] = "done";
})(loading || (exports.loading = loading = {}));
var addingOrEditing;
(function (addingOrEditing) {
    addingOrEditing[addingOrEditing["none"] = 0] = "none";
    addingOrEditing[addingOrEditing["adding"] = 1] = "adding";
    addingOrEditing[addingOrEditing["edditing"] = 2] = "edditing";
})(addingOrEditing || (exports.addingOrEditing = addingOrEditing = {}));
var formStates;
(function (formStates) {
    formStates[formStates["none"] = 0] = "none";
    formStates[formStates["add"] = 1] = "add";
    formStates[formStates["edit"] = 2] = "edit";
})(formStates || (exports.formStates = formStates = {}));
var queFacturarTipos;
(function (queFacturarTipos) {
    queFacturarTipos["todo"] = "Todo";
    queFacturarTipos["paqueteria"] = "Solo paqueteria";
    queFacturarTipos["carga"] = "Solo cargas completas";
})(queFacturarTipos || (exports.queFacturarTipos = queFacturarTipos = {}));
var cargaDescargaEnum;
(function (cargaDescargaEnum) {
    cargaDescargaEnum["carga"] = "Carga";
    cargaDescargaEnum["descarga"] = "Descarga";
})(cargaDescargaEnum || (exports.cargaDescargaEnum = cargaDescargaEnum = {}));
var Periodicidades;
(function (Periodicidades) {
    Periodicidades["diaria"] = "Diaria";
    Periodicidades["semanal"] = "Semanal";
    Periodicidades["quincenal"] = "Quincenal";
    Periodicidades["mensual"] = "Mensual";
    Periodicidades["trimestral"] = "Trimestral";
    Periodicidades["semestral"] = "Semestral";
    Periodicidades["anual"] = "Anual";
})(Periodicidades || (exports.Periodicidades = Periodicidades = {}));
var UserPermissions;
(function (UserPermissions) {
    UserPermissions["deshabilitado"] = "Deshabilitado";
    UserPermissions["consultar"] = "Consultar";
    UserPermissions["modificar"] = "Modificar";
})(UserPermissions || (exports.UserPermissions = UserPermissions = {}));
var ErrorMessagesTypes;
(function (ErrorMessagesTypes) {
    ErrorMessagesTypes["ok"] = "ok";
    ErrorMessagesTypes["nok"] = "nok";
})(ErrorMessagesTypes || (exports.ErrorMessagesTypes = ErrorMessagesTypes = {}));
var tiposEstructuraVehiculos;
(function (tiposEstructuraVehiculos) {
    tiposEstructuraVehiculos["rigido"] = "Rigido";
    tiposEstructuraVehiculos["articulado"] = "Articulado";
})(tiposEstructuraVehiculos || (exports.tiposEstructuraVehiculos = tiposEstructuraVehiculos = {}));
var tiposArticuladosVehiculos;
(function (tiposArticuladosVehiculos) {
    tiposArticuladosVehiculos["tractora"] = "Cabeza tractora";
    tiposArticuladosVehiculos["remolque"] = "Remolque / Semiremolque";
})(tiposArticuladosVehiculos || (exports.tiposArticuladosVehiculos = tiposArticuladosVehiculos = {}));
var usersTypes;
(function (usersTypes) {
    usersTypes["client"] = "client";
    usersTypes["employee"] = "employee";
    usersTypes["supplier"] = "supplier";
})(usersTypes || (exports.usersTypes = usersTypes = {}));
var plazosEntrega;
(function (plazosEntrega) {
    plazosEntrega["null"] = "Sin determinar";
    plazosEntrega["h12"] = "12 horas";
    plazosEntrega["h24"] = "24 horas";
    plazosEntrega["h48"] = "48 horas";
    plazosEntrega["h72"] = "72 horas";
})(plazosEntrega || (exports.plazosEntrega = plazosEntrega = {}));
var formasDePago;
(function (formasDePago) {
    formasDePago["null"] = "Sin determinar";
    formasDePago["confirming"] = "Confirming";
    formasDePago["transferencia"] = "Transferencia";
    formasDePago["giro"] = "Giro";
    formasDePago["contado"] = "Contado";
    formasDePago["pagare"] = "Pagar\u00E9";
})(formasDePago || (exports.formasDePago = formasDePago = {}));
var vencimientosFacturas;
(function (vencimientosFacturas) {
    vencimientosFacturas["null"] = "Sin determinar";
    vencimientosFacturas["d15"] = "15 d\u00EDas";
    vencimientosFacturas["d30"] = "30 d\u00EDas";
    vencimientosFacturas["d45"] = "45 d\u00EDas";
    vencimientosFacturas["d60"] = "60 d\u00EDas";
    vencimientosFacturas["d90"] = "90 d\u00EDas";
    vencimientosFacturas["d120"] = "120 d\u00EDas";
    vencimientosFacturas["d150"] = "150 d\u00EDas";
    vencimientosFacturas["d180"] = "180 d\u00EDas";
})(vencimientosFacturas || (exports.vencimientosFacturas = vencimientosFacturas = {}));
var albaranesPaqueteriaQueMostrar;
(function (albaranesPaqueteriaQueMostrar) {
    albaranesPaqueteriaQueMostrar["origenDestino"] = "Direcci\u00F3n origen y direcci\u00F3n destino";
    albaranesPaqueteriaQueMostrar["clienteOrigen"] = "Datos cliente y direcci\u00F3n origen";
    albaranesPaqueteriaQueMostrar["clienteDestino"] = "Datos cliente y direcci\u00F3n destino";
})(albaranesPaqueteriaQueMostrar || (exports.albaranesPaqueteriaQueMostrar = albaranesPaqueteriaQueMostrar = {}));
var locationType;
(function (locationType) {
    locationType["none"] = "";
    locationType["cp"] = "Cp";
    locationType["localidad"] = "Localidad";
    locationType["provincia"] = "Provincia";
})(locationType || (exports.locationType = locationType = {}));
var cobrarRetornoTypes;
(function (cobrarRetornoTypes) {
    cobrarRetornoTypes["no"] = "No";
    cobrarRetornoTypes["medioViaje"] = "Medio Viaje";
    cobrarRetornoTypes["viajeCompleto"] = "Viaje completo";
})(cobrarRetornoTypes || (exports.cobrarRetornoTypes = cobrarRetornoTypes = {}));
var estadoTrazaEnum;
(function (estadoTrazaEnum) {
    estadoTrazaEnum[estadoTrazaEnum["none"] = 0] = "none";
    estadoTrazaEnum[estadoTrazaEnum["introducida"] = 1] = "introducida";
    estadoTrazaEnum[estadoTrazaEnum["enTransito"] = 4] = "enTransito";
    estadoTrazaEnum[estadoTrazaEnum["entregada"] = 7] = "entregada";
    estadoTrazaEnum[estadoTrazaEnum["enPause"] = 90] = "enPause";
})(estadoTrazaEnum || (exports.estadoTrazaEnum = estadoTrazaEnum = {}));
var showListAgrupa;
(function (showListAgrupa) {
    showListAgrupa[showListAgrupa["none"] = 0] = "none";
    showListAgrupa[showListAgrupa["list"] = 1] = "list";
    showListAgrupa[showListAgrupa["agrupa"] = 2] = "agrupa";
    showListAgrupa[showListAgrupa["albaranes"] = 3] = "albaranes";
})(showListAgrupa || (exports.showListAgrupa = showListAgrupa = {}));
var reviRepaTypes;
(function (reviRepaTypes) {
    reviRepaTypes["revision"] = "Revisi\u00F3n";
    reviRepaTypes["reparacion"] = "Reparaci\u00F3n";
})(reviRepaTypes || (exports.reviRepaTypes = reviRepaTypes = {}));
var tariffsTypes;
(function (tariffsTypes) {
    tariffsTypes["none"] = "";
    tariffsTypes["horas"] = "horas";
    tariffsTypes["distancia"] = "distancia";
    tariffsTypes["poblacion"] = "poblacion";
    tariffsTypes["trayecto"] = "trayecto";
    tariffsTypes["otros"] = "otros";
    tariffsTypes["peso"] = "peso";
    tariffsTypes["bultos"] = "bultos";
    tariffsTypes["volumen"] = "volumen";
})(tariffsTypes || (exports.tariffsTypes = tariffsTypes = {}));
var whatTariffUseTypes;
(function (whatTariffUseTypes) {
    whatTariffUseTypes["none"] = "";
    whatTariffUseTypes["compania"] = "compania";
    whatTariffUseTypes["cliente"] = "cliente";
    whatTariffUseTypes["vehiculo"] = "vehiculo";
})(whatTariffUseTypes || (exports.whatTariffUseTypes = whatTariffUseTypes = {}));
var tiposRegimenFiscal;
(function (tiposRegimenFiscal) {
    tiposRegimenFiscal["irpf1"] = "irpf1";
    tiposRegimenFiscal["estimacionDirecta"] = "estimacionDirecta";
})(tiposRegimenFiscal || (exports.tiposRegimenFiscal = tiposRegimenFiscal = {}));
