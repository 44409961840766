import React, { ChangeEvent, Fragment, ReactElement, useEffect } from "react";
import {
    getFormattedDate, initialVehicleType, inicialiceLocTariff, CapitalizefirstLetter, validateNumberFormEmpty,
    formatedDirWithName, addingOrEditing, formStates, tariffCargaCompleta, validateAlphaNumeric, validateFormEmpty,
} from "aseguisShared"
import NumberFormat from 'react-number-format';
import VehiclesTypesAutocomplete from "../../components/Vehicles/VehiclesSettings/VehiclesTypesAutocomplete";
import GoogleAndClientsSuggestions from "../PlacesAutocomplete/GoogleAndClientsSuggestions";
import Swal from "sweetalert2";

interface infoToCargaTrayectoTariffs {
    allTariff: tariffCargaCompleta[]
    formTariff: tariffCargaCompleta
    setFormTariff: (tariff: tariffCargaCompleta) => void

    formState: number
    timestamp: string

    setErrorForm: (value: boolean) => void,
}

function CargaTrayectoTariffs(props: infoToCargaTrayectoTariffs): ReactElement {

    const [errorOnClientsAutocomplete, setErrorOnClientsAutocomplete] = React.useState(false);

    useEffect(() => {
        if (
            ((props.formState !== formStates.none)) &&
            (
                (props.formTariff.vehicleType.description.length < 2) ||
                (props.formTariff.description.length < 2) ||
                (props.formTariff.locTariffs.length === 0) ||
                (thereIsErrorOnTariffs() === true)
            )
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [props.formState, props.formTariff,
        thereIsErrorOnTariffs,
        errorOnClientsAutocomplete
    ]);

    function thereIsErrorOnTariffs(): boolean {
        for (var tariff of props.formTariff.locTariffs) {
            if (
                (
                    (tariff.origin.address.calle.length === 0) &&
                    (tariff.origin.address.placeId.length === 0)
                ) ||
                (
                    (tariff.destiny.address.calle.length === 0) &&
                    (tariff.destiny.address.placeId.length === 0)
                ) ||
                (tariff.value === 0)
            ) {
                return true;
            }
        }
        return false;
    }

    function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
        props.setFormTariff({
            ...props.formTariff,
            description: value
        });
    }

    const listTariffs: JSX.Element[] = [...props.allTariff].reverse().map((tariff, index) => {

        if (tariff && props.formTariff && tariff.id === props.formTariff.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={tariff.id} style={{ height: "40px" }}>
                    <td>
                        <VehiclesTypesAutocomplete
                            whatShow="remolques"
                            vehicleTypeSelected={props.formTariff.vehicleType}
                            onClick={(vehiType) => {
                                props.setFormTariff({
                                    ...props.formTariff,
                                    vehicleType: vehiType,
                                })
                            }} />
                    </td>
                    <td><input
                        type="text"
                        onChange={onValueChanged}
                        value={props.formTariff.description}
                        className={validateFormEmpty(props.formTariff.description, 2)}
                        name="description" />
                    </td>
                    <td>
                        <label>{getFormattedDate()}</label>
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={`table-row ${(tariff && props.formTariff && tariff.id === props.formTariff.id) ? "selected" : ""}`}
                    style={{ height: "40px" }}
                    onClick={() => {
                        if (props.formState === formStates.add) {
                            props.setFormTariff({
                                ...tariff,
                                id: props.allTariff.length + 1,
                                description: "",
                                fecAlta: new Date(),
                                vehicleType: initialVehicleType
                            });
                        } else {
                            props.setFormTariff(tariff);
                        }
                    }}
                    key={tariff.id}>
                    <td scope="row"><b>{tariff.vehicleType.description}</b></td>
                    <td>{tariff.description}</td>
                    <td>{getFormattedDate()}</td>
                </tr>
            )
        }
    });


    const listTariffsForm: JSX.Element[] = [...props.formTariff.locTariffs].map((tariff, index) => {
        return (
            <tr
                key={index}
                className="table-row"
                style={{
                    height: "40px",
                    flexWrap: "nowrap"
                }}
            >
                <td>
                    <GoogleAndClientsSuggestions
                        isEditing={addingOrEditing.none}
                        valueNoEditing={formatedDirWithName(tariff.origin.address, tariff.origin.name)}
                        placeholder="Escriba un lugar para autocompletar..."
                        initialAddress={props.formTariff.locTariffs[index].origin.address}
                        activateClientsDirs={true}
                        onClick={() => { }}
                        setErrorForm={setErrorOnClientsAutocomplete}
                    />
                </td>
                <td>
                    <GoogleAndClientsSuggestions
                        isEditing={addingOrEditing.none}
                        valueNoEditing={formatedDirWithName(tariff.destiny.address, tariff.destiny.name)}
                        placeholder="Escriba un lugar para autocompletar..."
                        initialAddress={props.formTariff.locTariffs[index].destiny.address}
                        activateClientsDirs={true}
                        onClick={() => { }}
                        setErrorForm={setErrorOnClientsAutocomplete}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={tariff.value}
                        className="form-control undefined"
                        renderText={value =>
                            <div style={{ whiteSpace: "nowrap" }} >
                                {value}
                            </div>
                        }
                        suffix={" \u20AC"}
                    />
                </td>
            </tr>
        )
    });

    const addFormTariff: JSX.Element[] = [...props.formTariff.locTariffs].map((tariff, index) => {
        return (
            <tr className="table-row"
                style={{ height: "40px" }}
                key={index}>
                <td>
                    <GoogleAndClientsSuggestions
                        isEditing={addingOrEditing.edditing}
                        valueNoEditing={""}
                        placeholder="Escriba un lugar para autocompletar..."
                        initialAddress={props.formTariff.locTariffs[index].origin.address}
                        activateClientsDirs={true}
                        onClick={(destination) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            origin: {
                                                address: destination.address,
                                                name: destination.name,
                                                tel: destination.tel
                                            }
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }}
                        setErrorForm={setErrorOnClientsAutocomplete}
                    />
                </td>
                <td>
                    <GoogleAndClientsSuggestions
                        isEditing={addingOrEditing.edditing}
                        valueNoEditing={""}
                        placeholder="Escriba un lugar para autocompletar..."
                        initialAddress={props.formTariff.locTariffs[index].destiny.address}
                        activateClientsDirs={true}
                        onClick={(destination) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            destiny: {
                                                address: destination.address,
                                                name: destination.name,
                                                tel: destination.tel
                                            }
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }}
                        setErrorForm={setErrorOnClientsAutocomplete}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" \u20AC"}
                        value={tariff.value}
                        className={validateNumberFormEmpty(tariff.value)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            value: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.filter((tariffForm, index2) => index2 !== index)
                            });
                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });


    return (
        <Fragment>
            <div className="card-body">
                <div className="row">
                    <div className="form-group col-md-5">
                        <h6 className="mb-2 font-weight-bold text-green">Historial</h6>
                        <div className="table-responsive portlet-700">
                            <table className="table" style={{ height: "684px" }}>
                                <thead className="thead-light">
                                    <tr style={{ height: "40px" }}>
                                        <th scope="col" style={{ zIndex: "2" }}>Tipo vehículo</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Descripción</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Fecha de alta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.formState === formStates.add &&
                                        <tr className="table-row" style={{ height: "40px" }}>
                                            <td>
                                                <VehiclesTypesAutocomplete
                                                    whatShow="remolques"
                                                    vehicleTypeSelected={props.formTariff.vehicleType}
                                                    onClick={(vehiType) => {
                                                        props.setFormTariff({
                                                            ...props.formTariff,
                                                            vehicleType: vehiType,
                                                        })
                                                    }} />
                                            </td>
                                            <td><input
                                                type="text"
                                                onChange={onValueChanged}
                                                value={props.formTariff.description}
                                                className={validateFormEmpty(props.formTariff.description, 2)}
                                                name="description" />
                                            </td>
                                            <td>
                                                <label>{props.timestamp}</label>
                                            </td>
                                        </tr>
                                    }
                                    {(props.allTariff.length > 0 || props.formState === formStates.add || props.formState === formStates.edit) ?
                                        listTariffs
                                        :
                                        <tr className="mt-2">
                                            <td>No existen tarifas</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                    {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                    <tr className="table-row" style={{ cursor: "default" }}>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive portlet-600">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr style={{ height: "62px" }}>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>

                    {((props.formTariff.locTariffs.length > 0) || (props.formState === formStates.add) || (props.formState === formStates.edit)) &&
                        <div className="form-group col-md-7">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h6 className="mb-2 font-weight-bold text-green">Valores</h6>
                                {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff.locTariffs.length > 0) &&
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Porcentaje a aumentar tarifa",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0", // Opcional: establece un mínimo
                                                    max: "100",
                                                    step: "0.01" // Permite decimales en el input
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Aumentar",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (percentageIncrease) => {
                                                    if (percentageIncrease === null || percentageIncrease === "") {
                                                        Swal.showValidationMessage("Por favor ingrese un porcentaje válido");
                                                        return;
                                                    }
                                                    const increaseFactor = 1 + (parseFloat(percentageIncrease) / 100);
                                                    return { increaseFactor: increaseFactor };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    props.setFormTariff({
                                                        ...props.formTariff,
                                                        locTariffs: props.formTariff.locTariffs.map((tariff, index) => {
                                                            return {
                                                                ...tariff,
                                                                value: Math.ceil((tariff.value * result.value.increaseFactor) * 100) / 100
                                                            }
                                                        }),
                                                        minImport: Math.ceil((props.formTariff.minImport * result.value.increaseFactor) * 100) / 100,
                                                        auxImport: Math.ceil((props.formTariff.auxImport * result.value.increaseFactor) * 100) / 100,
                                                    });
                                                    const percentageIncrease = (result.value.increaseFactor * 100 - 100).toFixed(2);
                                                    Swal.fire({
                                                        title: `Tarifas aumentadas en un ${percentageIncrease}%`,
                                                        icon: "success"
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <i className="fa fa-plus"></i> Aumentar Tarifas
                                    </button>
                                }
                            </div>

                            <div className="table-responsive portlet-700">
                                <table className="table" style={{ height: "684px" }}>
                                    <thead className="thead-light">
                                        <tr style={{ height: "40px" }}>
                                            <th scope="col" style={{ zIndex: "2" }}>Origen</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Destino</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Valor</th>
                                            {(props.formState === formStates.add || props.formState === formStates.edit)
                                                && props.formTariff && props.formTariff.locTariffs &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.formState === formStates.none && props.formTariff && props.formTariff.locTariffs &&
                                            listTariffsForm
                                        }
                                        {(props.formState === formStates.add || props.formState === formStates.edit)
                                            && props.formTariff && props.formTariff.locTariffs &&
                                            addFormTariff
                                        }
                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr style={{ height: "62px" }}>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    <div className="right">
                                                        {/* AÑADIR FILA A TARIFA*/}
                                                        <button className="btn btn-success btn-green"
                                                            onClick={() => {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    locTariffs: [...props.formTariff.locTariffs, { ...inicialiceLocTariff }]
                                                                })
                                                            }}>
                                                            <i className="fas fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>


        </Fragment>

    );

}

export default CargaTrayectoTariffs;