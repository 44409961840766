import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../ApplicationState';
import { requestAllReviRepas, exportReviRepaToExcel } from './ReviRepaStore';
import { reviRepa, searchFilterList, getFormattedDate, formatearMatricula } from 'aseguisShared';
import { filterReviRepaTableTH } from "../../common/commonInterfaces/thFilters";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import { requestParametrizacion } from "../../components/Parametrizacion/ParametrizacionStore";
import ReviRepaListFilter from "./ReviRepaSearchListFilter";
import NumberFormat from "react-number-format";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToReviRepaList {
    onClickReviRepa: (reviRepa: reviRepa) => void
    addReviRepa?: () => void
    searchFilterReviRepa: searchFilterList
    saveSearchFilterReviRepa: (filter: searchFilterList) => void
}

const ReviRepaList = (props: infoToReviRepaList) => {

    const dispatch = useDispatch();

    const allReviRepaList: reviRepa[] = useSelector((state: ApplicationState) => state.reviRepa.reviRepaList);
    const countAllReviRepas: number = useSelector((state: ApplicationState) => state.reviRepa.totalReviRepaList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.reviRepa.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.reviRepa.totalPages);
    const reviRepaForm: reviRepa = useSelector((state: ApplicationState) => state.reviRepa.reviRepaEditForm);

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    useEffect(() => {
        if (props.searchFilterReviRepa.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllReviRepas(props.searchFilterReviRepa));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllReviRepas(props.searchFilterReviRepa));
        }
    }, [props.searchFilterReviRepa, reviRepaForm]);

    useEffect(() => {
        dispatch(requestParametrizacion());
    }, [])

    const vehiclesControlList: JSX.Element[] = allReviRepaList.map((reviRepa, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClickReviRepa(reviRepa)
                }}
                key={index}>
                <td scope="row"><b>{reviRepa.id}</b></td>
                <td>{getFormattedDate(reviRepa.fecRevision)}</td>
                <td>{formatearMatricula(reviRepa.vehicle.matricula)}</td>
                <td>
                    <NumberFormat
                        renderText={value => <div>{value}</div>}
                        displayType="text"
                        value={reviRepa.kms}
                        id={"km"}
                        isNumericString={true}
                        className="form-control undefined"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={0}
                        allowNegative={false}
                        suffix={" km"}
                    />
                </td>
                <td>{reviRepa.typeReviRepa}</td>
                <td>{reviRepa.observaciones}</td>
                <td>
                    <NumberFormat
                        renderText={value => <div>{value}</div>}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={reviRepa.import}
                        className="form-control undefined"
                        suffix={" \u20AC"}
                    />
                </td>
            </tr>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*anadir button*/}
                        {(props.addReviRepa && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info left-margin btn-sm"
                                    onClick={() => {
                                        if (allReviRepaList.length > 0) {
                                            dispatch(exportReviRepaToExcel({
                                                ...props.searchFilterReviRepa,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addReviRepa}
                                />
                                
                            </div>
                        }

                        <div className="card-header">
                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">Lista de revisiones y reparaciones de vehículos {"(" + countAllReviRepas + ")"}</h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                            <br></br>
                            <ReviRepaListFilter
                                searchFilter={props.searchFilterReviRepa}
                                saveSearchFilter={props.saveSearchFilterReviRepa}
                            />
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "#")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "Fecha")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "Matrícula")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "Km")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "Tipo")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "Tareas realizadas")}
                                            {filterReviRepaTableTH(props.saveSearchFilterReviRepa, props.searchFilterReviRepa, "SubTotal")}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehiclesControlList}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchFilterReviRepa, props.saveSearchFilterReviRepa)}

                    </div>
                </div>
            </div>

        </Fragment >

    );
};

export default ReviRepaList;
