import React, { ChangeEvent, Fragment, ReactElement, useEffect, useState } from "react";
import {
    tariffCargaCompleta, formStates, initialVehicleType, CapitalizefirstLetter, getFormattedDate,
    inicialiceAddress, validateAlphaNumeric, validateFormEmpty, validateNumberFormEmpty, getValueLoc, inicializePostalCode, locationType
} from "aseguisShared"
import NumberFormat from 'react-number-format';
import VehiclesTypesAutocomplete from "../../components/Vehicles/VehiclesSettings/VehiclesTypesAutocomplete";
import PostalCodesAutocomplete from "../PostalCodes/PostalCodesAutocomplete";
import Swal from "sweetalert2";

interface infoToCargaPoblationsTariffs {
    allTariff: tariffCargaCompleta[]
    formTariff: tariffCargaCompleta
    setFormTariff: (tariff: tariffCargaCompleta) => void

    formState: number
    timestamp: string

    setErrorForm: (value: boolean) => void,
}

function CargaPoblationsTariffs(props: infoToCargaPoblationsTariffs): ReactElement {
    const [indexTariffSelected, setIndexTariffSelected] = useState<number | null>(null);
    const [tariffSelectedType, setTariffSelectedType] = useState<locationType>(locationType.none);
    const [tariffSelType, setTariffSelType] = useState<locationType>(locationType.none); //duplicamos este estado porque si no no lo coge
    const [cpToValidate, setCpToValidate] = useState("");

    function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))       
        props.setFormTariff({
            ...props.formTariff,
            description: value
        });
    }

    function thereIsErrorOnTariffs(): boolean {
        for (var tariff of props.formTariff.locTariffs) {
            if (tariff.locationType.length === 0 || tariff.value === 0) {
                return true;
            }
            for (var fromDistance of tariff.extraTariffs) {
                if (fromDistance.hasta === 0 || fromDistance.value === 0) {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        if (
            ((props.formState !== formStates.none)) &&
            ((props.formTariff.vehicleType.description.length < 2) ||
                (props.formTariff.description.length < 2) ||
                (props.formTariff.locTariffs.length === 0) ||
                (thereIsErrorOnTariffs() === true))
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }

    }, [props.formState, props.formTariff.vehicleType.description, props.formTariff.description, props.formTariff.locTariffs.length, thereIsErrorOnTariffs])


    useEffect(() => {
        if (props.formTariff.locTariffs.length === 0) {
            setIndexTariffSelected(null)
            setTariffSelectedType(locationType.none)
        }
    }, [props.formTariff])

    const listTariffs: JSX.Element[] = [...props.allTariff].reverse().map((tariff, index) => {
        if (tariff && props.formTariff && tariff.id === props.formTariff.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={tariff.id} style={{ height: "40px" }}>
                    <td>
                        <VehiclesTypesAutocomplete
                            whatShow="remolques"
                            vehicleTypeSelected={props.formTariff.vehicleType}
                            onClick={(vehiType) => {
                                props.setFormTariff({
                                    ...props.formTariff,
                                    vehicleType: vehiType,
                                })
                            }}
                        />
                    </td>
                    <td><input
                        type="text"
                        onChange={onValueChanged}
                        value={props.formTariff.description}
                        className={validateFormEmpty(props.formTariff.description, 2)}
                        name="description" />
                    </td>
                    <td>
                        <label>{getFormattedDate()}</label>
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={`table-row ${(tariff && props.formTariff && tariff.id === props.formTariff.id) ? "selected" : ""}`}
                    style={{ height: "40px" }}
                    onClick={() => {
                        if (props.formState === formStates.add) {
                            props.setFormTariff({
                                ...tariff,
                                id: props.allTariff.length + 1,
                                description: "",
                                fecAlta: new Date(),
                                vehicleType: initialVehicleType,
                            });
                        } else {
                            props.setFormTariff(tariff);
                        }
                        setIndexTariffSelected(null)
                        setTariffSelectedType(locationType.none)
                        setCpToValidate("")
                    }}
                    key={tariff.id}>
                    <td scope="row"><b>{tariff.vehicleType.description}</b></td>
                    <td>{tariff.description}</td>
                    <td>{getFormattedDate()}</td>
                </tr>
            )
        }
    });

    const listTariffsForm: JSX.Element[] = [...props.formTariff.locTariffs].map((tariff, index) => {
        return (
            <tr
                key={index}
                className={(index === indexTariffSelected) ? "table-row selected" : "table-row"}
                style={{ height: "40px" }}
                onClick={() => {
                    if (tariff.extraTariffs) {
                    }
                    setIndexTariffSelected(index)
                    setTariffSelectedType(tariff.locationType)
                    setCpToValidate(tariff.postalCode.cp)
                }}
            >
                <td>
                    {getValueLoc(tariff.locationType, tariff.postalCode)}
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={tariff.value}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"} />
                </td>
            </tr>
        )
    });

    const addFormTariff: JSX.Element[] = [...props.formTariff.locTariffs].map((tariff, index) => {
        return (
            <tr
                key={index}
                className={(index === indexTariffSelected) ? "table-row selected" : "table-row"}
                style={{ height: "40px" }}
                onClick={() => {
                    if (tariff.extraTariffs) {
                    }
                    setIndexTariffSelected(index)
                    setTariffSelectedType(tariff.locationType)
                    setCpToValidate(tariff.postalCode.cp)
                }}
            >
                <td>
                    <PostalCodesAutocomplete
                        Suggestionswidth="200"
                        location={getValueLoc(tariff.locationType, tariff.postalCode)}
                        country={tariff.postalCode.idPais}
                        onClick={(postalCode, locationType) => {
                            setTariffSelType(locationType)
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            postalCode: postalCode,
                                            locationType: locationType,
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" \u20AC"}
                        value={tariff.value}
                        className={validateNumberFormEmpty(tariff.value)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            value: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.filter((tariffForm, index2) => index2 !== index)
                            });
                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });

    const listDistanciasCentro: JSX.Element[] = props.formTariff.locTariffs[indexTariffSelected!] && props.formTariff.locTariffs[indexTariffSelected!].extraTariffs.map((distanciaCentro, index) => {
        return (
            <tr
                key={index}
                className="table-row"
                style={{ height: "40px" }}
            >
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={distanciaCentro.hasta}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" km"} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        value={distanciaCentro.value}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"} />
                </td>
            </tr>
        )
    });


    const listDistanciasCentroEdit: JSX.Element[] = props.formTariff.locTariffs[indexTariffSelected!] && props.formTariff.locTariffs[indexTariffSelected!].extraTariffs.map((distanciaCentro, index) => {
        return (
            <tr className="table-row"
                style={{ height: "40px" }}
                key={index}
            >
                <td>
                    <NumberFormat
                        placeholder="0"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" km"}
                        value={distanciaCentro.hasta}
                        className={validateNumberFormEmpty(distanciaCentro.hasta)}
                        onValueChange={(value) => {

                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((locTariff, index2) => {
                                    if (index2 === indexTariffSelected && locTariff.postalCode.cp === cpToValidate) {
                                        return {
                                            ...locTariff,
                                            extraTariffs: locTariff.extraTariffs.map((tariff2, index3) => {
                                                if (index3 === index) {
                                                    return {
                                                        ...tariff2,
                                                        hasta: value.floatValue || 0
                                                    }
                                                }
                                                return tariff2;
                                            }),
                                        }
                                    }
                                    return locTariff;
                                }),
                            })
                        }} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" \u20AC"}
                        value={distanciaCentro.value}
                        className={validateNumberFormEmpty(distanciaCentro.value)}
                        onValueChange={(value) => {

                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((locTariff, index2) => {
                                   if (index2 === indexTariffSelected && locTariff.postalCode.cp === cpToValidate) {
                                        return {
                                            ...locTariff,
                                            extraTariffs: locTariff.extraTariffs.map((tariff2, index3) => {
                                                if (index3 === index) {
                                                    return {
                                                        ...tariff2,
                                                        value: value.floatValue || 0
                                                    }
                                                }
                                                return tariff2;
                                            }),
                                        }
                                    }
                                    return locTariff;
                                }),
                            })

                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                locTariffs: props.formTariff.locTariffs.map((locTariff, index2) => {
                                    if (index2 === indexTariffSelected && locTariff.postalCode.cp === cpToValidate) {
                                        return {
                                            ...locTariff,
                                            extraTariffs: locTariff.extraTariffs.filter((tariff, index3) => index3 !== index)                                            
                                        }
                                    }
                                    return locTariff;
                                }),
                            })

                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });


    return (

        <Fragment>

            <div className="card-body">
                <div className="row">
                    <div className="form-group col-md-5">
                        <h6 className="mb-2 font-weight-bold text-green">Historial</h6>


                        {/* HISTORIAL */}

                        <div className="table-responsive portlet-700">

                            <table className="table" style={{ height: "684px" }}>

                                <thead className="thead-light">
                                    <tr style={{ height: "40px" }}>
                                        <th scope="col" style={{ zIndex: "2" }}>Tipo vehículo</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Descripción</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Fecha de alta</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.formState === formStates.add &&
                                        <tr className="table-row" style={{ height: "40px" }}>
                                            <td>
                                                <VehiclesTypesAutocomplete
                                                    whatShow="remolques"
                                                    vehicleTypeSelected={props.formTariff.vehicleType}
                                                    onClick={(vehiType) => {
                                                        props.setFormTariff({
                                                            ...props.formTariff,
                                                            vehicleType: vehiType,
                                                        })
                                                    }} />
                                            </td>
                                            <td><input
                                                type="text"
                                                onChange={onValueChanged}
                                                value={props.formTariff.description}
                                                className={validateFormEmpty(props.formTariff.description, 2)}
                                                name="description" />
                                            </td>
                                            <td>
                                                <label>{props.timestamp}</label>
                                            </td>
                                        </tr>
                                    }

                                    {(props.allTariff.length > 0 || props.formState === formStates.add || props.formState === formStates.edit) ?
                                        listTariffs
                                        :
                                        <tr className="mt-2">
                                            <td>No existen tarifas</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }

                                    {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                    <tr className="table-row" style={{ cursor: "default" }}>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                        <div className="table-responsive portlet-600">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr style={{ height: "62px" }}>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>


                    {/* VALORES */}

                    {((props.formTariff.locTariffs.length > 0) || ((props.formState === formStates.add) || (props.formState === formStates.edit))) &&
                        <div className="form-group col-md-4">

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h6 className="mb-2 font-weight-bold text-green">Valores</h6>
                                {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff.locTariffs.length > 0) && 
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Porcentaje a aumentar tarifa",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0", // Opcional: establece un mínimo
                                                    max: "100",
                                                    step: "0.01" // Permite decimales en el input
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Aumentar",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (percentageIncrease) => {
                                                    if (percentageIncrease === null || percentageIncrease === "") {
                                                        Swal.showValidationMessage("Por favor ingrese un porcentaje válido");
                                                        return;
                                                    }
                                                    const increaseFactor = 1 + (parseFloat(percentageIncrease) / 100);
                                                    return { increaseFactor: increaseFactor };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    props.setFormTariff({
                                                        ...props.formTariff,
                                                        locTariffs: props.formTariff.locTariffs.map((tariff, index) => {
                                                            return {
                                                                ...tariff,
                                                                value: Math.ceil((tariff.value * result.value.increaseFactor) * 100) / 100
                                                            }
                                                        }),
                                                        minImport: Math.ceil((props.formTariff.minImport * result.value.increaseFactor) * 100) / 100,
                                                        auxImport: Math.ceil((props.formTariff.auxImport * result.value.increaseFactor) * 100) / 100,
                                                    });
                                                    const percentageIncrease = (result.value.increaseFactor * 100 - 100).toFixed(2);
                                                    Swal.fire({
                                                        title: `Tarifas aumentadas en un ${percentageIncrease}%`,
                                                        icon: "success"
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <i className="fa fa-plus"></i> Aumentar Tarifas
                                    </button>
                                }
                            </div>

                            <div className="table-responsive portlet-700">
                                <table className="table" style={{ height: "684px" }}>

                                    <thead className="thead-light">
                                        <tr style={{ height: "40px" }}>
                                            <th scope="col" style={{ zIndex: "2" }}>Población</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Valor</th>
                                            {(props.formState === formStates.add || props.formState === formStates.edit)
                                                && props.formTariff && props.formTariff.locTariffs &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {props.formState === formStates.none && props.formTariff && props.formTariff.locTariffs &&
                                            listTariffsForm
                                        }

                                        {(props.formState === formStates.add || props.formState === formStates.edit)
                                            && props.formTariff && props.formTariff.locTariffs &&
                                            addFormTariff
                                        }
                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}

                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                            <div className="table">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr style={{ height: "62px" }}>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    <div className="right">
                                                        {/* AÑADIR FILA A TARIFA*/}
                                                        <button className="btn btn-success btn-green"
                                                            onClick={() => {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    locTariffs: [
                                                                        ...props.formTariff.locTariffs,
                                                                        {
                                                                            postalCode: inicializePostalCode,
                                                                            locationType: locationType.none,
                                                                            origin: {
                                                                                address: inicialiceAddress,
                                                                                name: ""
                                                                            },
                                                                            destiny: {
                                                                                address: inicialiceAddress,
                                                                                name: ""
                                                                            },
                                                                            value: 0,
                                                                            extraTariffs: []
                                                                        }]
                                                                })
                                                                setTariffSelectedType(locationType.none)
                                                                setTariffSelType(locationType.none)
                                                                setIndexTariffSelected(props.formTariff.locTariffs.length)
                                                            }}>
                                                            <i className="fas fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                        </div>
                    }



                    {/* INCREMENTO POR DISTANCIA AL CENTRO */}
                    {(indexTariffSelected !== null && tariffSelectedType !== locationType.cp && tariffSelType !== locationType.cp) &&
                        <div className="form-group col-md-3">
                            <h6 className="mb-2 font-weight-bold text-green">Incremento por distancia al centro</h6>

                            <div className="table-responsive portlet-700">
                                <table className="table" style={{ height: "684px" }}>

                                    <thead className="thead-light">
                                        <tr style={{ height: "40px" }}>
                                            <th scope="col" style={{ zIndex: "2" }}>Desde distancia</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Valor</th>
                                            {(props.formState === formStates.add || props.formState === formStates.edit)
                                                && props.formTariff && props.formTariff.locTariffs &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {(
                                            indexTariffSelected !== null &&
                                            props.formTariff.locTariffs &&
                                            props.formTariff.locTariffs[indexTariffSelected] &&
                                            props.formTariff.locTariffs[indexTariffSelected].extraTariffs &&
                                            props.formTariff.locTariffs[indexTariffSelected].extraTariffs.length > 0)
                                            ?
                                            <>
                                                {props.formState === formStates.none &&
                                                    listDistanciasCentro
                                                }

                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    listDistanciasCentroEdit
                                                }
                                            </>
                                            :
                                            <tr
                                                className="table-row"
                                                style={{ height: "40px" }}
                                            >
                                                <td>
                                                    No existen registros
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        }

                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                            <div className="table">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr style={{ height: "62px" }}>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                    <div className="right">
                                                        {/* AÑADIR FILA A TARIFA*/}
                                                        <button className="btn btn-success btn-green"
                                                            onClick={() => {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    locTariffs: props.formTariff.locTariffs.map((locTariff, index) => {
                                                                        if (index === indexTariffSelected && cpToValidate === locTariff.postalCode.cp) {
                                                                            return {
                                                                                ...locTariff,
                                                                                extraTariffs: [
                                                                                    ...locTariff.extraTariffs,
                                                                                    {
                                                                                        hasta: 0,
                                                                                        value: 0,
                                                                                        reissues: 0
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }
                                                                        return locTariff;
                                                                    }),
                                                                })
                                                            }}>
                                                            <i className="fas fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    }

                </div>
            </div>


        </Fragment>

    );

}

export default CargaPoblationsTariffs;