import React, {ReactElement, ChangeEvent, useEffect, useState, useRef} from "react";
import {
    lineaViajeCarga,
    addingOrEditing,
    inicializeLineaViajeCarga,
    vehicleType,
    validateNumberFormEmpty,
    supplier
} from "aseguisShared";
import InputNumber from "../../../common/Inputs/InputNumber";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import InputTime from "../../../common/Inputs/InputTime";
import {UseDebounce} from "../../../common/UseDebounce/UseDebounce";

interface infoToFormHoras {
    isEdditing: number;
    addOrEdit: number,
    saveLineaHoras: (LineaHoras: lineaViajeCarga, noCalculate?: boolean, recalculate?: boolean,) => void
    saveExistingLinea: () => void
    addLinea: () => void
    lineaHoras: lineaViajeCarga
    vehicleType: vehicleType
    supplier: supplier
    tariffText: string
}

function FormHoras(props: infoToFormHoras): ReactElement {

    const [errorForm, setErrorForm] = React.useState(false);
    const [goToCalculateHoras, setGoToCalculateHoras] = React.useState(false);

    const [tempValueDto, setTempValueDto] = useState(0);
    const debouncedValueDto = UseDebounce(tempValueDto.toString(), 400); // 1000ms = 1 segundo
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        props.saveLineaHoras({
            ...props.lineaHoras,
            descuento: tempValueDto
        })
    }, [debouncedValueDto]);

    useEffect(() => {
        //si hay error en algun sitio, grabamos error general
        if (
            props.lineaHoras.horas <= 0 ||
            props.lineaHoras.importe <= 0
        ) {
            setErrorForm(true)
        } else {
            setErrorForm(false)
        }
    }, [props.lineaHoras]);

    useEffect(() => { //calculamos horas
        if (goToCalculateHoras === true) {
            let newHours = calculateHours(props.lineaHoras.desdeHoras, props.lineaHoras.hastaHoras)
            props.saveLineaHoras({
                ...props.lineaHoras,
                horas: newHours ? newHours : props.lineaHoras.horas,
                horasProveedor: newHours ? newHours : props.lineaHoras.horasProveedor,
            })
        }
        setGoToCalculateHoras(false)
    }, [goToCalculateHoras]);

    function diffHours(start: string, end: string) {
        var startTime = start.split(":");
        var endTime = end.split(":");

        if (startTime.length === 2 && endTime.length === 2) {

            const HourStart: number = +startTime[0]
            const HourFinish: number = +startTime[1]

            const minutesStart: number = +endTime[0]
            const minutesFinish: number = +endTime[1]

            var startDate = new Date(0, 0, 0, HourStart, HourFinish, 0);
            var endDate = new Date(0, 0, 0, minutesStart, minutesFinish, 0);

            var diff = endDate.getTime() - startDate.getTime();
            var hours = Math.floor(diff / 1000 / 60 / 60);
            diff -= hours * 1000 * 60 * 60;
            var minutes = Math.floor(diff / 1000 / 60);

            if (hours < 0)
                hours = hours + 24;

            return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        }

        return ""
    }

    const calculateHours = (desdeHoras: string, hastaHoras: string) => {
        if (desdeHoras.length > 0 && hastaHoras.length > 0) {
            let diff = diffHours(props.lineaHoras.desdeHoras, props.lineaHoras.hastaHoras)
            const split = diff.split(":")
            const hour: number = +split[0]
            const minutes: number = +split[1]
            let calcPorc = Math.ceil(minutes * 100 / 60)
            let newDiff2 = calcPorc / 100
            let total1 = hour + newDiff2
            let calculatedHoras = (Math.round(total1 * 100) / 100).toFixed(2);
            const newHours: number = +calculatedHoras
            return newHours
        }
    }

    return (
        <div className="card-body">

            {props.isEdditing === addingOrEditing.edditing &&
                <div>

                    <div className="form-row">

                        <InputTime
                            colMesure="2"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Hora desde"
                            valueEdit={props.lineaHoras.desdeHoras}
                            value={props.lineaHoras.desdeHoras}
                            onChange={(time) => {
                                props.saveLineaHoras({
                                    ...props.lineaHoras,
                                    desdeHoras: time,
                                })
                                setGoToCalculateHoras(true)
                            }}
                        />

                        <InputTime
                            colMesure="2"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Hora hasta"
                            valueEdit={props.lineaHoras.hastaHoras}
                            value={props.lineaHoras.hastaHoras}
                            onChange={(time) => {
                                props.saveLineaHoras({
                                    ...props.lineaHoras,
                                    hastaHoras: time,
                                })
                                setGoToCalculateHoras(true)
                            }}
                        />

                        <InputNumber
                            className="form-control"
                            colMesure="1"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="€ / hora"
                            valueEdit={props.lineaHoras.eurosHora ? props.lineaHoras.eurosHora : 0}
                            value={props.lineaHoras.eurosHora ? props.lineaHoras.eurosHora : 0}
                            onChange={(value) => {
                                const eurosHora = value.floatValue ? value.floatValue : 0
                                props.saveLineaHoras({
                                    ...props.lineaHoras,
                                    eurosHora: eurosHora
                                })
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            showNumber0={true}
                            suffix={" \u20AC"}
                        />

                        <InputNumber
                            className={validateNumberFormEmpty(props.lineaHoras.horas, 0)}
                            colMesure={"2"}
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name={props.lineaHoras.horas > 0 ? "Total horas (" + calculateHoursToString(props.lineaHoras.horas) + ")" : "Total horas"}
                            valueEdit={props.lineaHoras.horas}
                            value={props.lineaHoras.horas}
                            onChange={(value) => {
                                const horas = value.floatValue ? value.floatValue : 0
                                props.saveLineaHoras({
                                    ...props.lineaHoras,
                                    horas: horas,
                                    horasProveedor: horas
                                })
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            showNumber0={true}
                        />

                        {(props.supplier._id && props.supplier._id.length > 0) &&
                            <InputNumber
                                className="form-control undefined"
                                colMesure="2"
                                editEnabled={props.isEdditing}
                                addOrEdit={props.addOrEdit}
                                name={props.lineaHoras.horasProveedor || 0 > 0 ? "Total SubContrata (" + calculateHoursToString(props.lineaHoras.horasProveedor || 0) + ")" : "Total SubContrata"}
                                valueEdit={props.lineaHoras.horasProveedor || 0}
                                value={props.lineaHoras.horasProveedor || 0}
                                onChange={(value) => {
                                    const horasProveedor = value.floatValue ? value.floatValue : 0
                                    props.saveLineaHoras({
                                        ...props.lineaHoras,
                                        horasProveedor: horasProveedor
                                    })
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                showNumber0={true}
                            />
                        }

                        <div
                            className={("form-group col-md-") + ((props.supplier._id && props.supplier._id.length > 0) ? "3" : "5")}>

                            <div>

                                <label style={{fontWeight: 'bold'}} htmlFor="name"> Otros conceptos </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="input-group mb-2">
                                        <input
                                            name="concepto"
                                            type="text"
                                            value={props.lineaHoras.concepto}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                props.saveLineaHoras({
                                                    ...props.lineaHoras,
                                                    concepto: event.target.value.trimStart()
                                                }, true)
                                            }}
                                            className="form-control"
                                        />
                                    </div>
                                    : <div>{props.lineaHoras.concepto}</div>}
                            </div>
                        </div>

                    </div>

                    <div className="form-row" style={{marginTop: '20px'}}>
                        <div className="form-group col-md-7">
                            <div>
                                {(props.isEdditing === addingOrEditing.edditing) &&
                                    <div>
                                        {/*props.tariffText*/}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-1" style={{marginTop: '8px'}}>
                            <div> Descuento (%):</div>
                            <div style={{marginTop: "14px"}}> Importe:</div>
                            {(props.supplier._id && props.supplier._id.length > 0) &&
                                <div style={{marginTop: "14px"}}> SubContrata:</div>
                            }
                        </div>

                        <div className="form-group col-md-2">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix={" %"}
                                value={props.lineaHoras.descuento}
                                className="form-control undefined"
                                isAllowed={(value: NumberFormatValues) => {
                                    const {floatValue} = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    if (numberValue) {
                                        return numberValue < 100;
                                    } else return true
                                }}
                                onValueChange={(value: NumberFormatValues) => {
                                    const {floatValue} = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    props.saveLineaHoras({
                                        ...props.lineaHoras,
                                        descuento: numberValue
                                    }, true)
                                    setTempValueDto(numberValue)
                                }}
                            />
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                onValueChange={(value) => {
                                    props.saveLineaHoras({
                                        ...props.lineaHoras,
                                        importe: value.floatValue ? value.floatValue : 0
                                    }, true)
                                }}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={props.lineaHoras.importe}
                                className={validateNumberFormEmpty(props.lineaHoras.importe, 1)}
                                suffix={" \u20AC"}
                            />
                            {(props.supplier._id && props.supplier._id.length > 0) &&
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    onValueChange={(value) => {
                                        props.saveLineaHoras({
                                            ...props.lineaHoras,
                                            importeProveedor: value.floatValue ? value.floatValue : 0
                                        }, true)
                                    }}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={props.lineaHoras.importeProveedor}
                                    className="form-control undefined"
                                    suffix={" \u20AC"}
                                />
                            }
                        </div>

                        <div style={{marginTop: "18px"}}>
                            <button
                                className="btn btn-info left-margin btn-sm"
                                onClick={() => {
                                    let newHours = calculateHours(props.lineaHoras.desdeHoras, props.lineaHoras.hastaHoras)
                                    props.saveLineaHoras({
                                        ...props.lineaHoras,
                                        horas: newHours ? newHours : props.lineaHoras.horas,
                                        horasProveedor: newHours ? newHours : props.lineaHoras.horasProveedor
                                    }, false, true)
                                }}>
                                Recalcular
                            </button>
                        </div>

                    </div>

                </div>

            }


            {props.isEdditing === addingOrEditing.edditing &&
                <div className="form-row">

                    {/* LIMPIAR FORMULARIO LINEA VIAJE*/}
                    <div className="form-group col-md-6">
                        <button className="btn btn-info btn-sm"
                                onClick={() => {
                                    props.saveLineaHoras(inicializeLineaViajeCarga)
                                }}>
                            Limpiar formulario
                        </button>
                    </div>

                    <div className="form-group col-md-6 right">
                        {/* GUARDAR FILA EDITADA A VIAJES*/}
                        {props.lineaHoras.id > 0 &&
                            <button
                                className={errorForm === false ? "btn btn-info btn-sm left-margin" : "btn btn-info btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (errorForm === false) {
                                        props.saveExistingLinea()
                                    }
                                }}>
                                Aplicar cambios
                            </button>
                        }

                        {/* ANADIR FILA A VIAJES*/}
                        <button
                            className={errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                            onClick={() => {
                                if (errorForm === false) {
                                    props.addLinea()
                                }
                            }}>
                            Añadir fila
                        </button>

                    </div>

                </div>
            }


        </div>

    );
};


export const calculateConceptoHoras = (line: lineaViajeCarga) => {
    let horas = line.horas
    let horasString = calculateHoursToString(horas);
    let newConcepto = ""
    let conceptoHoras = "Total Horas: " + horasString
    if (horas > 0) {
        if (line.desdeHoras.length > 0 && line.hastaHoras.length > 0) {
            let conceptoDesdeHasta = "Desde: " + line.desdeHoras + "h hasta: " + line.hastaHoras + "h. " + conceptoHoras
            newConcepto = conceptoDesdeHasta
        } else {
            newConcepto = conceptoHoras
        }
    } else {
        newConcepto = conceptoHoras
    }
    newConcepto = "- " + newConcepto
    return newConcepto
}

export const calculateHoursToString = (decimalHours: number) => {
    // Extraer la parte entera (horas) y la parte decimal (minutos)
    const hours = Math.floor(decimalHours);
    const minutesDecimal = decimalHours - hours;
    // Convertir la parte decimal a minutos
    const minutes = Math.round(minutesDecimal * 60);
    // Formatear el resultado
    return `${hours}h ${minutes}m`;
}

export default FormHoras;