"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicialiceLoginUser = void 0;
const commonFunctions_1 = require("./commonFunctions");
exports.inicialiceLoginUser = {
    _id: null,
    id: 0,
    nif: "",
    email: "",
    passwordHash: "",
    name: "",
    apellido1: "",
    apellido2: "",
    tokenAccess: "",
    tokenAccessApp: "",
    tokenRefresh: "",
    userType: "",
    permisosUsuario: commonFunctions_1.inicialicePermisosUsuario
};
