"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = exports.formattLineType = exports.getCoordinatesGoogleURL = exports.existNumberOnString = exports.replaceAnyDirectionFieldsOnStreet = exports.quitarAcentosyMayusculas = exports.splitTextBySpaces = exports.mountRefere = exports.nombreCompleto = exports.DateIsNearVto = exports.inicialiceRefereRequest = exports.inicialiceDate = exports.initSuscriptionData = exports.inicialicePermisosUsuario = exports.initInfotoApiSign = exports.initInfoSign = exports.initGeoPosition = exports.initGeoCoordinates = exports.inicialiceAddress = void 0;
exports.formatearMatricula = formatearMatricula;
exports.formatedDirWithName = formatedDirWithName;
exports.formatedDirection = formatedDirection;
exports.compareAddress = compareAddress;
exports.sumarPlazoEntrega = sumarPlazoEntrega;
exports.calculateNextControl = calculateNextControl;
exports.CapitalizefirstLetter = CapitalizefirstLetter;
exports.invertColor = invertColor;
exports.OrderArraybyPropertiesOf = OrderArraybyPropertiesOf;
exports.sortArrayByProps = sortArrayByProps;
const commonEnums_1 = require("../enums/commonEnums");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
exports.inicialiceAddress = {
    calle: "",
    numero: "",
    puerta: "",
    localidad: "",
    codigoPostal: "",
    provincia: "",
    pais: "",
    vicinity: "",
    url: "",
    placeId: "",
    otherName: "",
    formatedAddress: "",
    cpMongo: postalCodeFunctions_1.inicializePostalCode
};
exports.initGeoCoordinates = {
    latitude: 0,
    longitude: 0,
    accuracy: 0,
    altitude: 0,
    heading: 0,
    speed: 0,
    altitudeAccuracy: 0
};
exports.initGeoPosition = {
    coords: exports.initGeoCoordinates,
    timestamp: 0,
    mocked: false,
    provider: ""
};
exports.initInfoSign = {
    sign: "",
    signTime: new Date(),
    signName: "",
    signDni: "",
    signPosition: exports.initGeoPosition
};
exports.initInfotoApiSign = {
    idAlbaran: "",
    remitenteSign: exports.initInfoSign,
    destinatarioSign: exports.initInfoSign,
    indexDestination: 0,
    indexLineaViaje: 0
};
exports.inicialicePermisosUsuario = {
    idAssociated: "",
    isAdmin: false,
    accessEnabled: false,
    accessAppMvEnabled: false,
    panel: commonEnums_1.UserPermissions.deshabilitado,
    compania: commonEnums_1.UserPermissions.deshabilitado,
    companiaTariff: commonEnums_1.UserPermissions.deshabilitado,
    clients: commonEnums_1.UserPermissions.deshabilitado,
    clientsTariffs: commonEnums_1.UserPermissions.deshabilitado,
    employees: commonEnums_1.UserPermissions.deshabilitado,
    proveedores: commonEnums_1.UserPermissions.deshabilitado,
    vehicles: commonEnums_1.UserPermissions.deshabilitado,
    vehiclesControls: commonEnums_1.UserPermissions.deshabilitado,
    vehiclesReviRepa: commonEnums_1.UserPermissions.deshabilitado,
    grupajes: commonEnums_1.UserPermissions.deshabilitado,
    cargaCompleta: commonEnums_1.UserPermissions.deshabilitado,
    paqueteria: commonEnums_1.UserPermissions.deshabilitado,
    bills: commonEnums_1.UserPermissions.deshabilitado
};
exports.initSuscriptionData = {
    pricesId: [],
    customerId: "",
    suscriptionId: "",
    trialPeriodEnds: "",
    suscriptionStatus: "canceled",
    isSuscriptionActive: false, //--- si NO está activa, no dejamos añadir nuevos datos ni editar existentes
    suscriptionName: "",
    soportePremium: false, //-- si NO tiene premium, solo mostramos el email de soporte en la barra superior
    empleados: false, //-- la api no deja añadir nuevos. y da mensaje de error si se intenta añadir
    proveedoresTransportes: false, //quitamos en cargaForm y paqForm. filtro en paq y carga. admin y menu top
    reviRepa: false, //quitamos en admin y menu top
    entregasMasivas: false, //quitamos en admin y menu top
    controlesVehiculos: false, //quitamos en admin y menu top
    remesas: false, //quitamos en admin y menu top
    paqueteria: false, //quitamos en admin, menu top, abonoForm, BilldsAddForm y tariff
    crmClientes: false, //se toca el login. y el clientForm para quitar userpermisions
};
exports.inicialiceDate = {
    year1000: new Date('0001-01-01T00:00:00Z'),
};
exports.inicialiceRefereRequest = {
    refere: "",
    filterClientId: "",
    idsToExclude: [],
    fromDate: exports.inicialiceDate.year1000,
    untilDate: exports.inicialiceDate.year1000,
    onlyFacturated: false,
    onlyDelivered: false,
    notShowFacturated: false,
};
//FUNCIONES
//FUNCIONES
function formatearMatricula(matricula) {
    if (matricula === "") {
        return matricula;
    }
    let formattedMatricula = "";
    const firstPosition = matricula[0];
    // Comprobar si el primer carácter NO es un número (matrículas que comienzan con letra)
    if (isNaN(Number(firstPosition))) {
        formattedMatricula = firstPosition + "-";
        // Construir la sección de números de la matrícula
        for (let i = 1; i < 5; i++) {
            if (matricula[i]) {
                formattedMatricula += matricula[i];
            }
        }
        // Antes de añadir el segundo guion, comprobar si el siguiente carácter es un número
        // Si es un número, añadir el guion una posición más adelante.
        if (!isNaN(Number(matricula[5]))) {
            formattedMatricula += matricula[5] + "-"; // Incluir el número antes del guion
            for (let i = 6; i < matricula.length; i++) {
                if (matricula[i]) {
                    formattedMatricula += matricula[i];
                }
            }
        }
        else {
            formattedMatricula += "-"; // Añadir el guion normalmente
            for (let i = 5; i < matricula.length; i++) {
                if (matricula[i]) {
                    formattedMatricula += matricula[i];
                }
            }
        }
    }
    else {
        // Para matrículas que comienzan con número (sin letras al inicio)
        formattedMatricula = firstPosition;
        for (let i = 1; i < 4; i++) {
            if (matricula[i]) {
                formattedMatricula += matricula[i];
            }
        }
        formattedMatricula += "-"; // Añadir el guion
        for (let i = 4; i < matricula.length; i++) {
            if (matricula[i]) {
                formattedMatricula += matricula[i];
            }
        }
    }
    return formattedMatricula;
}
function formatedDirWithName(address, name, withDoor, short) {
    var formatName = "";
    if (address) {
        if (name && name !== "") {
            formatName = name.trim();
            formatName = formatName.trim() + ", " + formatedDirection(address, withDoor, short).trimEnd();
            formatName = formatName.trim();
            return formatName;
        }
        else {
            return formatedDirection(address, withDoor, short).trimEnd();
        }
    }
    return formatName;
}
function formatedDirection(address, withDoor, short) {
    var formatDir = "";
    if (address) {
        if (address.calle === "" && address.numero === "" && address.localidad === "" &&
            address.codigoPostal === "" && address.provincia === "") {
            return formatDir;
        }
        if (short) {
            formatDir = address.localidad.trim();
            formatDir = formatDir.trim() + " " + address.provincia;
            formatDir = formatDir.trim();
            return formatDir;
        }
        if (address.numero) {
            if (withDoor && address.puerta && address.puerta !== "") {
                formatDir = address.calle.trim();
                formatDir = formatDir.trim() + " " + address.numero;
                formatDir = formatDir.trim() + " " + address.puerta;
                formatDir = formatDir.trim() + " " + address.localidad;
                formatDir = formatDir.trim() + " " + address.codigoPostal;
                formatDir = formatDir.trim() + " " + address.provincia;
                formatDir = formatDir.trim() + " " + address.pais;
                formatDir = formatDir.trim();
            }
            else {
                formatDir = address.calle.trim();
                formatDir = formatDir.trim() + " " + address.numero;
                formatDir = formatDir.trim() + " " + address.localidad;
                formatDir = formatDir.trim() + " " + address.codigoPostal;
                formatDir = formatDir.trim() + " " + address.provincia;
                formatDir = formatDir.trim() + " " + address.pais;
                formatDir = formatDir.trim();
            }
        }
        else {
            formatDir = address.calle.trim();
            formatDir = formatDir.trim() + " " + address.localidad;
            formatDir = formatDir.trim() + " " + address.codigoPostal;
            formatDir = formatDir.trim() + " " + address.provincia;
            formatDir = formatDir.trim() + " " + address.pais;
            formatDir = formatDir.trim();
        }
    }
    formatDir = formatDir.replace("  ", " ");
    formatDir = formatDir.replace("   ", " ");
    formatDir = formatDir.replace("    ", " ");
    formatDir = formatDir.replace("     ", " ");
    formatDir = formatDir.replace("      ", " ");
    formatDir = formatDir.replace("       ", " ");
    return formatDir;
}
function compareAddress(address1, address2) {
    if (address1 && address2) {
        if ((address1.calle === address2.calle) &&
            (address1.codigoPostal === address2.codigoPostal) &&
            (address1.localidad === address2.localidad) &&
            (address1.numero === address2.numero) &&
            (address1.pais === address2.pais) &&
            (address1.provincia === address2.provincia) &&
            (address1.puerta === address2.puerta)) {
            return true;
        }
    }
    return false;
}
function sumarPlazoEntrega(dateToSum, plazoEntrega, diasFestivos) {
    let dateToReturn = dateToSum;
    switch (plazoEntrega) {
        case commonEnums_1.plazosEntrega.null: {
            break;
        }
        case commonEnums_1.plazosEntrega.h12: {
            let date = new Date(new Date(dateToSum).setHours(dateToSum.getHours() + 12));
            dateToReturn = date;
            break;
        }
        case commonEnums_1.plazosEntrega.h24: {
            let date = new Date(new Date(dateToSum).setHours(dateToSum.getHours() + 24));
            dateToReturn = date;
            break;
        }
        case commonEnums_1.plazosEntrega.h48: {
            let date = new Date(new Date(dateToSum).setHours(dateToSum.getHours() + 48));
            dateToReturn = date;
            break;
        }
        case commonEnums_1.plazosEntrega.h72: {
            let date = new Date(new Date(dateToSum).setHours(dateToSum.getHours() + 72));
            dateToReturn = date;
            break;
        }
        default: {
            break;
        }
    }
    if (diasFestivos) {
        while (diasFestivos.includes(dateToReturn.getDay())) {
            dateToReturn.setDate(dateToReturn.getDate() + 1);
        }
    }
    return dateToReturn;
}
function calculateNextControl(controlDate, periodicidad) {
    var nextControl = new Date();
    controlDate = new Date(controlDate);
    var year = controlDate.getFullYear();
    var month = controlDate.getMonth();
    var day = controlDate.getDate();
    switch (periodicidad) {
        case commonEnums_1.Periodicidades.anual: {
            var nextControl = new Date(year + 1, month, day);
            break;
        }
        case commonEnums_1.Periodicidades.diaria: {
            var nextControl = new Date(year, month, day + 1);
            break;
        }
        case commonEnums_1.Periodicidades.mensual: {
            var nextControl = new Date(year, month + 1, day);
            break;
        }
        case commonEnums_1.Periodicidades.quincenal: {
            var nextControl = new Date(year, month, day + 15);
            break;
        }
        case commonEnums_1.Periodicidades.semanal: {
            var nextControl = new Date(year, month, day + 7);
            break;
        }
        case commonEnums_1.Periodicidades.semestral: {
            var nextControl = new Date(year, month + 6, day);
            break;
        }
        case commonEnums_1.Periodicidades.trimestral: {
            var nextControl = new Date(year, month + 3, day);
            break;
        }
        default: {
            break;
        }
    }
    return nextControl;
}
function CapitalizefirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
const DateIsNearVto = (inputDate) => {
    const currentDate = new Date();
    const reviewDate = new Date(inputDate);
    currentDate.setHours(0, 0, 0, 0); // Establecer la hora actual a medianoche
    reviewDate.setHours(0, 0, 0, 0); // Establecer la hora de la fecha de revisión a medianoche
    // Si la fecha de revisión es anterior a la fecha actual
    if (reviewDate < currentDate) {
        return '#E23F3F'; // La revisión está vencida
    }
    // Calcular la fecha límite para ser considerado "próximo" (3 meses)
    const thresholdDate = new Date(currentDate);
    thresholdDate.setMonth(currentDate.getMonth() + 3);
    // Si la fecha de revisión es menor o igual a la fecha límite (3 meses)
    if (reviewDate <= thresholdDate) {
        return 'orange'; // La revisión es próxima
        //return '#FFB13A'; // La revisión es próxima
    }
    // Si no se cumplen las condiciones anteriores, devolver blanco
    return 'black';
};
exports.DateIsNearVto = DateIsNearVto;
const nombreCompleto = (employee) => {
    if (employee.name.length > 0 || employee.apellido1.length > 0 || employee.apellido2.length > 0) {
        return (employee.name + " " + employee.apellido1 + " " + employee.apellido2);
    }
    return "";
};
exports.nombreCompleto = nombreCompleto;
const mountRefere = (referes) => {
    var text = "";
    for (let i = 0; i < referes.length; i++) {
        text = text + referes[i];
    }
    return (text);
};
exports.mountRefere = mountRefere;
const splitTextBySpaces = (refere) => {
    const newValue = refere.split(" ");
    const news = [];
    for (let i = 0; i < newValue.length; i++) {
        if (newValue[i].trim().length > 0) {
            news.push(newValue[i].toUpperCase());
        }
    }
    return (news);
};
exports.splitTextBySpaces = splitTextBySpaces;
const quitarAcentosyMayusculas = (string) => {
    var asd = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return asd.replace(/,/g, '');
};
exports.quitarAcentosyMayusculas = quitarAcentosyMayusculas;
const replaceAnyDirectionFieldsOnStreet = (oldStreet, cpInfo) => {
    var newStreet = oldStreet;
    newStreet = newStreet.replace("  ", " ");
    newStreet = CapitalizefirstLetter(newStreet.replace(cpInfo.cp, ""));
    let split = newStreet.split(",");
    let street2 = "";
    for (var val of split) {
        if (val.trim().length > 0) {
            street2 += val;
        }
    }
    return street2.replace(/(\S*\*\S*)|\*/g, "");
};
exports.replaceAnyDirectionFieldsOnStreet = replaceAnyDirectionFieldsOnStreet;
const existNumberOnString = (text) => {
    var splitText = (0, exports.splitTextBySpaces)(text);
    var maxLength = 0;
    var isNumber = false;
    for (var text of splitText) {
        if (!isNaN(text)) {
            if (text.length > maxLength) {
                maxLength = text.length;
            }
            isNumber = true;
        }
    }
    return { isNumber, maxLength };
};
exports.existNumberOnString = existNumberOnString;
const getCoordinatesGoogleURL = (latitude, longitude) => {
    let url = "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
    return url;
};
exports.getCoordinatesGoogleURL = getCoordinatesGoogleURL;
function invertColor(hex, bw) {
    hex = hex.replace("#", "");
    var r = parseInt(hex.slice(0, 2), 16), g = parseInt(hex.slice(2, 4), 16), b = parseInt(hex.slice(4, 6), 16);
    const calcColor = r * 0.299 + g * 0.587 + b * 0.114;
    if (calcColor > 186) {
        return "#000000";
    }
    else {
        return "#ffffff";
    }
}
function OrderArraybyPropertiesOf(sortBy) {
    function compareByProperty(arg) {
        let key;
        let sortOrder = 1;
        if (typeof arg === 'string' && arg.startsWith('-')) {
            sortOrder = -1;
            // Typescript is not yet smart enough to infer that substring is keyof T
            key = arg.substr(1);
        }
        else {
            // Likewise it is not yet smart enough to infer that arg here is keyof T
            key = arg;
        }
        return function (a, b) {
            const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
            return result * sortOrder;
        };
    }
    return function (obj1, obj2) {
        let i = 0;
        let result = 0;
        const numberOfProperties = sortBy === null || sortBy === void 0 ? void 0 : sortBy.length;
        while (result === 0 && i < numberOfProperties) {
            result = compareByProperty(sortBy[i])(obj1, obj2);
            i++;
        }
        return result;
    };
}
/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
function sortArrayByProps(arr, ...sortBy) {
    arr.sort(OrderArraybyPropertiesOf(sortBy));
}
const formattLineType = (lineType) => {
    switch (lineType) {
        case "distancia": {
            return "Distancia";
        }
        case "horas": {
            return "Horas";
        }
        case "poblacion": {
            return "Población";
        }
        case "otros": {
            return "Otros";
        }
        case "trayecto": {
            return "Trayecto";
        }
        default: {
            return "";
        }
    }
};
exports.formattLineType = formattLineType;
const formatNumber = (value) => {
    const formatedValue = ('' + value).replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g, function (m, s1, s2) {
        return s2 || (s1 + ',');
    });
    return formatedValue.replace(/[.,]/g, function (x) {
        return x == ',' ? '.' : ',';
    });
};
exports.formatNumber = formatNumber;
