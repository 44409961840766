"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEstadoString = exports.initialRutasList = exports.initIncidenciasEntregas = exports.inicialicePaqueteria = exports.inicialiceDatosTransporte = exports.inicializeDatosEconomicosPaq = exports.inicializeDatosGeneralesPaq = exports.inicialicePaqueteriaValidateFieldsResult = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const clientsFunctions_1 = require("./clientsFunctions");
const commonFunctions_1 = require("./commonFunctions");
const employeesFunctions_1 = require("./employeesFunctions");
const loginFunctions_1 = require("./loginFunctions");
const parametrizacionFunctions_1 = require("./parametrizacionFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const suppliersFunctions_1 = require("./suppliersFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
const infoToCMRFunctions_1 = require("./infoToCMRFunctions");
exports.inicialicePaqueteriaValidateFieldsResult = {
    referenciaExists: false,
    cartaDePorte: false,
    anyFieldExist: false
};
exports.inicializeDatosGeneralesPaq = {
    entradaSalida: "",
    client: clientsFunctions_1.inicialiceClient,
    vehicle: vehiclesFunctions_1.initialVehicle,
    remolque: vehiclesFunctions_1.initialVehicle,
    driver: employeesFunctions_1.inicialiceEmployee,
    supplier: suppliersFunctions_1.inicializeSupplier,
    recogida: new Date(),
    entrega: (() => {
        const entregaDate = new Date();
        entregaDate.setHours(23);
        entregaDate.setMinutes(59);
        return entregaDate;
    })(),
    fecAlta: new Date(),
    observaciones: "",
    user: loginFunctions_1.inicialiceLoginUser
};
exports.inicializeDatosEconomicosPaq = {
    descuento: 0,
    subTotal: 0,
    subTotalOriginal: 0,
    reexpedicion: 0,
    whatTariffUse: "",
    tariffSelected: "",
    tariffPaq: tariffFunctions_1.inicializePaqueteriaTariff,
    agrupacionesCalculo: [],
    subTotalProveedor: 0,
    tariffProveedor: tariffFunctions_1.inicializePaqueteriaTariff
};
exports.inicialiceDatosTransporte = {
    destName: "",
    tel: "",
    address: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    destNameDos: "",
    telDos: "",
    addressDos: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    referenciaString: "",
    referencias: [],
    cartaPorte: "",
    bultos: 0,
    bultosString: "",
    kilos: 0,
    kilosString: "",
    m3: 0,
    m3String: "",
    estadoTraza: commonEnums_1.estadoTrazaEnum.none,
    dateTimesEstadoTraza: [],
    cmrInfo: infoToCMRFunctions_1.initInfoToCreateCMR
};
exports.inicialicePaqueteria = {
    _id: null,
    id: 0,
    idString: "",
    datosGenerales: exports.inicializeDatosGeneralesPaq,
    datosEconomicos: exports.inicializeDatosEconomicosPaq,
    datosTransporte: exports.inicialiceDatosTransporte,
    datosEntrega: {
        qrIdReaded: "",
        fileRelation: [],
        deliveredTime: new Date(),
        geoPosition: commonFunctions_1.initGeoPosition,
        whoDeliver: loginFunctions_1.inicialiceLoginUser
    },
    incidenciasEntregas: [],
    idBill: 0,
    idPaqRender: null,
    idAbono: ""
};
exports.initIncidenciasEntregas = {
    //id: 0,
    tipoIncidencia: parametrizacionFunctions_1.initIncidenceType,
    datosIncidencia: {
        qrIdReaded: "",
        fileRelation: [],
        deliveredTime: new Date(),
        geoPosition: commonFunctions_1.initGeoPosition,
        whoDeliver: loginFunctions_1.inicialiceLoginUser
    },
    value: 0
};
exports.initialRutasList = {
    localities: [{
            locality: "",
            paqueterias: []
        }],
    postalCodes: [{
            postalCode: "",
            paqueterias: []
        }],
    provinces: [{
            province: "",
            paqueterias: []
        }],
    countries: [{
            country: "",
            paqueterias: []
        }],
};
const getEstadoString = (estado /*, whoDelivered: loginUsers, timestamp: Date*/) => {
    switch (estado) {
        case commonEnums_1.estadoTrazaEnum.none: {
            return "Introduciendo";
        }
        case commonEnums_1.estadoTrazaEnum.introducida: {
            return "En proceso";
        }
        case commonEnums_1.estadoTrazaEnum.enTransito: {
            return "En reparto";
        }
        case commonEnums_1.estadoTrazaEnum.entregada: {
            return "Entregado";
        }
        case commonEnums_1.estadoTrazaEnum.enPause: {
            return "En pausa";
        }
        default: {
            return "";
        }
    }
};
exports.getEstadoString = getEstadoString;
