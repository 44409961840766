"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicialiceSearchFilterList = void 0;
const commonFunctions_1 = require("./commonFunctions");
exports.inicialiceSearchFilterList = {
    pageNum: 1,
    pageSize: 30,
    withTimeout: false,
    getAll: false,
    idMongo: "",
    idsMongo: [],
    id: 0,
    notBilled: false,
    ids: [],
    marcaVehicle: "",
    idString: "",
    plateVehicle: "",
    typeVehicle: "",
    concepto: "",
    camionRemolque: "",
    referencia: "",
    fechaDesde: commonFunctions_1.inicialiceDate.year1000,
    fechaHasta: commonFunctions_1.inicialiceDate.year1000,
    recogidaDesde: commonFunctions_1.inicialiceDate.year1000,
    recogidaHasta: commonFunctions_1.inicialiceDate.year1000,
    entregaDesde: commonFunctions_1.inicialiceDate.year1000,
    entregaHasta: commonFunctions_1.inicialiceDate.year1000,
    clientName: "",
    clientsIDsMongo: [],
    cartaPorteExactly: "",
    cartaPorteRegex: "",
    supplierName: "",
    driverName: "",
    employeeName: "",
    address: "",
    nif: "",
    email: "",
    idEmployee: "",
    tel: "",
    typeOfReviRepa: "",
    fueraDePlazo: false,
    enPause: false,
    enReparto: false,
    estaEntregado: false,
    estaFacturado: false,
    estaCobrada: false,
    estaVencida: false,
    sortDesc: true,
    sortClients: "#",
    sortEmployees: "#",
    sortSuppliers: "#",
    sortVehicles: "#",
    sortVehiclesControls: "#",
    sortReviRepa: "#",
    sortGrupajes: "#",
    sortCargaCompleta: "#",
    sortPaqueteria: "#",
    sortBills: "#",
    sortAbonos: "#",
};
