import {Action, Dispatch} from 'redux';
import {ApplicationState} from '../../ApplicationState';
import axios from 'axios';
import {
    inicialiceSearchFilterList, cargaCompleta, client, searchFilterList, datosEntregaRequest,
    cargaCompletaValidateFieldsResult, infoToCreateCMR, loading, addingOrEditing,
    inicialiceCargaCompleta, OrderArraybyPropertiesOf, inicialiceCargaCompletaValidateFieldsResult,
    searchedCargasCompletas, destinationsLinea
} from 'aseguisShared';
import {Notify} from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface cargaCompletaState {
    allCargasCompletas: cargaCompleta[];
    cargaCompletaForm: cargaCompleta;
    cargaCompletaEditForm: cargaCompleta;
    addOrEditCarga: number,
    cargaGetLoading: number,
    isLoadingGoogleCarga: number,
    actualPage: number,
    totalPages: number,
    searchFilterCarga: searchFilterList,
    totalCargasCompletas: number,
    validateFieldsForm: cargaCompletaValidateFieldsResult
    openCloseUploadModal: boolean
    historyAddList: cargaCompleta[]
}

export const initialState: cargaCompletaState = {
    allCargasCompletas: [],
    cargaCompletaForm: inicialiceCargaCompleta,
    totalCargasCompletas: 0,
    cargaCompletaEditForm: inicialiceCargaCompleta,
    addOrEditCarga: addingOrEditing.none,
    cargaGetLoading: loading.pending,
    searchFilterCarga: inicialiceSearchFilterList,
    isLoadingGoogleCarga: loading.done,
    validateFieldsForm: inicialiceCargaCompletaValidateFieldsResult,
    actualPage: 0,
    totalPages: 0,
    openCloseUploadModal: false,
    historyAddList: [],
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveFormCargaAction {
    type: 'SAVE_FORM_CARGA',
    cargaCompleta: cargaCompleta
}

export interface addEditCargaAction {
    type: 'CHANGE_ADD_EDIT_CARGA',
    addOrEdit: number
}

export interface addReceivedCargaAction {
    type: 'ADD_RECEIVED_CARGA',
    cargaCompleta: cargaCompleta
}

export interface saveEditReceivedCargaAction {
    type: 'SAVE_EDIT_RECEIVED_CARGA',
    cargaCompleta: cargaCompleta
}

export interface RequestAllCargaAction {
    type: 'REQUEST_ALL_CARGA'
}

export interface ReceivedAllCargaAction {
    type: 'RECEIVED_ALL_CARGA',
    cargas: searchedCargasCompletas
}

export interface ReceivedDeleteCargaAction {
    type: 'DELETE_RECEIVED_CARGA'
}

export interface saveSearchCargaAction {
    type: 'SAVE_SEARCH_FILTER_CARGA',
    searchFilterCarga: searchFilterList
}

export interface RequestGoogleAddressCargaAction {
    type: 'REQUEST_GOOGLE_ADDRESS_CARGA'
}

export interface ReceivedGoogleAddressCargaAction {
    type: 'RECEIVED_GOOGLE_ADDRESS_CARGA'
    destinations: destinationsLinea
}

export interface RequestGooglePlaceCargaAction {
    type: 'REQUEST_GOOGLE_PLACE_CARGA'
}

export interface ReceivedGooglePlaceCargaAction {
    type: 'RECEIVED_GOOGLE_PLACE_CARGA'
    client: client
}

export interface setBothFormsCargaAction {
    type: 'SET_BOTH_FORMS_CARGA',
    cargaCompleta: cargaCompleta
}

export interface setValidateFormCargaAction {
    type: 'SET_VALIDATE_FORM_CARGA',
    form: cargaCompletaValidateFieldsResult
}

export interface setOpenCloseModalUploadFileCargaAction {
    type: 'SET_OPEN_CLOSE_MODAL_CARGA',
    openClose: boolean
}

export interface setHistoryAddCargaAction {
    type: 'SET_HISTORY_ADD_CARGA',
    historyAddList: cargaCompleta[]
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormCargaAction | ReceivedDeleteCargaAction |
    addReceivedCargaAction | setOpenCloseModalUploadFileCargaAction | saveEditReceivedCargaAction | RequestAllCargaAction | ReceivedAllCargaAction |
    addEditCargaAction | saveSearchCargaAction | RequestGoogleAddressCargaAction |
    ReceivedGoogleAddressCargaAction | setHistoryAddCargaAction | RequestGooglePlaceCargaAction |
    ReceivedGooglePlaceCargaAction | setBothFormsCargaAction | setValidateFormCargaAction

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setFormsCarga(cargaCompleta: cargaCompleta): setBothFormsCargaAction {
    return {
        type: 'SET_BOTH_FORMS_CARGA',
        cargaCompleta: cargaCompleta
    };
}

export function saveFormCarga(cargaCompleta: cargaCompleta): saveFormCargaAction {
    return {
        type: 'SAVE_FORM_CARGA',
        cargaCompleta: cargaCompleta
    };
}

export function addOrEditCarga(addOrEdit: number): addEditCargaAction {
    return {
        type: 'CHANGE_ADD_EDIT_CARGA',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterCarga(searchFilterCarga: searchFilterList): saveSearchCargaAction {
    return {
        type: 'SAVE_SEARCH_FILTER_CARGA',
        searchFilterCarga: searchFilterCarga
    };
}

export function setValidateCargaForm(form: cargaCompletaValidateFieldsResult): setValidateFormCargaAction {
    return {
        type: 'SET_VALIDATE_FORM_CARGA',
        form: form
    };
}

export function setOpenCloseModalUploadFileCarga(openClose: boolean): setOpenCloseModalUploadFileCargaAction {
    return {
        type: 'SET_OPEN_CLOSE_MODAL_CARGA',
        openClose: openClose
    };
}

export function setHistoryAddListCarga(historyList: cargaCompleta[]): setHistoryAddCargaAction {
    return {
        type: 'SET_HISTORY_ADD_CARGA',
        historyAddList: historyList
    };
}


/**************************************************************
 **************************************************************
 *
 *
 * ************************************************************
 * @param cargaCompleta
 **************************************************************/

export async function createCargaCompletaApi(cargaCompleta: cargaCompleta) {
    try {
        const response = await axios.post('api/cargaCompleta/', cargaCompleta);
        return response.data;
    } catch (error) {
        console.error("Error al crear la carga completa", error);
        throw error;
    }
}

export function addCarga(cargaCompleta: cargaCompleta) {
    return async (dispatch: Dispatch) => {
        dispatch({type: 'REQUEST_ALL_CARGA'});
        return createCargaCompletaApi(cargaCompleta)
            .then((data) => {
                Notify("Albarán carga creado", "success");
                dispatch({type: 'ADD_RECEIVED_CARGA', cargaCompleta: data});
            })
            .catch((error) => {
            });
    };
}

export function editCarga(cargaCompleta: cargaCompleta) {
    return async (dispatch: Dispatch) => {
        dispatch({type: 'REQUEST_ALL_CARGA'});
        return axios.put('api/cargaCompleta/', cargaCompleta)
            .then((data) => {
                Notify("Albarán carga modificado", "success")
                dispatch({type: 'SAVE_EDIT_RECEIVED_CARGA', cargaCompleta: data.data});
            })
            .catch((error) => {
            })
    }
}

export function deleteOneCargaCompleta(cargaCompleta: cargaCompleta) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/cargaCompleta/delete', cargaCompleta)
            .then(res => {
                Notify("Albarán carga eliminado", "success")
                dispatch({type: 'SAVE_EDIT_RECEIVED_CARGA', cargaCompleta: inicialiceCargaCompleta});
                const searchFilter = getState().cargaCompleta.searchFilterCarga
                dispatch(requestAllCargas(searchFilter))
            })
            .catch((error) => {
            })
    }
}

export function requestAllCargas(searchCargaFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({type: 'REQUEST_ALL_CARGA'});
        return axios.post<searchedCargasCompletas>('api/cargaCompleta/searchFilter/', searchCargaFilter)
            .then((data) => {
                dispatch({type: 'RECEIVED_ALL_CARGA', cargas: data.data});
            })
            .catch((error) => {
            })
    }
}

export const requestOneCarga = async (idCarga: string) => {
    let searchFilter = {...inicialiceSearchFilterList, idMongo: idCarga}
    return await axios.post<searchedCargasCompletas>('api/cargaCompleta/searchFilter/', searchFilter)
}

export function validateIfExistsFields(carga: cargaCompleta) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/cargaCompleta/validateExists/', carga)
            .then((data) => {
                return dispatch({type: 'SET_VALIDATE_FORM_CARGA', form: data.data});
            })
            .catch((error) => {
            })
    }
}

export function downloadCargaPDF(cargas: number[], client: client | null) {
    let newFilter = {...inicialiceSearchFilterList, ids: cargas, getAll: true}
    if (client && client._id) {
        newFilter = {...newFilter, clientsIDsMongo: [client._id]}
    }
    return async (dispatch: Dispatch) => {
        return axios({method: 'post', responseType: 'blob', url: 'api/cargaCompleta/downloadPDF/', data: newFilter})
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
            })
            .catch((error) => {
            })
    }
}

export function downloadAttached(cargaCompletaId: string) {
    return async (dispatch: Dispatch) => {
        return axios({method: 'get', responseType: 'blob', url: 'api/cargaCompleta/downloadPDF/' + cargaCompletaId})
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
            })
            .catch((error) => {
            })
    }
}

export function downloadCMR(infoToCMR: infoToCreateCMR) {
    return async (dispatch: Dispatch) => {
        return axios({method: 'post', responseType: 'blob', url: 'api/createCMR', data: infoToCMR})
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
            })
            .catch((error) => {
            })
    }
}

export function exportToExcel(searchCargaFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({
            method: 'post',
            responseType: 'blob',
            url: 'api/cargaCompleta/createExcel/getList/',
            data: searchCargaFilter
        })
            .then((data) => {
                const blob = new Blob([data.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_albaranes_Cargas.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

export function addFilesToCarga(datosEntrega: datosEntregaRequest) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.put('api/cargaCompleta/deliver/', datosEntrega)
            .then((data) => {
                dispatch(setFormsCarga(data.data))
                dispatch(setOpenCloseModalUploadFileCarga(false))
            })
            .catch((error) => {
            })
    }
}

export function markCargaAsDelivered(datosEntrega: datosEntregaRequest) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/cargaCompleta/deliver/', datosEntrega)
            .then((data) => {
                requestOneCarga(datosEntrega.qrIdReaded[0])
                    .then((carga) => {
                        if (carga.data.cargasResults.length === 1) {
                            dispatch(setFormsCarga(carga.data.cargasResults[0]))
                        }
                    })
                    .catch((error) => {
                    })
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function CargaCompletaReducer(state: cargaCompletaState = initialState, incomingAction: Action): cargaCompletaState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_CARGA': {
            return {
                ...state,
                cargaCompletaForm: action.cargaCompleta,
                cargaCompletaEditForm: action.cargaCompleta,
                validateFieldsForm: inicialiceCargaCompletaValidateFieldsResult
            }
        }
        case 'SAVE_FORM_CARGA': {
            return {
                ...state,
                cargaCompletaEditForm: action.cargaCompleta,
            };
        }
        case 'SET_HISTORY_ADD_CARGA': {
            return {
                ...state,
                historyAddList: action.historyAddList
            };
        }
        case 'CHANGE_ADD_EDIT_CARGA': {
            return {
                ...state,
                addOrEditCarga: action.addOrEdit
            }
        }
        case 'ADD_RECEIVED_CARGA': {
            let newCarga: cargaCompleta = {
                ...action.cargaCompleta,
                datosGenerales: {
                    ...action.cargaCompleta.datosGenerales,
                    client: state.cargaCompletaEditForm.datosGenerales.client,
                }
            }
            return {
                ...state,
                cargaCompletaForm: {
                    ...inicialiceCargaCompleta,
                    datosGenerales: {
                        ...inicialiceCargaCompleta.datosGenerales,
                        client: state.cargaCompletaEditForm.datosGenerales.client,
                        recogida: state.cargaCompletaEditForm.datosGenerales.recogida,
                        entrega: state.cargaCompletaEditForm.datosGenerales.entrega,
                    },
                },
                cargaCompletaEditForm: {
                    ...inicialiceCargaCompleta,
                    datosGenerales: {
                        ...inicialiceCargaCompleta.datosGenerales,
                        client: state.cargaCompletaEditForm.datosGenerales.client,
                        recogida: state.cargaCompletaEditForm.datosGenerales.recogida,
                        entrega: state.cargaCompletaEditForm.datosGenerales.entrega,
                    },
                },
                historyAddList: [...state.historyAddList, newCarga].sort(OrderArraybyPropertiesOf<cargaCompleta>(['-id'])),
                addOrEditCarga: addingOrEditing.adding,
            };
        }
        case 'SAVE_EDIT_RECEIVED_CARGA': {
            return {
                ...state,
                cargaCompletaForm: {
                    ...action.cargaCompleta,
                    datosGenerales: {
                        ...action.cargaCompleta.datosGenerales,
                        client: state.cargaCompletaEditForm.datosGenerales.client
                    },
                },
                cargaCompletaEditForm: {
                    ...action.cargaCompleta,
                    datosGenerales: {
                        ...action.cargaCompleta.datosGenerales,
                        client: state.cargaCompletaEditForm.datosGenerales.client
                    },
                },
            };
        }
        case 'REQUEST_ALL_CARGA': {
            return {
                ...state,
                cargaGetLoading: loading.pending
            };
        }
        case 'RECEIVED_ALL_CARGA': {
            return {
                ...state,
                cargaGetLoading: loading.done,
                allCargasCompletas: action.cargas.cargasResults,
                totalCargasCompletas: action.cargas.totalResults,
                actualPage: action.cargas.numPage,
                totalPages: action.cargas.totalPages
            };
        }

        case 'SAVE_SEARCH_FILTER_CARGA': {
            return {
                ...state,
                searchFilterCarga: action.searchFilterCarga
            };
        }
        case 'SET_VALIDATE_FORM_CARGA': {
            return {
                ...state,
                validateFieldsForm: action.form
            };
        }
        case 'SET_OPEN_CLOSE_MODAL_CARGA': {
            return {
                ...state,
                openCloseUploadModal: action.openClose
            };
        }
        default:
            return state;
    }
};

export default CargaCompletaReducer;