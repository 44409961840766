import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import {
    requestAllPaqueterias, exportToExcel, setOpenCloseModalUploadFile, 
    setHistoryAddListPaq, downloadPaqueteriaPDF
} from './PaqueteriaStore';
import PaqueteriaRutasList from "./PaqueteriaRutasList";
import PaqueteriaListFilter from "./PaqueteriaListFilter";
import { filterPaqueteriaTableTH } from "../../common/commonInterfaces/thFilters";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import NumberFormat from "react-number-format";
import PaqueteriaAgrupacionModal from "./PaqueteriaAgrupaciónModal";
import {
    getFormattedDateShort, loginUsers, usersTypes, paqueteria, estadoTrazaEnum,
    showListAgrupa, searchFilterList, inicialiceClient
} from "aseguisShared";
import ModalPrintAlbaranes from "../../common/ModalPrintAlbaranes/modalPrintAlbaranes";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToPaqueteriaList {
    onClick: (paqueteria: paqueteria) => void
    addPaqueteria?: () => void
    searchPaqueteriaFilter: searchFilterList
    saveSearchPaqueteriaFilter: (filter: searchFilterList) => void
}
function PaqueteriaList(props: infoToPaqueteriaList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const [whatShow, setWhatShow] = useState<number>(showListAgrupa.list)

    const countAllPaqueterias: number = useSelector((state: ApplicationState) => state.paqueteria.totalPaqueteriaList);
    const allPaqueterias: paqueteria[] = useSelector((state: ApplicationState) => state.paqueteria.allPaqueterias) || [];
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.paqueteria.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.paqueteria.totalPages);
    const paqueteriaEditForm: paqueteria = useSelector((state: ApplicationState) => state.paqueteria.paqueteriaEditForm);
    const modalFilesState: boolean = useSelector((state: ApplicationState) => state.paqueteria.openCloseUploadModal);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const [modalAgruparAlbaranesOpened, setModalAgruparAlbaranesOpened] = useState(false)

    useEffect(() => {
        dispatch(setHistoryAddListPaq([]))
    }, []);

    useEffect(() => {
        if (props.searchPaqueteriaFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllPaqueterias(props.searchPaqueteriaFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllPaqueterias(props.searchPaqueteriaFilter));
        }
    }, [props.searchPaqueteriaFilter, paqueteriaEditForm]);

    const getRowColor = (paqueteria: paqueteria) => {
        if (userLogged.userType === usersTypes.employee) {
            if (paqueteria.datosTransporte.estadoTraza === estadoTrazaEnum.enPause) {
                return ("#CAE5FC")
            }
            if (paqueteria.idBill > 0 && paqueteria.datosEntrega && paqueteria.datosEntrega.qrIdReaded.length > 0) { //está entregado y facturado
                return ("#D8D8D8")
            }
            else if (paqueteria.idBill > 0 && (paqueteria.datosEntrega == null || paqueteria.datosEntrega.qrIdReaded.length === 0)) { //no está entregado y si está facturado
                return ("#FFE3B0")
            }
            else if (paqueteria.idBill === 0 && paqueteria.datosEntrega && paqueteria.datosEntrega.qrIdReaded.length > 0) { //está entregado y no facturado
                return ("#CCFFCE")
            }
            else if (new Date() > new Date(paqueteria.datosGenerales.entrega)) { //está fuera de plazo
                return ("#FFD9D9")
            }
        }
        return ("#FFFFFF")
    }

    const paqueteriaList: JSX.Element[] = allPaqueterias.map((paqueteria, index) => {
        return (
            <Fragment key={index}>
                <tr className={"table-row"}
                    style={{
                        backgroundColor: getRowColor(paqueteria)
                    }}
                    onClick={() => {
                        props.onClick(paqueteria)
                    }}
                    key={index}
                >
                    <td scope="row"><b>{paqueteria.id}</b></td>
                    <td>{paqueteria.datosGenerales.entradaSalida}</td>
                    {(userLogged.userType === usersTypes.employee) &&
                        <td>{paqueteria.datosGenerales.client.nombre}</td>
                    }
                    <td>{paqueteria.datosTransporte.destName + " " + paqueteria.datosTransporte.address.codigoPostal + " " + paqueteria.datosTransporte.address.localidad}</td>
                    <td>{paqueteria.datosTransporte.destNameDos + " " + paqueteria.datosTransporte.addressDos.codigoPostal + " " + paqueteria.datosTransporte.addressDos.localidad}</td>

                    <td style={{ whiteSpace: "nowrap" }}> {getFormattedDateShort(paqueteria.datosGenerales.recogida)}</td>
                    <td style={{ whiteSpace: "nowrap" }}> {getFormattedDateShort(paqueteria.datosGenerales.entrega)}</td>

                    <td>{paqueteria.datosTransporte.referenciaString.length > 0 ? paqueteria.datosTransporte.referenciaString : 0}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={0}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={paqueteria.datosTransporte.bultos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " bultos"}</div>}
                        />
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={paqueteria.datosTransporte.kilos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " kg"}</div>}
                        />
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={paqueteria.datosEconomicos.subTotal}
                            className="form-control undefined"
                            renderText={value => <div>{value}</div>}
                            suffix={" \u20AC"}
                        />
                    </td>
                    <td>{paqueteria.datosTransporte.cartaPorte.length > 0 ? paqueteria.datosTransporte.cartaPorte : "-"}</td>
                    <td>{paqueteria.datosEconomicos.agrupacionesCalculo && paqueteria.datosEconomicos.agrupacionesCalculo.length > 0 ? "Si" : "-"}</td>
                    <td>{paqueteria.datosEntrega.qrIdReaded.length > 0 ? "Si" : "-"}</td>
                    <td>{paqueteria.idBill > 0 ? paqueteria.idBill : "-"}</td>
                </tr>
            </Fragment>
        )
    });

    return (

        <Fragment>

            {whatShow === showListAgrupa.list &&
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">


                            {(userLogged.userType === usersTypes.employee) &&
                                <div className="header-buttons">

                                    <button
                                        className="btn btn-info btn-sm left-margin"
                                        onClick={() => {
                                            dispatch(setOpenCloseModalUploadFile(true))
                                        }}>
                                        Imprimir albaranes
                                    </button>

                                    {(userLogged.userType === usersTypes.employee) &&
                                        <button
                                            className="btn btn-info btn-sm left-margin"
                                            onClick={() => {
                                                setModalAgruparAlbaranesOpened(true)
                                            }}>
                                            Agrupar por destino
                                        </button>
                                    }

                                    <button
                                        className="btn btn-info btn-sm left-margin"
                                        onClick={() => {
                                            if (allPaqueterias.length > 0) {
                                                dispatch(exportToExcel({
                                                    ...props.searchPaqueteriaFilter,
                                                    getAll: true
                                                }))
                                            }
                                        }}>
                                        Exportar a Excel
                                    </button>

                                    <AddButtonList
                                        addCallback={props.addPaqueteria}
                                    />

                                </div>
                            }
                            
                            <div className="card-header">
                                <div className="form-inline">
                                    <h6 className="m-0 font-weight-bold text-green">
                                        Albaranes paquetería {"(" + countAllPaqueterias.toLocaleString() + ")"}
                                    </h6>
                                    {(isAppLoading === true) &&
                                        <span className="spinner-border spinner-border-sm ml-4"></span>
                                    }
                                </div>

                                <br></br>

                                <PaqueteriaListFilter
                                    searchFilterPaqueteria={props.searchPaqueteriaFilter}
                                    saveSearchFilterPaqueteria={props.saveSearchPaqueteriaFilter}
                                />
                            </div>


                            <div className="card-body">
                                <div className="table-responsive table-hover">

                                    <table className="table table-sm">
                                        <thead className="thead-light">
                                            <tr>
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "#")}
                                                <th>E/S</th>
                                                {(userLogged.userType === usersTypes.employee) &&
                                                    filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Cliente")
                                                }
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Origen")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Destino")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Fecha Albarán")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Entr prevista")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Referencia/s")}
                                                <th>Bultos</th>
                                                <th>Kilos</th>
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "SubTotal")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Carta Porte")}
                                                <th>Agrupada</th>
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "Entregado")}
                                                {filterPaqueteriaTableTH(props.saveSearchPaqueteriaFilter, props.searchPaqueteriaFilter, "ID Factura")}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paqueteriaList}
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                            
                            {/* paginacion */}
                            {Pagination(numPage, totalPages, props.searchPaqueteriaFilter, props.saveSearchPaqueteriaFilter)}

                        </div>
                    </div>
                </div>
            }

            {whatShow === showListAgrupa.agrupa &&
                <PaqueteriaRutasList
                    allPaqueterias={allPaqueterias}
                    showPaqueteriaList={setWhatShow}
                />
            }

            {/* MODAL PARA IMPRIMIR ALBARANES*/}
            <ModalPrintAlbaranes
                modalOpened={modalFilesState}
                setModalOpened={(opened: boolean) => {
                    dispatch(setOpenCloseModalUploadFile(opened))
                }}
                printAlbaranesById={(fromID, untilID, client) => {
                    const paqueterias: number[] = [];
                    for (let i = fromID; i <= untilID; i++) {
                        paqueterias.push(i)
                    }
                    dispatch(downloadPaqueteriaPDF(paqueterias, client, ""))
                }}
                printAlbaranesByCartaPorte={(cartaPorte) => {
                    dispatch(downloadPaqueteriaPDF([], inicialiceClient, cartaPorte))
                }}
            />
            
            {/* MODAL PARA AGRUPAR ALBARANES POR DESTINO */}
            <PaqueteriaAgrupacionModal
                modalOpened={modalAgruparAlbaranesOpened}
                setModalOpened={setModalAgruparAlbaranesOpened}
                callbackOnAgrupar={() => {
                    dispatch(requestAllPaqueterias(props.searchPaqueteriaFilter));
                }}
            />

        </Fragment >

    );
};

export default PaqueteriaList;
