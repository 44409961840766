"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicializePostalCodeList = exports.inicializePoblation = exports.inicializePostalCode = void 0;
exports.getValueLoc = getValueLoc;
const commonEnums_1 = require("../enums/commonEnums");
exports.inicializePostalCode = {
    cp: "",
    poblacion: "",
    provincia: "",
    pais: "",
    idCp: 0,
    idPais: "",
    idPoblacion: 0,
    idProvincia: 0,
};
exports.inicializePoblation = {
    namePobl: "",
    typeLoc: commonEnums_1.locationType.none,
    cps: []
};
exports.inicializePostalCodeList = {
    cpList: [],
    poblacionList: [],
    provinciaList: []
};
function getValueLoc(location, postalCode /*[]*/) {
    var value = "";
    if (postalCode !== undefined && postalCode !== null) {
        if (location === commonEnums_1.locationType.cp) {
            //value = "Cp: " + postalCodes[postalCodes.length-1].cp
            value = "Cp: " + postalCode.cp + " (" + postalCode.poblacion + ")";
        }
        if (location === commonEnums_1.locationType.localidad) {
            //value = "Localidad: " + postalCodes[postalCodes.length - 1].poblacion
            value = "Localidad: " + postalCode.poblacion;
        }
        if (location === commonEnums_1.locationType.provincia) {
            //value = "Provincia: " + postalCodes[postalCodes.length - 1].provincia
            value = "Provincia: " + postalCode.provincia;
        }
    }
    return value;
}
