"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAlbaranesAgrupados = exports.inicializeCalculatedPaqueteriaTariff = void 0;
exports.calculateAllTariffsPaqueteria = calculateAllTariffsPaqueteria;
exports.calculateOneTariffPaqueteria = calculateOneTariffPaqueteria;
exports.calculate = calculate;
exports.calculateIva = calculateIva;
exports.getTariffPriority = getTariffPriority;
const commonEnums_1 = require("../enums/commonEnums");
const commonFunctions_1 = require("./commonFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
exports.inicializeCalculatedPaqueteriaTariff = {
    text: '',
    descuento: 0,
    subTotal: 0,
    total: 0,
    iva: 0,
    porIva: 0,
    tariffType: commonEnums_1.tariffsTypes.none,
    whatTariffUse: commonEnums_1.whatTariffUseTypes.none,
    tariff: tariffFunctions_1.inicializePaqueteriaTariff,
    reexpTariff: 0,
    subTotalProveedor: 0,
    tariffProveedor: tariffFunctions_1.inicializePaqueteriaTariff,
    reexpTariffProveedor: 0,
};
/***************************************** FUNCIONES PARA CALCULAR TARIFA PAQUETERIA ***************************************/
/***************************************** FUNCIONES PARA CALCULAR TARIFA PAQUETERIA ***************************************/
/***************************************** FUNCIONES PARA CALCULAR TARIFA PAQUETERIA ***************************************/
function calculateAllTariffsPaqueteria(paqueteria, company, reexpFromTariffOrForm, calculateSupplier) {
    const clientTariffs = Object.assign({}, paqueteria.datosGenerales.client.tariff);
    const companyTariffs = Object.assign({}, company.tariff);
    const porIva = paqueteria.datosGenerales.client.porIva > 0 ? paqueteria.datosGenerales.client.porIva : company.porIva;
    var finalTariff = Object.assign(Object.assign({}, exports.inicializeCalculatedPaqueteriaTariff), { porIva: porIva });
    var addressToCalculate = Object.assign({}, commonFunctions_1.inicialiceAddress);
    if (paqueteria.datosGenerales.entradaSalida === "Entrada") {
        addressToCalculate = Object.assign({}, paqueteria.datosTransporte.address);
    }
    if (paqueteria.datosGenerales.entradaSalida === "Salida") {
        addressToCalculate = Object.assign({}, paqueteria.datosTransporte.addressDos);
    }
    const reexpValue = paqueteria.datosEconomicos.reexpedicion ? paqueteria.datosEconomicos.reexpedicion : 0;
    const tariffBultosCliente = calculateOneTariffPaqueteria(clientTariffs.porBultos, paqueteria.datosTransporte.bultos, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
    const tariffPesoCliente = calculateOneTariffPaqueteria(clientTariffs.porPeso, paqueteria.datosTransporte.kilos, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
    let tariffVolumenCliente = exports.inicializeCalculatedPaqueteriaTariff;
    if (paqueteria.datosGenerales.client.m3ToKg === 0) { //Si se informa valor de conversión, no se aplican tarifas por m3
        tariffVolumenCliente = calculateOneTariffPaqueteria(clientTariffs.porVolumen, paqueteria.datosTransporte.m3, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
    }
    const maxTariffClient = Math.max(tariffBultosCliente.subTotal, tariffPesoCliente.subTotal, tariffVolumenCliente.subTotal);
    if (maxTariffClient > 0) {
        if (tariffBultosCliente.subTotal === maxTariffClient) {
            finalTariff = Object.assign(Object.assign({}, tariffBultosCliente), { text: tariffBultosCliente.text ? "CLIENTE - BULTOS - " + tariffBultosCliente.text : tariffBultosCliente.text, tariffType: commonEnums_1.tariffsTypes.bultos, whatTariffUse: commonEnums_1.whatTariffUseTypes.cliente });
        }
        if (tariffPesoCliente.subTotal === maxTariffClient) {
            finalTariff = Object.assign(Object.assign({}, tariffPesoCliente), { text: tariffPesoCliente.text ? "CLIENTE - PESO - " + tariffPesoCliente.text : tariffPesoCliente.text, tariffType: commonEnums_1.tariffsTypes.peso, whatTariffUse: commonEnums_1.whatTariffUseTypes.cliente });
        }
        if (tariffVolumenCliente.subTotal === maxTariffClient) {
            finalTariff = Object.assign(Object.assign({}, tariffVolumenCliente), { text: tariffVolumenCliente.text ? "CLIENTE - VOLUMEN - " + tariffVolumenCliente.text : tariffVolumenCliente.text, tariffType: commonEnums_1.tariffsTypes.volumen, whatTariffUse: commonEnums_1.whatTariffUseTypes.cliente });
        }
    }
    if (maxTariffClient === 0) {
        const tariffBultosCompany = calculateOneTariffPaqueteria(companyTariffs.porBultos, paqueteria.datosTransporte.bultos, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
        const tariffPesoCompany = calculateOneTariffPaqueteria(companyTariffs.porPeso, paqueteria.datosTransporte.kilos, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
        let tariffVolumenCompany = exports.inicializeCalculatedPaqueteriaTariff;
        if (paqueteria.datosGenerales.client.m3ToKg === 0) { //Si se informa valor de conversión, no se aplican tarifas por m3
            tariffVolumenCompany = calculateOneTariffPaqueteria(companyTariffs.porVolumen, paqueteria.datosTransporte.m3, addressToCalculate, porIva, reexpValue, paqueteria.incidenciasEntregas, reexpFromTariffOrForm);
        }
        const maxTariffCompany = Math.max(tariffBultosCompany.subTotal, tariffPesoCompany.subTotal, tariffVolumenCompany.subTotal);
        if (maxTariffCompany > 0) {
            if (tariffBultosCompany.subTotal === maxTariffCompany) {
                finalTariff = Object.assign(Object.assign({}, tariffBultosCompany), { text: tariffBultosCompany.text ? "COMPANIA - BULTOS - " + tariffBultosCompany.text : tariffBultosCompany.text, tariffType: commonEnums_1.tariffsTypes.bultos, whatTariffUse: commonEnums_1.whatTariffUseTypes.compania });
            }
            if (tariffPesoCompany.subTotal === maxTariffCompany) {
                finalTariff = Object.assign(Object.assign({}, tariffPesoCompany), { text: tariffPesoCompany.text ? "COMPANIA - PESO - " + tariffPesoCompany.text : tariffPesoCompany.text, tariffType: commonEnums_1.tariffsTypes.peso, whatTariffUse: commonEnums_1.whatTariffUseTypes.compania });
            }
            if (tariffVolumenCompany.subTotal === maxTariffCompany) {
                finalTariff = Object.assign(Object.assign({}, tariffVolumenCompany), { text: tariffVolumenCompany.text ? "COMPANIA - VOLUMEN - " + tariffVolumenCompany.text : tariffVolumenCompany.text, tariffType: commonEnums_1.tariffsTypes.volumen, whatTariffUse: commonEnums_1.whatTariffUseTypes.compania });
            }
        }
        else {
            finalTariff = Object.assign(Object.assign({}, exports.inicializeCalculatedPaqueteriaTariff), { porIva: porIva, text: "Sin tarifa encontrada" });
        }
    }
    // CALCULAMOS TARIFAS PROVEEDOR
    if (calculateSupplier != "no") {
        if (paqueteria.datosGenerales.supplier._id && paqueteria.datosGenerales.supplier._id.length > 0) {
            const supplierTariffs = Object.assign({}, paqueteria.datosGenerales.supplier.tariff);
            let tariffBultosProveedor = exports.inicializeCalculatedPaqueteriaTariff;
            let tariffPesoProveedor = exports.inicializeCalculatedPaqueteriaTariff;
            let tariffVolumenProveedor = exports.inicializeCalculatedPaqueteriaTariff;
            if (supplierTariffs) {
                if (supplierTariffs.porBultos) {
                    tariffBultosProveedor = calculateOneTariffPaqueteria(supplierTariffs.porBultos, paqueteria.datosTransporte.bultos, addressToCalculate, porIva, 0, [], "tariff");
                }
                if (supplierTariffs.porPeso) {
                    tariffPesoProveedor = calculateOneTariffPaqueteria(supplierTariffs.porPeso, paqueteria.datosTransporte.kilos, addressToCalculate, porIva, 0, [], "tariff");
                }
                if (paqueteria.datosGenerales.client.m3ToKg === 0 && supplierTariffs.porVolumen) { //Si se informa valor de conversión, no se aplican tarifas por m3
                    tariffVolumenProveedor = calculateOneTariffPaqueteria(supplierTariffs.porVolumen, paqueteria.datosTransporte.m3, addressToCalculate, porIva, 0, [], "tariff");
                }
                const maxTariffProveedor = Math.max(tariffBultosProveedor.subTotal, tariffPesoProveedor.subTotal, tariffVolumenProveedor.subTotal);
                if (maxTariffProveedor > 0) {
                    if (tariffBultosProveedor.subTotal === maxTariffProveedor) {
                        finalTariff = Object.assign(Object.assign({}, finalTariff), { tariffProveedor: tariffBultosProveedor.tariff, subTotalProveedor: tariffBultosProveedor.subTotal, reexpTariffProveedor: tariffBultosProveedor.reexpTariff });
                    }
                    if (tariffPesoProveedor.subTotal === maxTariffProveedor) {
                        finalTariff = Object.assign(Object.assign({}, finalTariff), { tariffProveedor: tariffPesoProveedor.tariff, subTotalProveedor: tariffPesoProveedor.subTotal, reexpTariffProveedor: tariffPesoProveedor.reexpTariff });
                    }
                    if (tariffVolumenProveedor.subTotal === maxTariffProveedor) {
                        finalTariff = Object.assign(Object.assign({}, finalTariff), { tariffProveedor: tariffVolumenProveedor.tariff, subTotalProveedor: tariffVolumenProveedor.subTotal, reexpTariffProveedor: tariffVolumenProveedor.reexpTariff });
                    }
                }
                else {
                    finalTariff = Object.assign(Object.assign({}, finalTariff), { tariffProveedor: exports.inicializeCalculatedPaqueteriaTariff.tariffProveedor, subTotalProveedor: exports.inicializeCalculatedPaqueteriaTariff.subTotalProveedor, reexpTariffProveedor: exports.inicializeCalculatedPaqueteriaTariff.reexpTariffProveedor });
                }
            }
        }
    }
    else {
        finalTariff = Object.assign(Object.assign({}, finalTariff), { tariffProveedor: paqueteria.datosEconomicos.tariffProveedor || exports.inicializeCalculatedPaqueteriaTariff.tariffProveedor, subTotalProveedor: paqueteria.datosEconomicos.subTotalProveedor || exports.inicializeCalculatedPaqueteriaTariff.subTotalProveedor, reexpTariffProveedor: exports.inicializeCalculatedPaqueteriaTariff.reexpTariffProveedor });
    }
    //si hay descuento, lo aplicamos
    if (paqueteria.datosEconomicos.descuento > 0) {
        const importToDiscount = (finalTariff.subTotal * paqueteria.datosEconomicos.descuento) / 100;
        const newSubtotal = finalTariff.subTotal - importToDiscount;
        const newIVA = calculateIva(newSubtotal, porIva);
        const newTotal = newSubtotal + newIVA;
        finalTariff = Object.assign(Object.assign({}, finalTariff), { subTotal: newSubtotal, porIva: porIva, iva: newIVA, descuento: paqueteria.datosEconomicos.descuento, total: newTotal });
    }
    return finalTariff;
}
/** CALCULAMOS UNA TARIFA **/
/** CALCULAMOS UNA TARIFA **/
/** CALCULAMOS UNA TARIFA **/
function calculateOneTariffPaqueteria(tariff, newValue, address, porIva, reexpValue, incidencias, reexpFromTariffOrForm) {
    var calculatedTariff = Object.assign(Object.assign({}, exports.inicializeCalculatedPaqueteriaTariff), { porIva: porIva });
    var calculatedTariffMinorPriority = Object.assign(Object.assign({}, exports.inicializeCalculatedPaqueteriaTariff), { porIva: porIva });
    var maxPriorityTariff = 0;
    if (newValue > 0 && address.codigoPostal !== '' && address.cpMongo) {
        tariff.map(tariffs => {
            tariffs.tariffLocations.map(locTariffs => {
                var cpUsed = postalCodeFunctions_1.inicializePostalCode;
                var tariffFound = false;
                var tariffMinorPriority = false;
                if ((locTariffs.locationType === commonEnums_1.locationType.cp && locTariffs.postalCode.idCp === address.cpMongo.idCp) ||
                    (locTariffs.locationType === commonEnums_1.locationType.localidad && locTariffs.postalCode.idPoblacion === address.cpMongo.idPoblacion) ||
                    (locTariffs.locationType === commonEnums_1.locationType.provincia && locTariffs.postalCode.idProvincia === address.cpMongo.idProvincia)) {
                    cpUsed = address.cpMongo;
                    if (getTariffPriority(locTariffs.locationType) >= maxPriorityTariff) {
                        maxPriorityTariff = getTariffPriority(locTariffs.locationType);
                        tariffFound = true;
                    }
                    else {
                        tariffMinorPriority = true;
                    }
                }
                if (tariffFound) {
                    const calc = calculate(tariffs, newValue, cpUsed, porIva, locTariffs, reexpValue, incidencias, reexpFromTariffOrForm);
                    if (calc.subTotal > calculatedTariff.subTotal) {
                        calculatedTariff = (Object.assign({}, calc));
                    }
                }
                if (tariffMinorPriority) {
                    const calc = calculate(tariffs, newValue, cpUsed, porIva, locTariffs, reexpValue, incidencias, reexpFromTariffOrForm);
                    if (calc.subTotal > calculatedTariffMinorPriority.subTotal) {
                        calculatedTariffMinorPriority = (Object.assign({}, calc));
                    }
                }
            });
        });
        if (calculatedTariff.subTotal === 0 && calculatedTariffMinorPriority.subTotal > 0) {
            calculatedTariff = (Object.assign({}, calculatedTariffMinorPriority));
        }
    }
    return calculatedTariff;
}
/** CALCULAMOS **/
/** CALCULAMOS **/
/** CALCULAMOS **/
function calculate(tariffs, newValue, cpUsed, porIva, locTariffs, reExpValue, incidencias, reexpFromTariffOrForm) {
    var found = false;
    var tariffMaxValue = 0;
    var tariffHasta = 0;
    var tariffValue = 0;
    var calculatedTariff = Object.assign(Object.assign({}, exports.inicializeCalculatedPaqueteriaTariff), { porIva: porIva });
    var text = "Sin tarifa encontrada";
    tariffs.tariffs.map((tariff) => {
        var calculatedValue = 0;
        var importe = 0;
        var reExpedicionTariff = 0;
        if ((newValue <= tariff.hasta) && (found === false)) {
            if (tariffs.multiplicateValue === true) {
                calculatedValue = tariff.value * newValue;
            }
            else {
                calculatedValue = tariff.value;
            }
            if (calculatedValue < tariffs.minImport && newValue > 0) {
                calculatedValue = tariffs.minImport;
            }
            if (tariffMaxValue > calculatedValue) {
                calculatedValue = tariffMaxValue;
            }
            else {
                tariffHasta = tariff.hasta;
                tariffValue = tariff.value;
            }
            if (calculatedValue > importe) {
                if (locTariffs.locationType === commonEnums_1.locationType.cp) {
                    text = "CP: " + cpUsed.cp + ", Hasta: " + tariffHasta + ", Valor:  " + tariffValue + /*(Math.round(tariffValue * 100) / 100).toFixed(6) +*/ "€";
                }
                if (locTariffs.locationType === commonEnums_1.locationType.localidad) {
                    text = "Localidad: " + cpUsed.poblacion + ", CP: " + cpUsed.cp + ", Hasta: " + tariffHasta + ", Valor:  " + tariffValue + /*(Math.round(tariffValue * 100) / 100).toFixed(6) +*/ "€";
                }
                if (locTariffs.locationType === commonEnums_1.locationType.provincia) {
                    text = "Provincia: " + cpUsed.provincia + ", CP: " + cpUsed.cp + ", Hasta: " + tariffHasta + ", Valor:  " + tariffValue + /*(Math.round(tariffValue * 100) / 100).toFixed(6) +*/ "€";
                }
                importe = calculatedValue;
                if (reexpFromTariffOrForm === "tariff" && locTariffs.reissues && locTariffs.reissues > 0) {
                    const porcentajeDecimal = locTariffs.reissues / 100;
                    const valorAdicional = importe * porcentajeDecimal;
                    reExpedicionTariff = valorAdicional;
                    text = text + ". Reexpedición: " + reExpedicionTariff;
                    importe = importe + reExpedicionTariff;
                }
                if (reexpFromTariffOrForm === "form") {
                    reExpedicionTariff = reExpValue;
                    text = text + ". Reexpedición: " + reExpValue;
                    importe = importe + reExpValue;
                }
                //INCIDENCIAS
                if (incidencias && incidencias.length > 0) {
                    text = text + ". Incidencia/s: ";
                    for (var inc of incidencias) {
                        importe = importe + inc.value;
                        text = text + " " + inc.value + "€";
                    }
                }
                const importeIVA = calculateIva(importe, porIva);
                const total = importe + importeIVA;
                if (importe > calculatedTariff.subTotal) {
                    calculatedTariff = (Object.assign(Object.assign({}, calculatedTariff), { text: text, subTotal: importe, iva: importeIVA, porIva: porIva, total: total, tariff: Object.assign(Object.assign({}, tariffs), { tariffLocations: [locTariffs] }), reexpTariff: reExpedicionTariff }));
                }
            }
            found = true;
        }
        if (tariffs.multiplicateValue === true) {
            tariffMaxValue = tariff.value * tariff.hasta;
        }
        else {
            tariffMaxValue = tariff.value;
        }
        tariffHasta = tariff.hasta;
        tariffValue = tariff.value;
    });
    return calculatedTariff;
}
function calculateIva(value, porIva) {
    const iva = porIva > 0 ? (value * porIva) / 100 : 0;
    return iva;
}
function getTariffPriority(tariffType) {
    var priority = 0;
    if (tariffType === commonEnums_1.locationType.cp) {
        priority = 3;
    }
    if (tariffType === commonEnums_1.locationType.localidad) {
        priority = 2;
    }
    if (tariffType === commonEnums_1.locationType.provincia) {
        priority = 1;
    }
    return priority;
}
const getAlbaranesAgrupados = (albaranesParaAgrupar, company) => {
    const grupos = {};
    // Iteramos sobre todos los paqueterias
    for (const paqueteria of albaranesParaAgrupar) {
        let claveDestino = "";
        if (paqueteria.datosGenerales.entradaSalida = "Salida") {
            claveDestino = (0, commonFunctions_1.formatedDirection)(paqueteria.datosTransporte.addressDos);
            // Si el destino no existe en el objeto grupos, lo añadimos
            if (!grupos[claveDestino]) {
                grupos[claveDestino] = {
                    totalBultos: paqueteria.datosTransporte.bultos,
                    totalM3: paqueteria.datosTransporte.m3,
                    totalKilos: paqueteria.datosTransporte.kilos,
                    subTotalPrecio: paqueteria.datosEconomicos.subTotal,
                    mediaSubTotalPrecio: 0,
                    contador: 1,
                    paqueteReferencia: paqueteria,
                    idsAgrupadas: [paqueteria._id]
                };
            }
            else {
                // Si el destino ya existe en el objeto grupos, sumamos los kilos y el precio
                grupos[claveDestino].totalBultos += paqueteria.datosTransporte.bultos;
                grupos[claveDestino].totalM3 += paqueteria.datosTransporte.m3;
                grupos[claveDestino].totalKilos += paqueteria.datosTransporte.kilos;
                grupos[claveDestino].subTotalPrecio += paqueteria.datosEconomicos.subTotal;
                grupos[claveDestino].contador++;
                grupos[claveDestino].idsAgrupadas.push(paqueteria._id);
            }
        }
    }
    // Ahora, calculamos el precio total para cada grupo
    for (const claveDestino in grupos) {
        const grupo = grupos[claveDestino];
        const paqueteriaConKilosTotales = Object.assign(Object.assign({}, grupo.paqueteReferencia), { datosTransporte: Object.assign(Object.assign({}, grupo.paqueteReferencia.datosTransporte), { kilos: grupo.totalKilos, bultos: grupo.totalBultos, m3: grupo.totalM3 }), 
            /**** NUEVO ****/
            datosEconomicos: Object.assign(Object.assign({}, grupo.paqueteReferencia.datosEconomicos), { descuento: 0, reexpedicion: 0 }), incidenciasEntregas: [] });
        grupo.subTotalPrecio = calculateAllTariffsPaqueteria(paqueteriaConKilosTotales, company, "tariff").subTotal;
        grupo.paqueteReferencia = paqueteriaConKilosTotales;
    }
    // Ahora, podemos calcular la media de los precios para cada grupo
    for (const claveDestino in grupos) {
        const grupo = grupos[claveDestino];
        grupo.mediaSubTotalPrecio = grupo.subTotalPrecio / grupo.contador;
    }
    // Generamos un nuevo array de paqueterias con los precios modificados
    const paqueteriasModificados = albaranesParaAgrupar.map(albaran => {
        const claveDestino = (0, commonFunctions_1.formatedDirection)(albaran.datosTransporte.addressDos);
        const grupo = grupos[claveDestino];
        let newAlbaran = Object.assign({}, albaran);
        if (grupo && grupo.mediaSubTotalPrecio) {
            newAlbaran = Object.assign(Object.assign({}, newAlbaran), { datosEconomicos: Object.assign(Object.assign({}, newAlbaran.datosEconomicos), { 
                    /**** NUEVO ****/
                    subTotalOriginal: calculateAllTariffsPaqueteria(Object.assign(Object.assign({}, newAlbaran), { datosEconomicos: Object.assign(Object.assign({}, newAlbaran.datosEconomicos), { descuento: 0 }), incidenciasEntregas: [] }), company, "form").subTotal, descuento: 0, reexpedicion: 0, 
                    /**** NUEVO ****/
                    subTotal: grupo.mediaSubTotalPrecio, agrupacionesCalculo: grupo.idsAgrupadas }), incidenciasEntregas: newAlbaran.incidenciasEntregas.filter(incidencia => incidencia.value <= 0) });
        }
        return newAlbaran;
    });
    const paqueteriasSoloModificadas = paqueteriasModificados.filter((paqueteria, index) => {
        let newPaquete = Object.assign({}, paqueteria);
        let claveDestino = "";
        if (newPaquete.datosGenerales.entradaSalida === "Salida") {
            claveDestino = (0, commonFunctions_1.formatedDirection)(newPaquete.datosTransporte.addressDos);
            const grupo = grupos[claveDestino];
            // Solo mantenemos los paqueterias que tienen más de un paqueteria en su grupo
            // y cuyo precio se haya modificado
            const original = albaranesParaAgrupar[index];
            return (grupo &&
                grupo.contador > 1 &&
                (!original.datosEconomicos.agrupacionesCalculo || original.datosEconomicos.agrupacionesCalculo.length === 0));
        }
    });
    return paqueteriasSoloModificadas;
};
exports.getAlbaranesAgrupados = getAlbaranesAgrupados;
