import React, {Fragment, useEffect, useState} from "react";
import {Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import {Route} from 'react-router-dom';
import Admin from "./components/Admin/Admin";
import Login from "../src/components/Login/Login";
import PrivateRoute from "../src/common/PrivateRoute/PrivateRoute";
import {allAppRoutes} from "./common/commonInterfaces/allAppRoutes";
import CookiesAccept from "./common/Politicas/CookiesAccept";
import RegisterActivated from "./components/Register/RegisterActivated";
import {useDispatch, useSelector} from "react-redux";
import {logoutAndClearStorage, refreshLogin} from "./components/Login/LoginStore";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/Login/ForgotPassword";
import NewPassword from "./components/Login/NewPassword";
import TrackingShipment from "./components/Tracking/TrackingShipment";
import {ApplicationState} from "./ApplicationState";
import {requestParametrizacion} from "./components/Parametrizacion/ParametrizacionStore";
import {requestCompany} from "./components/Company/CompanyStore";

const App: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const location = useLocation();

    const primaryColor: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.primaryColor);
    const secondColor: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.secondColor);
    const companyLogo: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.companyLogo);

    const refreshLoaded = useSelector((state: ApplicationState) => state.login.refreshLoaded);
    const refreshToken = localStorage.getItem('refreshToken');

    useEffect(() => {
        if (refreshToken) {
            dispatch(requestParametrizacion());
            dispatch(requestCompany());
        }
    }, [refreshToken]);

    //*** LOGICA PARA QUE LA APP HAGA TIMEOUT Y CIERRE SESION ***
    const timeout = 1800000; // 30min
    useEffect(() => {
        let timer: NodeJS.Timeout;
        const updateActivity = () => {
            const currentTime = Date.now();
            localStorage.setItem('lastActivity', currentTime.toString());
            resetTimer();
        };
        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                dispatch(logoutAndClearStorage(history));
            }, timeout);
        };
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'lastActivity') {
                resetTimer();
            }
        };
        // Añadir eventos para detectar actividad
        window.addEventListener('mousemove', updateActivity);
        window.addEventListener('keydown', updateActivity);
        window.addEventListener('scroll', updateActivity);
        window.addEventListener('storage', handleStorageChange);
        // Iniciar el temporizador por primera vez
        resetTimer();
        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', updateActivity);
            window.removeEventListener('keydown', updateActivity);
            window.removeEventListener('scroll', updateActivity);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch, history, timeout]);
    // ****************************************************************/


    //*** LOGICA PARA QUE CUANDO CIERRE SESION UNA PESTAÑA, EL RESTO SE DE CUENTA Y MANDE TAMBIEN A LOGIN ***
    const normalizeRoute = (route: string) => {
        const hasParams = /:/.test(route);
        let cleanedRoute = route.split('/')
            .map(segment => segment.includes(':') ? '' : segment)
            .filter(segment => segment.length > 0)
            .join('/');
        if (hasParams) {
            cleanedRoute += '/';
        }
        return cleanedRoute;
    };
    const redirectRoutes = [
        allAppRoutes.register,
        allAppRoutes.forgotPassword,
        allAppRoutes.newPassword,
        allAppRoutes.enableLink,
        allAppRoutes.tracking
    ].map(route => normalizeRoute(route));
    const isPublicRoute = (pathname: string) => {
        return redirectRoutes.some(route => pathname.includes(route));
    };
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
    const handleStorageChange = (event: StorageEvent) => {
        if (event.key === 'tokenLost' && !isPublicRoute(location.pathname)) {
            setIsAuthenticated(false);
            dispatch(logoutAndClearStorage(history));
        }
        if (event.key === 'loggedIn') {
            setIsAuthenticated(true);
            history.push(allAppRoutes.leftMenuPanel);
        }
    };
    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [history, location]);
    useEffect(() => {
        if (!refreshToken || refreshToken.length === 0) {
            handleTokenLoss();
        } else if (refreshToken.length > 0 && !refreshLoaded) {
            dispatch(refreshLogin());
        }
    }, [refreshToken, dispatch]);
    const isAuthorized = (): boolean => {
        const token = localStorage.getItem('refreshToken');
        return !!token;
    };
    const handleTokenLoss = () => {
        localStorage.setItem('tokenLost', Date.now().toString());
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        setIsAuthenticated(false);
    };
    useEffect(() => {
        if (!isAuthorized()) {
            handleTokenLoss();
        }
    }, []);
    useEffect(() => {
        if (!isAuthenticated && !isPublicRoute(location.pathname)) {
            dispatch(logoutAndClearStorage(history));
        }
    }, [isAuthenticated, history]);
    // ****************************************************************/

    if ((!refreshLoaded || !primaryColor || !secondColor || !companyLogo) && refreshToken) {
        return (
            <div
                style={{
                    position: "fixed",
                    top: "0", left: "0",
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white"
                }}>
                <div className="spinner-border" style={{width: "3rem", height: "3rem"}}></div>
            </div>
        )
    }

    return (

        <Fragment>

            <Switch>

                {(refreshLoaded && refreshToken) &&
                    <PrivateRoute
                        path="/admin"
                        Component={Admin}
                    />
                }

                <Route path={allAppRoutes.login}> <Login/> </Route>
                <Route path={allAppRoutes.register}> <Register/> </Route>
                <Route path={allAppRoutes.forgotPassword}> <ForgotPassword/> </Route>
                <Route path={allAppRoutes.newPassword}> <NewPassword/> </Route>
                <Route path={allAppRoutes.enableLink}> <RegisterActivated/> </Route>
                <Route path={allAppRoutes.tracking}> <TrackingShipment/> </Route>

                <Redirect from="*" to={allAppRoutes.leftMenuPanel}/>

            </Switch>

            <CookiesAccept/>

        </Fragment>

    )
};

export default App;