import React, {Fragment, ReactElement, useEffect, ChangeEvent, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {ApplicationState} from '../../ApplicationState';
import {requestCompany} from "../Company/CompanyStore";
import {
    saveFormCarga, validateIfExistsFields, setValidateCargaForm, downloadCargaPDF, downloadAttached,
    setOpenCloseModalUploadFileCarga, addFilesToCarga, addOrEditCarga, setFormsCarga, markCargaAsDelivered,
    requestOneCarga
} from "./CargaCompletaStore";
import {
    loginUsers, initGeoPosition, datosEntregaRequest, estadoTrazaEnum, getEstadoString, cargaCompleta,
    getFormattedDate, lineaViajeCarga, company, inicializeCargaCompletaTariff, tariffsTypes, 
    getCoordinatesGoogleURL, getFormattedDateAndHourShort, nombreCompleto, splitTextBySpaces, urlParmsForm,
    initialVehicle, initialVehicleType, tiposArticuladosVehiculos, inicializeLineaViajeCarga, validateAlphaNumeric,
    initIncidenceType, addingOrEditing, usersTypes, cargaCompletaValidateFieldsResult,
    getInfoToCmrForCarga, suscriptionData, vehicleType, vehicle,
} from "aseguisShared";
import ClientsAutocomplete from "../Clients/ClientsAutocomplete";
import {calculateAllLineasCarga, calculateDistanciaCarga, calculateHorasCarga} from "./CargaCompletaCalculateTariff";
import VehicleAutocomplete from "../Vehicles/VehicleAutocomplete";
import SupplierAutocomplete from "../Suppliers/SuppliersAutocomplete";
import EmployeesAutocomplete from "../Employees/EmployeesAutocomplete";
import CargaCompletaViajesList from "./CargaCompletaViajesList";
import VehiclesTypesAutocomplete from "../Vehicles/VehiclesSettings/VehiclesTypesAutocomplete";
import FormHoras from "./CargaCompletaForms/FormHoras";
import FormPoblacion from "./CargaCompletaForms/FormPoblacion";
import FormTrayecto from "./CargaCompletaForms/FormTrayecto";
import FormDistancia from "./CargaCompletaForms/FormDistancia";
import InputDate from "../../common/Inputs/InputDate";
import InputNumberSum from "../../common/Inputs/InputNumberSum";
import FormOtros from "./CargaCompletaForms/FormOtros";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UploadFile from "../../common/Inputs/UploadFile";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {requestOneClient} from "../Clients/ClientsStore";
import PaqueteriaIncidencesList from "../Paqueteria/PaqueteriaIncidencesList";
import Swal from "sweetalert2";
import {GetSuscriptionDataFromLocalStorage} from "../../common/commonInterfaces/commonFunctions";
import CargaCompletaRepeatModal from "./CargaCompletaRepeatModal";
import {requestOneSupplier} from "../Suppliers/SuppliersStore";

interface infoToCargaForm {
    isEdditing: number;
    addOrEdit: number,
    restoreLines: boolean,
    setRestoreLines: (setRestore: boolean) => void
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

function CargaCompletaForm(props: infoToCargaForm): ReactElement {

    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch: React.Dispatch<any> = useDispatch();
    let history = useHistory()

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const cargaForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaForm);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const validateFieldsForm: cargaCompletaValidateFieldsResult = useSelector((state: ApplicationState) => state.cargaCompleta.validateFieldsForm);
    const modalFilesState: boolean = useSelector((state: ApplicationState) => state.cargaCompleta.openCloseUploadModal);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [modalRecurrenciaOpened, setModalRecurrenciaOpened] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [formType, setFormType] = useState<tariffsTypes>(tariffsTypes.horas);
    const [calculateTariff, setCalculateTariff] = useState(false);
    const [tariffHorasText, setTariffHorasText] = useState("");
    const [tariffDistanciaText, setTariffDistanciaText] = useState("");
    const [lineaHoras, setLineaHoras] = useState<lineaViajeCarga>(inicializeLineaViajeCarga);
    const [lineaDistancia, setlineaDistancia] = useState<lineaViajeCarga>(inicializeLineaViajeCarga);
    const [lineaPoblacion, setLineaPoblacion] = useState<lineaViajeCarga>(inicializeLineaViajeCarga);
    const [lineaTrayecto, setLineaTrayecto] = useState<lineaViajeCarga>(inicializeLineaViajeCarga);
    const [lineaOtros, setLineaOtros] = useState<lineaViajeCarga>(inicializeLineaViajeCarga);
    const [errorOnClients, setErrorOnClients] = useState(false);
    const [errorOnVehicleType, setErrorOnVehicleType] = useState(false);
    const [goToValidateFields, setGoToValidateFields] = useState(false);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    //MODAL upload ficheros
    const handleClose = () => dispatch(setOpenCloseModalUploadFileCarga(false))
    const handleShow = () => dispatch(setOpenCloseModalUploadFileCarga(true))
    const [markDelivered, setMarkDelivered] = useState(false)
    const [base64Files, setBase64Files] = useState<string[]>([])
    const onFileUpload = () => {
        if (base64Files.length > 0) {
            if (cargaEditForm._id) {
                dispatch(addFilesToCarga({
                    deliveredTime: new Date(),
                    geoPosition: initGeoPosition,
                    imagesBase64: base64Files,
                    qrIdReaded: [cargaEditForm._id],
                    whoDeliver: userLogged,
                    markDelivered: markDelivered,
                    tipoIncidencia: initIncidenceType
                }))
            }
        }
    }

    //para tener siempre la ultima versión...
    let {id} = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditCarga(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneCarga(id)
                .then((carga) => {
                    if (carga.data.cargasResults.length === 1) {
                        dispatch(addOrEditCarga(addingOrEditing.edditing));
                        dispatch(setFormsCarga(carga.data.cargasResults[0]))
                    } else {
                        history.push(allAppRoutes.cargaCompletaList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.cargaCompletaList)
                })
        }
    }, [id]);

    //para recuperar las tarifas ya que no se guardan en la carga
    useEffect(() => {
        if (cargaEditForm.datosGenerales.client._id) {
            if (cargaEditForm.datosGenerales.client._id.length > 0 && props.isEdditing === addingOrEditing.edditing) {
                requestOneClient(cargaEditForm.datosGenerales.client._id)
                    .then((clientsGetted) => {
                        if (clientsGetted.data.clientsResults.length === 1) {
                            if (cargaEditForm.datosGenerales.supplier._id && cargaEditForm.datosGenerales.supplier._id.length > 0) {
                                requestOneSupplier(cargaEditForm.datosGenerales.supplier._id)
                                    .then((supplier) => {
                                        if (supplier.data.suppliersResults.length === 1) {
                                            dispatch(saveFormCarga({
                                                ...cargaEditForm,
                                                datosGenerales: {
                                                    ...cargaEditForm.datosGenerales,
                                                    client: clientsGetted.data.clientsResults[0],
                                                    supplier: supplier.data.suppliersResults[0]
                                                }
                                            }));
                                        } else {
                                            history.push(allAppRoutes.cargaCompletaList)
                                        }
                                    })
                                    .catch((error) => {
                                        history.push(allAppRoutes.cargaCompletaList)
                                    })
                            } else {
                                dispatch(saveFormCarga({
                                    ...cargaEditForm,
                                    datosGenerales: {
                                        ...cargaEditForm.datosGenerales,
                                        client: clientsGetted.data.clientsResults[0]
                                    }
                                }));
                            }
                        } else {
                            history.push(allAppRoutes.cargaCompletaList)
                        }
                    })
                    .catch((error) => {
                        history.push(allAppRoutes.cargaCompletaList)
                    })
            }
        }
    }, [props.isEdditing]);

    useEffect(() => {
        //si hay error en algun sitio, grabamos error general
        if (
            errorOnClients === true ||
            errorOnVehicleType === true ||
            cargaEditForm.lineasViaje.length < 1 ||
            new Date(cargaEditForm.datosGenerales.entrega).getFullYear() === 0 ||
            new Date(cargaEditForm.datosGenerales.entrega).getFullYear() === 1 ||
            new Date(cargaEditForm.datosGenerales.recogida).getFullYear() === 0 ||
            new Date(cargaEditForm.datosGenerales.recogida).getFullYear() === 1
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [errorOnClients, errorOnVehicleType, cargaEditForm, validateFieldsForm.anyFieldExist]);

    useEffect(() => {
        if (calculateTariff) {
            const calculatedTariff = calculateAllLineasCarga(cargaEditForm.lineasViaje, cargaEditForm.datosGenerales.client.porIva, company.porIva)
            dispatch(saveFormCarga({
                ...cargaEditForm,
                datosEconomicos: {
                    ...cargaEditForm.datosEconomicos,
                    subTotal: calculatedTariff.subTotal,
                    descuento: 0,
                    subTotalProveedor: calculatedTariff.subTotalProveedor
                }
            }));
            setCalculateTariff(false)
        }
    }, [calculateTariff])

    useEffect(() => {
        if (goToValidateFields) {
            dispatch(validateIfExistsFields(cargaEditForm));
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    useEffect(() => {
        /* para coger tarifas generales*/
        dispatch(requestCompany());
    }, []);

    useEffect(() => {
        if (props.restoreLines) {
            setLineaHoras(inicializeLineaViajeCarga)
            setlineaDistancia(inicializeLineaViajeCarga)
            setLineaPoblacion(inicializeLineaViajeCarga)
            setLineaTrayecto(inicializeLineaViajeCarga)
            setLineaOtros(inicializeLineaViajeCarga)
            props.setRestoreLines(false)
        }
    }, [props.restoreLines]);

    function inicialiceTariffsCarga(lineaViaje: lineaViajeCarga,): lineaViajeCarga {
        var newLinea = lineaViaje
        newLinea = ({
            ...newLinea,
            tariffCarga: inicializeCargaCompletaTariff,
            lineType: ''
        });
        return newLinea
    }

    function saveInicialicedTariffs(): void {
        formType === tariffsTypes.horas && setLineaHoras(inicialiceTariffsCarga(lineaHoras))
        formType === tariffsTypes.poblacion && setLineaPoblacion(inicialiceTariffsCarga(lineaPoblacion))
        formType === tariffsTypes.distancia && setlineaDistancia(inicialiceTariffsCarga(lineaDistancia))
        formType === tariffsTypes.trayecto && setLineaTrayecto(inicialiceTariffsCarga(lineaTrayecto))
        formType === tariffsTypes.otros && setLineaOtros(inicialiceTariffsCarga(lineaOtros))
    }

    function lineaViajeToViajesList(): lineaViajeCarga {
        var lineaViaje = inicializeLineaViajeCarga
        if (formType === tariffsTypes.horas) {
            lineaViaje = lineaHoras
        }
        if (formType === tariffsTypes.distancia) {
            lineaViaje = lineaDistancia
        }
        if (formType === tariffsTypes.poblacion) {
            lineaViaje = lineaPoblacion
        }
        if (formType === tariffsTypes.trayecto) {
            lineaViaje = lineaTrayecto
        }
        if (formType === tariffsTypes.otros) {
            lineaViaje = lineaOtros
        }
        return lineaViaje
    }

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(props.addOrEdit !== addingOrEditing.adding) &&
                            <span> {"# " + cargaEditForm.id.toString()}</span>
                        }
                        {(props.addOrEdit === addingOrEditing.adding) &&
                            <span> {"Añadir Carga"}</span>
                        }
                    </h6>
                </div>

                {props.addOrEdit === addingOrEditing.edditing &&
                    <div className="header-buttons">

                        <button
                            className="btn btn-info left-margin btn-sm"
                            onClick={() => {
                                dispatch(downloadCargaPDF([cargaEditForm.id], cargaEditForm.datosGenerales.client))
                            }}>
                            Descargar Albarán
                        </button>

                        {cargaEditForm.datosEntrega.fileRelation.length > 0 &&
                            <button
                                className="btn btn-info left-margin btn-sm"
                                onClick={() => {
                                    if (cargaEditForm._id && cargaEditForm._id.length > 0) {
                                        dispatch(downloadAttached(cargaEditForm._id))
                                    }
                                }}>
                                Descargar Adjuntos
                            </button>
                        }

                        {userLogged.userType === usersTypes.employee &&
                            <div>

                                <button
                                    className="btn btn-info left-margin btn-sm"
                                    onClick={() => {
                                        handleShow()
                                    }}>
                                    Añadir ficheros
                                </button>

                                {(cargaEditForm.datosEntrega.qrIdReaded.length === 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            if (cargaEditForm._id) {
                                                Swal.fire({
                                                    title: "¿Estás seguro?",
                                                    text: "Una vez entregado, esta acción no puede deshacerse",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Confirmar",
                                                    confirmButtonColor: "#28a745",
                                                    cancelButtonColor: "#d33",
                                                    cancelButtonText: "Cancelar"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        let entregaRequest: datosEntregaRequest = {
                                                            deliveredTime: new Date(),
                                                            geoPosition: initGeoPosition,
                                                            qrIdReaded: [cargaEditForm._id!],
                                                            whoDeliver: userLogged,
                                                            imagesBase64: [],
                                                            markDelivered: true,
                                                            tipoIncidencia: initIncidenceType
                                                        }
                                                        dispatch(markCargaAsDelivered(entregaRequest))
                                                        Swal.fire({
                                                            title: "¡Listo!",
                                                            text: "Este albarán ha sido entregado",
                                                            icon: "success"
                                                        });
                                                    }
                                                });
                                            }
                                        }}>
                                        Marcar como entregado
                                    </button>
                                }

                                {(cargaEditForm.datosEntrega.geoPosition && cargaEditForm.datosEntrega.geoPosition.coords.latitude > 0 && cargaEditForm.datosEntrega.geoPosition.coords.longitude > 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            let url = getCoordinatesGoogleURL(
                                                cargaEditForm.datosEntrega.geoPosition.coords.latitude,
                                                cargaEditForm.datosEntrega.geoPosition.coords.longitude
                                            )
                                            window.open(url);
                                        }}>
                                        Ubicación de entrega
                                    </button>
                                }

                                {props.isEdditing !== addingOrEditing.edditing &&
                                    < button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            setModalRecurrenciaOpened(true)
                                        }}>
                                        Repetir albarán
                                    </button>
                                }

                                {(cargaEditForm.idAbono && cargaEditForm.idAbono.length > 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            history.push(allAppRoutes.abonosForm + cargaEditForm.idAbono)
                                        }}>
                                        Abono asociado
                                    </button>
                                }

                            </div>
                        }
                    </div>
                }

                {/* MODAL PARA SUBIR ARCHIVOS */}
                <Modal show={modalFilesState} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Añadir ficheros a albarán (*.pdf, *.tif)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(cargaEditForm.datosEntrega.qrIdReaded == "") &&
                            <div className="form-group ml-4 mt-4 mb-0">
                                <div className="custom-control custom-checkbox small">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck"
                                        checked={markDelivered}
                                        onChange={() => {
                                            setMarkDelivered(!markDelivered)
                                        }}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck">Marcar como
                                        entregado</label>
                                </div>
                            </div>
                        }
                        <UploadFile
                            onFileUpload={onFileUpload}
                            setFilesOnBase64={setBase64Files}
                            colWidth="12"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* Modal recurrencia */}
                <CargaCompletaRepeatModal
                    modalOpened={modalRecurrenciaOpened}
                    setModalOpened={setModalRecurrenciaOpened}
                />


                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Fecha de alta </label>
                            <div>{getFormattedDate(cargaEditForm.datosGenerales.fecAlta)}</div>
                        </div>

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Fecha albarán"
                            valueEdit={cargaEditForm.datosGenerales.recogida}
                            value={cargaForm.datosGenerales.recogida}
                            onChange={(value) => {
                                const newCarga: cargaCompleta = {
                                    ...cargaEditForm,
                                    datosGenerales: {
                                        ...cargaEditForm.datosGenerales,
                                        recogida: value,
                                        entrega: new Date(cargaEditForm.datosGenerales.entrega).getTime() < value.getTime()
                                            ? value
                                            : cargaEditForm.datosGenerales.entrega,
                                        fecAlta: props.addOrEdit === addingOrEditing.adding ? new Date() : cargaEditForm.datosGenerales.fecAlta
                                    },
                                }
                                dispatch(saveFormCarga({
                                    ...newCarga,
                                    lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                        return {
                                            ...lineaViaje,
                                            destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                ...destination,
                                                cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "recogida", company)
                                            }))
                                        }
                                    }),
                                }));
                            }}
                        />

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Previsión entrega"
                            valueEdit={cargaEditForm.datosGenerales.entrega}
                            value={cargaForm.datosGenerales.entrega}
                            onChange={(value) => {
                                const fecEntrega = new Date(value)
                                fecEntrega.setHours(23);
                                fecEntrega.setMinutes(59);
                                dispatch(saveFormCarga({
                                    ...cargaEditForm,
                                    datosGenerales: {
                                        ...cargaEditForm.datosGenerales,
                                        entrega: fecEntrega
                                    }
                                }));
                            }}
                        />


                        <div className="form-group col-md-2">
                            <label style={{fontWeight: 'bold'}} htmlFor="Estado">Estado: </label>
                            <div>
                                <div>
                                    <input
                                        className="form-control undefined"
                                        name="estado"
                                        disabled={true}
                                        type="text"
                                        value={
                                            cargaEditForm.datosEntrega.qrIdReaded.length > 0
                                                ? getEstadoString(estadoTrazaEnum.entregada)
                                                : getEstadoString(estadoTrazaEnum.introducida)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {(cargaEditForm.datosEntrega.qrIdReaded.length > 0 && userLogged.userType === usersTypes.employee) &&
                            <div className="form-group col-md-3">
                                <label style={{fontWeight: 'bold'}} htmlFor="Estado">Entregado por: </label>
                                <div>
                                    <input
                                        className="form-control undefined"
                                        name="entregadoPor"
                                        disabled={true}
                                        type="text"
                                        value={
                                            cargaEditForm.datosEntrega.whoDeliver.name + " " +
                                            cargaEditForm.datosEntrega.whoDeliver.apellido1 + ": " +
                                            getFormattedDateAndHourShort(cargaEditForm.datosEntrega.deliveredTime)
                                        }
                                    />
                                </div>
                            </div>
                        }

                        {cargaEditForm.idBill > 0 &&
                            <div className="form-group col-md-1">
                                <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Factura: </label>
                                <div>
                                    <input
                                        className="form-control undefined"
                                        name="idbill"
                                        disabled={true}
                                        type="text"
                                        value={cargaEditForm.idBill}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label style={{fontWeight: 'bold'}} htmlFor="name"> Cliente </label>
                            {(props.isEdditing === addingOrEditing.edditing && cargaEditForm.idBill === 0) ?
                                <ClientsAutocomplete
                                    clientName={cargaEditForm.datosGenerales.client ? cargaEditForm.datosGenerales.client.nombre : ""}
                                    setClient={(client) => {
                                        const newCarga: cargaCompleta = {
                                            ...cargaEditForm,
                                            datosGenerales: {
                                                ...cargaEditForm.datosGenerales,
                                                client: client,
                                                fecAlta: props.addOrEdit === addingOrEditing.adding ? new Date() : cargaEditForm.datosGenerales.fecAlta
                                            },
                                        }
                                        dispatch(saveFormCarga({
                                            ...newCarga,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                return {
                                                    ...lineaViaje,
                                                    destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                        ...destination,
                                                        cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "cliente", company)
                                                    }))
                                                }
                                            }),
                                        }));
                                        saveInicialicedTariffs();
                                        setCalculateTariff(true)
                                    }}
                                    setErrorForm={setErrorOnClients}
                                />
                                :
                                <input
                                    name="nameclient"
                                    disabled={true}
                                    type="text"
                                    value={cargaForm.datosGenerales.client.nombre}
                                    className="form-control undefined"
                                />
                            }
                        </div>


                        {(userLogged.userType === usersTypes.employee && suscriptionData.empleados) &&
                            <div className="form-group col-md-4">
                                <label style={{fontWeight: 'bold'}} htmlFor="name">Conductor</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <EmployeesAutocomplete
                                        employeeName={nombreCompleto(cargaEditForm.datosGenerales.driver)}
                                        setEmployee={(driver) => {
                                            let newVehicle: vehicle = {...cargaEditForm.datosGenerales.vehicle};
                                            let newRemolque: vehicle = {...cargaEditForm.datosGenerales.remolque};
                                            let newTypeVehicle: vehicleType = {...cargaEditForm.datosGenerales.typeVehicle};
                                            if (driver.assignedVehicle && driver.assignedVehicle.matricula.length > 0) {
                                                if (driver.assignedRemolque && driver.assignedRemolque.matricula.length > 0) {
                                                    newVehicle = {...driver.assignedVehicle};
                                                    newRemolque = {...driver.assignedRemolque};
                                                    newTypeVehicle = {...driver.assignedRemolque.vehicleType};
                                                } else if (driver.assignedVehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora) {
                                                    newVehicle = {...driver.assignedVehicle};
                                                    newTypeVehicle = {...initialVehicle.vehicleType};
                                                    newRemolque = {...initialVehicle};
                                                } else {
                                                    newVehicle = {...driver.assignedVehicle};
                                                    newTypeVehicle = {...driver.assignedVehicle.vehicleType};
                                                    newRemolque = {...initialVehicle};
                                                }
                                            }
                                            dispatch(saveFormCarga({
                                                ...cargaEditForm,
                                                datosGenerales: ({
                                                    ...cargaEditForm.datosGenerales,
                                                    driver: driver,
                                                    vehicle: cargaEditForm.datosGenerales.typeVehicle.typeVehicle.length > 0 ? cargaEditForm.datosGenerales.vehicle : newVehicle,
                                                    remolque: cargaEditForm.datosGenerales.typeVehicle.typeVehicle.length > 0 ? cargaEditForm.datosGenerales.remolque : newRemolque,
                                                    typeVehicle: cargaEditForm.datosGenerales.typeVehicle.typeVehicle.length > 0 ? cargaEditForm.datosGenerales.typeVehicle : newTypeVehicle
                                                })
                                            }));
                                            saveInicialicedTariffs();
                                        }}
                                    />
                                    :
                                    <input
                                        className="form-control undefined"
                                        name="conductor"
                                        disabled={true}
                                        type="text"
                                        value={
                                            cargaForm.datosGenerales.driver.name + " " +
                                            cargaForm.datosGenerales.driver.apellido1 + " " +
                                            cargaForm.datosGenerales.driver.apellido2
                                        }
                                    />
                                }
                            </div>
                        }

                        {(userLogged.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                            <div className="form-group col-md-4">
                                <label style={{fontWeight: 'bold'}} htmlFor="name">Transportista</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <SupplierAutocomplete
                                        supplierName={cargaEditForm.datosGenerales.supplier.nombre}
                                        setSupplier={(supplier) => {
                                            const newCarga: cargaCompleta = {
                                                ...cargaEditForm,
                                                datosGenerales: ({
                                                    ...cargaEditForm.datosGenerales,
                                                    supplier: supplier
                                                })
                                            }
                                            dispatch(saveFormCarga({
                                                ...newCarga,
                                                lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                    return {
                                                        ...lineaViaje,
                                                        importeProveedor: supplier.id === cargaEditForm.datosGenerales.supplier.id ? lineaViaje.importeProveedor : 0,
                                                        horasProveedor: supplier.id === cargaEditForm.datosGenerales.supplier.id ? lineaViaje.horasProveedor : 0,
                                                        tariffProveedor: supplier.id === cargaEditForm.datosGenerales.supplier.id ? lineaViaje.tariffProveedor : inicializeCargaCompletaTariff,
                                                        destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                            ...destination,
                                                            cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "transportista", company)
                                                        }))
                                                    }
                                                }),
                                            }));
                                            setCalculateTariff(true)
                                            saveInicialicedTariffs();
                                        }}
                                    />
                                    :
                                    <input
                                        className="form-control undefined"
                                        name="transportista"
                                        disabled={true}
                                        type="text"
                                        value={cargaForm.datosGenerales.supplier.nombre}
                                    />
                                }
                            </div>
                        }
                    </div>


                    <div className="form-row">
                        <div
                            className={cargaEditForm.datosGenerales.vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora ? "form-group col-md-2" : "form-group col-md-4"}>
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name">Vehículo</label>
                                {(props.isEdditing === addingOrEditing.edditing && cargaEditForm.idBill === 0) ?
                                    <VehicleAutocomplete
                                        vehicleName={cargaEditForm.datosGenerales.vehicle.matricula}
                                        setVehicle={(vehicle) => {
                                            const newCarga: cargaCompleta = {
                                                ...cargaEditForm,
                                                datosGenerales: ({
                                                    ...cargaEditForm.datosGenerales,
                                                    vehicle: vehicle,
                                                    typeVehicle: (vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.tractora) ? vehicle.vehicleType : initialVehicleType,
                                                    remolque: initialVehicle
                                                }),
                                            }
                                            dispatch(saveFormCarga({
                                                ...newCarga,
                                                lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                    return {
                                                        ...lineaViaje,
                                                        destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                            ...destination,
                                                            cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "vehicle", company)
                                                        }))
                                                    }
                                                }),
                                            }));
                                            saveInicialicedTariffs();
                                            setCalculateTariff(true)
                                        }}
                                        whatShow={"camiones"}
                                    />
                                    :
                                    <input
                                        className="form-control undefined"
                                        name="vehicle"
                                        disabled={true}
                                        type="text"
                                        value={cargaForm.datosGenerales.vehicle.matricula + " - " + cargaForm.datosGenerales.vehicle.marca + " " + cargaForm.datosGenerales.vehicle.modelo}
                                    />
                                }
                            </div>
                        </div>

                        {cargaEditForm.datosGenerales.vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora &&
                            <div className="form-group col-md-2">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="name">Remolque</label>
                                    {(props.isEdditing === addingOrEditing.edditing && cargaEditForm.idBill === 0) ?
                                        <VehicleAutocomplete
                                            key={cargaEditForm.datosGenerales.remolque.matricula}
                                            vehicleName={cargaEditForm.datosGenerales.remolque.matricula}
                                            setVehicle={(vehicle) => {
                                                const newCarga: cargaCompleta = {
                                                    ...cargaEditForm,
                                                    datosGenerales: ({
                                                        ...cargaEditForm.datosGenerales,
                                                        remolque: vehicle,
                                                        typeVehicle: vehicle.vehicleType
                                                    }),
                                                }
                                                dispatch(saveFormCarga({
                                                    ...newCarga,
                                                    lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                        return {
                                                            ...lineaViaje,
                                                            destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                                ...destination,
                                                                cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "vehicle", company)
                                                            }))
                                                        }
                                                    }),
                                                }));
                                                saveInicialicedTariffs();
                                                setCalculateTariff(true)
                                            }}
                                            whatShow={"remolques"}
                                        />
                                        :
                                        <input
                                            className="form-control undefined"
                                            name="remolque"
                                            disabled={true}
                                            type="text"
                                            value={cargaForm.datosGenerales.remolque.matricula + " - " + cargaForm.datosGenerales.remolque.marca + " " + cargaForm.datosGenerales.remolque.modelo}
                                        />
                                    }
                                </div>
                            </div>
                        }


                        <div className="form-group col-md-2">
                            <label style={{fontWeight: 'bold'}} htmlFor="name">Tipo vehículo</label>
                            {(props.isEdditing === addingOrEditing.edditing && cargaEditForm.idBill === 0) ?
                                <VehiclesTypesAutocomplete
                                    key={cargaEditForm.datosGenerales.vehicle.matricula}
                                    vehicleTypeSelected={cargaEditForm.datosGenerales.typeVehicle}
                                    onClick={(vehiType) => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            datosGenerales: ({
                                                ...cargaEditForm.datosGenerales,
                                                typeVehicle: vehiType
                                            })
                                        }));
                                        saveInicialicedTariffs()
                                        setCalculateTariff(true)
                                    }}
                                    setErrorForm={setErrorOnVehicleType}
                                    whatShow="remolques"
                                />
                                :
                                <input
                                    className="form-control undefined"
                                    name="tipvehicle"
                                    disabled={true}
                                    type="text"
                                    value={cargaForm.datosGenerales.typeVehicle.description}
                                />
                            }
                        </div>

                        <InputNumberSum
                            coolMesure={"2"}
                            autoComplete={false}
                            addOrEdit={props.addOrEdit}
                            isEdditing={cargaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            nameToShow="Bultos"
                            onChange={(valueNumber, valueText) => {
                                const newCarga: cargaCompleta = {
                                    ...cargaEditForm,
                                    datosGenerales: ({
                                        ...cargaEditForm.datosGenerales,
                                        bultos: valueNumber,
                                        bultosString: valueText
                                    }),
                                }
                                dispatch(saveFormCarga({
                                    ...newCarga,
                                    lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                        return {
                                            ...lineaViaje,
                                            destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                ...destination,
                                                cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "bultos", company)
                                            }))
                                        }
                                    }),
                                }));
                            }}
                            valueNumber={cargaForm.datosGenerales.bultos}
                            valueNumberEdit={cargaEditForm.datosGenerales.bultos}
                            valueText={cargaForm.datosGenerales.bultosString}
                            valueTextEdit={cargaEditForm.datosGenerales.bultosString}
                            withoutFormError={true}
                        />

                        <InputNumberSum
                            coolMesure={"2"}
                            addOrEdit={props.addOrEdit}
                            isEdditing={cargaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            nameToShow="Kilos"
                            autoComplete={false}
                            onChange={(valueNumber, valueText) => {
                                const newCarga: cargaCompleta = {
                                    ...cargaEditForm,
                                    datosGenerales: ({
                                        ...cargaEditForm.datosGenerales,
                                        kilos: valueNumber,
                                        kilosString: valueText
                                    }),
                                }
                                dispatch(saveFormCarga({
                                    ...newCarga,
                                    lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                        return {
                                            ...lineaViaje,
                                            destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                ...destination,
                                                cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "kilos", company)
                                            }))
                                        }
                                    }),
                                }));
                            }}
                            valueNumber={cargaForm.datosGenerales.kilos}
                            valueNumberEdit={cargaEditForm.datosGenerales.kilos}
                            valueText={cargaForm.datosGenerales.kilosString}
                            valueTextEdit={cargaEditForm.datosGenerales.kilosString}
                            decimalScale={2}
                            withoutFormError={true}
                        />

                        <InputNumberSum
                            coolMesure={"2"}
                            decimalScale={2}
                            addOrEdit={props.addOrEdit}
                            isEdditing={cargaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            nameToShow="M3"
                            autoComplete={false}
                            onChange={(valueNumber, valueText) => {
                                const newCarga: cargaCompleta = {
                                    ...cargaEditForm,
                                    datosGenerales: ({
                                        ...cargaEditForm.datosGenerales,
                                        m3: valueNumber,
                                        m3String: valueText
                                    }),
                                }
                                dispatch(saveFormCarga({
                                    ...newCarga,
                                    lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                        return {
                                            ...lineaViaje,
                                            destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                ...destination,
                                                cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "m3", company)
                                            }))
                                        }
                                    }),
                                }));
                            }}
                            valueNumber={cargaForm.datosGenerales.m3}
                            valueNumberEdit={cargaEditForm.datosGenerales.m3}
                            valueText={cargaForm.datosGenerales.m3String}
                            valueTextEdit={cargaEditForm.datosGenerales.m3String}
                            withoutFormError={true}
                        />

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name"> Referencia/s <small>(separadas por
                                    espacio)</small></label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="referencia"
                                        type="text"
                                        value={cargaEditForm.datosGenerales.referenciaString}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            var value = validateAlphaNumeric(event.target.value).toUpperCase()
                                            dispatch(saveFormCarga({
                                                ...cargaEditForm,
                                                datosGenerales: ({
                                                    ...cargaEditForm.datosGenerales,
                                                    referenciaString: value,
                                                    referencias: splitTextBySpaces(value),
                                                })
                                            }));
                                            dispatch(setValidateCargaForm({
                                                ...validateFieldsForm,
                                                referenciaExists: false
                                            }))

                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);

                                        }}
                                        className="form-control undefined"
                                    />
                                    :
                                    <div>
                                        <input
                                            className="form-control undefined"
                                            name="idbill"
                                            disabled={true}
                                            type="text"
                                            value={cargaForm.datosGenerales.referenciaString}
                                        />
                                    </div>
                                }


                                <div className="text-danger" style={{height: "20px"}}>
                                    {(
                                            validateFieldsForm.referenciaExists && (
                                                cargaEditForm.id === 0 ||
                                                (
                                                    cargaForm.id > 0 &&
                                                    cargaEditForm.id > 0 &&
                                                    cargaEditForm.datosGenerales.referenciaString !== cargaForm.datosGenerales.referenciaString
                                                )
                                            )
                                        ) &&
                                        <small>REFERENCIA ya existente</small>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="observaciones">Observaciones</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="observaciones"
                                        type="text"
                                        value={cargaEditForm.datosGenerales.observaciones}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            const newCarga: cargaCompleta = {
                                                ...cargaEditForm,
                                                datosGenerales: ({
                                                    ...cargaEditForm.datosGenerales,
                                                    observaciones: event.target.value.trimStart()
                                                }),
                                            }
                                            dispatch(saveFormCarga({
                                                ...newCarga,
                                                lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                    return {
                                                        ...lineaViaje,
                                                        destinations: lineaViaje.destinations.map((destination, indexDest) => ({
                                                            ...destination,
                                                            cmrInfo: getInfoToCmrForCarga(newCarga, lineaViaje, indexDest, "observaciones", company)
                                                        }))
                                                    }
                                                }),
                                            }));
                                        }}
                                        className="form-control undefined"/>
                                    :
                                    <div>
                                        <input
                                            className="form-control undefined"
                                            name="observ"
                                            disabled={true}
                                            type="text"
                                            value={cargaForm.datosGenerales.observaciones}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>

                    <div className="form-row">


                    </div>


                    <div className="form-row mt-2">

                        <div className="form-group col-md-12">

                            <PaqueteriaIncidencesList
                                from="cargas"
                                isEdditing={cargaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                                idForm={cargaEditForm._id ? cargaEditForm._id : ""}
                                listadoIncidencias={cargaEditForm.incidenciasEntregas}
                                setListadoIncidencias={(incidencias) => {
                                    dispatch(saveFormCarga({
                                        ...cargaEditForm,
                                        incidenciasEntregas: incidencias
                                    }));
                                }}
                            />

                        </div>

                    </div>


                </div>
            </div>


            {/****************************** LINEAS VIAJE ******************************************/}
            {/****************************** LINEAS VIAJE ******************************************/}
            {/****************************** LINEAS VIAJE ******************************************/}
            {/****************************** LINEAS VIAJE ******************************************/}

            {(props.isEdditing === addingOrEditing.edditing && cargaEditForm.idBill === 0) &&
                <div className="card shadow mb-4">
                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">Añadir servicio</h6>
                    </div>

                    {/***************************************************************** TABS ******************************************************************************/}

                    <div className="p-2">
                        <Tabs
                            selectedIndex={selectedIndex}
                            onSelect={(value) => {
                            }}
                        >
                            <TabList
                                style={{fontWeight: "bold", backgroundColor: "#f7f7f7"}}>
                                <Tab
                                    onClick={() => {
                                        setFormType(tariffsTypes.horas)
                                        setSelectedIndex(0)
                                    }}
                                >
                                    Por horas
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setFormType(tariffsTypes.distancia)
                                        setSelectedIndex(1)
                                    }}
                                >
                                    Por distancia recorrida
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setFormType(tariffsTypes.poblacion)
                                        setSelectedIndex(2)
                                    }}
                                >
                                    Por población destino
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setFormType(tariffsTypes.trayecto)
                                        setSelectedIndex(3)
                                    }}
                                >
                                    Trayectos guardados
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setFormType(tariffsTypes.otros)
                                        setSelectedIndex(4)
                                    }}
                                >
                                    Otros
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <FormHoras
                                    tariffText={tariffHorasText}
                                    vehicleType={cargaEditForm.datosGenerales.typeVehicle}
                                    isEdditing={props.isEdditing}
                                    addOrEdit={props.addOrEdit}
                                    lineaHoras={lineaHoras}
                                    supplier={cargaEditForm.datosGenerales.supplier}
                                    saveLineaHoras={(lineaHoras, noCalculate, recalculate) => {
                                        if (noCalculate && noCalculate === true) {
                                            setLineaHoras({...lineaHoras, lineType: tariffsTypes.horas})
                                        } else {
                                            const calc = calculateHorasCarga(lineaHoras, cargaEditForm, company, recalculate ? recalculate : false)
                                            setLineaHoras({
                                                ...lineaHoras,
                                                lineType: tariffsTypes.horas,
                                                importe: calc.subTotal,
                                                eurosHora: calc.eurosHora,
                                                importeOriginal: calc.subTotalOriginal,
                                                descuento: calc.descuento,
                                                iva: calc.iva,
                                                total: calc.total,
                                                tariffCarga: calc.tariff,
                                                importeProveedor: calc.subTotalProveedor,
                                                tariffProveedor: calc.tariffProveedor,
                                                horasProveedor: calc.horasProveedor
                                            })
                                            setTariffHorasText(calc.text)
                                        }
                                        setCalculateTariff(true)
                                    }}
                                    saveExistingLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                if (lineaViaje.id !== lineaHoras.id) {
                                                    return {...lineaViaje};
                                                }
                                                return {...lineaHoras, lineType: tariffsTypes.horas}
                                            }),
                                        }));
                                        setLineaHoras(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                    addLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: [...cargaEditForm.lineasViaje, {
                                                ...lineaHoras,
                                                id: cargaEditForm.lineasViaje.length > 0 ? cargaEditForm.lineasViaje[cargaEditForm.lineasViaje.length - 1].id + 1 : 1,
                                                lineType: tariffsTypes.horas
                                            }]
                                        }))
                                        setLineaHoras(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <FormDistancia
                                    tariffText={tariffDistanciaText}
                                    supplier={cargaEditForm.datosGenerales.supplier}
                                    vehicleType={cargaEditForm.datosGenerales.typeVehicle}
                                    isEdditing={props.isEdditing}
                                    addOrEdit={props.addOrEdit}
                                    lineaDistancia={lineaDistancia}
                                    saveLineaDistancia={(lineaDistancia, noCalculate) => {
                                        if (noCalculate && noCalculate === true) {
                                            setlineaDistancia({...lineaDistancia, lineType: tariffsTypes.distancia})
                                        } else {
                                            const calc = calculateDistanciaCarga(lineaDistancia, cargaEditForm, company)
                                            setlineaDistancia({
                                                ...lineaDistancia,
                                                lineType: tariffsTypes.distancia,
                                                importe: calc.subTotal,
                                                importeOriginal: calc.subTotalOriginal,
                                                descuento: calc.descuento,
                                                iva: calc.iva,
                                                total: calc.total,
                                                tariffCarga: calc.tariff,
                                                importeProveedor: calc.subTotalProveedor,
                                                tariffProveedor: calc.tariffProveedor
                                            })
                                            setTariffDistanciaText(calc.text)
                                        }
                                        setCalculateTariff(true)
                                    }}
                                    saveExistingLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                if (lineaViaje.id !== lineaDistancia.id) {
                                                    return {...lineaViaje};
                                                }
                                                return {
                                                    ...lineaDistancia,
                                                    lineType: tariffsTypes.distancia,
                                                    destinations: lineaDistancia.destinations.map((destination, indexDest) => ({
                                                        ...destination,
                                                        cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaDistancia, indexDest, "lineas", company)
                                                    }))
                                                }
                                            }),
                                        }));
                                        setCalculateTariff(true)
                                        setlineaDistancia(inicializeLineaViajeCarga)
                                    }}
                                    addLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: [...cargaEditForm.lineasViaje, {
                                                ...lineaDistancia,
                                                id: cargaEditForm.lineasViaje.length > 0 ? cargaEditForm.lineasViaje[cargaEditForm.lineasViaje.length - 1].id + 1 : 1,
                                                lineType: tariffsTypes.distancia,
                                                destinations: lineaDistancia.destinations.map((destination, indexDest) => ({
                                                    ...destination,
                                                    cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaDistancia, indexDest, "init", company)
                                                }))
                                            }]
                                        }))
                                        setlineaDistancia(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <FormPoblacion
                                    vehicleType={cargaEditForm.datosGenerales.typeVehicle}
                                    isEdditing={props.isEdditing}
                                    supplier={cargaEditForm.datosGenerales.supplier}
                                    addOrEdit={props.addOrEdit}
                                    lineaPoblacion={lineaPoblacion}
                                    saveLineaPoblacion={(newLineaPoblacion) => {
                                        setLineaPoblacion({...newLineaPoblacion, lineType: tariffsTypes.poblacion})
                                        setCalculateTariff(true)
                                    }}
                                    saveExistingLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                if (lineaViaje.id !== lineaPoblacion.id) {
                                                    return {...lineaViaje};
                                                }
                                                return {
                                                    ...lineaPoblacion,
                                                    lineType: tariffsTypes.poblacion,
                                                    destinations: lineaPoblacion.destinations.map((destination, indexDest) => ({
                                                        ...destination,
                                                        cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaPoblacion, indexDest, "lineas", company)
                                                    }))
                                                }
                                            }),
                                        }));
                                        setCalculateTariff(true)
                                        setLineaPoblacion(inicializeLineaViajeCarga)
                                    }}
                                    addLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: [...cargaEditForm.lineasViaje, {
                                                ...lineaPoblacion,
                                                id: cargaEditForm.lineasViaje.length > 0 ? cargaEditForm.lineasViaje[cargaEditForm.lineasViaje.length - 1].id + 1 : 1,
                                                lineType: tariffsTypes.poblacion,
                                                destinations: lineaPoblacion.destinations.map((destination, indexDest) => ({
                                                    ...destination,
                                                    cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaPoblacion, indexDest, "init", company)
                                                }))
                                            }]
                                        }))
                                        setCalculateTariff(true)
                                        setLineaPoblacion(inicializeLineaViajeCarga)
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <FormTrayecto
                                    isEdditing={props.isEdditing}
                                    addOrEdit={props.addOrEdit}
                                    supplier={cargaEditForm.datosGenerales.supplier}
                                    lineaTrayecto={lineaTrayecto}
                                    saveLineaTrayecto={(lineaTrayecto) => {
                                        setLineaTrayecto({...lineaTrayecto, lineType: tariffsTypes.trayecto})
                                        setCalculateTariff(true)
                                    }}
                                    saveExistingLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                if (lineaViaje.id !== lineaTrayecto.id) {
                                                    return {...lineaViaje};
                                                }
                                                return {
                                                    ...lineaTrayecto,
                                                    lineType: tariffsTypes.trayecto,
                                                    destinations: lineaTrayecto.destinations.map((destination, indexDest) => ({
                                                        ...destination,
                                                        cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaTrayecto, indexDest, "lineas", company)
                                                    }))
                                                }
                                            }),
                                        }));
                                        setLineaTrayecto(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                    addLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: [...cargaEditForm.lineasViaje, {
                                                ...lineaTrayecto,
                                                id: cargaEditForm.lineasViaje.length > 0 ? cargaEditForm.lineasViaje[cargaEditForm.lineasViaje.length - 1].id + 1 : 1,
                                                lineType: tariffsTypes.trayecto,
                                                destinations: lineaTrayecto.destinations.map((destination, indexDest) => ({
                                                    ...destination,
                                                    cmrInfo: getInfoToCmrForCarga(cargaEditForm, lineaTrayecto, indexDest, "init", company)
                                                }))
                                            }]
                                        }))
                                        setLineaTrayecto(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                    trayectosClient={
                                        cargaEditForm.datosGenerales.client.tariff &&
                                        cargaEditForm.datosGenerales.client.tariff.porTrayecto ?
                                            cargaEditForm.datosGenerales.client.tariff.porTrayecto : []
                                    }
                                    trayectosCompany={
                                        company.tariff &&
                                        company.tariff.porTrayecto ?
                                            company.tariff.porTrayecto : []
                                    }
                                    trayectosProveedor={
                                        cargaEditForm.datosGenerales.supplier.tariff &&
                                        cargaEditForm.datosGenerales.supplier.tariff.porTrayecto ?
                                            cargaEditForm.datosGenerales.supplier.tariff.porTrayecto : []
                                    }
                                    vehicleType={cargaEditForm.datosGenerales.typeVehicle}
                                />
                            </TabPanel>
                            <TabPanel>
                                <FormOtros
                                    isEdditing={props.isEdditing}
                                    addOrEdit={props.addOrEdit}
                                    lineaOtros={lineaOtros}
                                    saveLineaOtros={(lineaOtros) => {
                                        setLineaOtros({...lineaOtros, lineType: tariffsTypes.otros})
                                        setCalculateTariff(true)
                                    }}
                                    saveExistingLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: cargaEditForm.lineasViaje.map(lineaViaje => {
                                                if (lineaViaje.id !== lineaOtros.id) {
                                                    return {...lineaViaje};
                                                }
                                                return {...lineaOtros, lineType: tariffsTypes.otros}
                                            }),
                                        }));
                                        setLineaOtros(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                    addLinea={() => {
                                        dispatch(saveFormCarga({
                                            ...cargaEditForm,
                                            lineasViaje: [...cargaEditForm.lineasViaje, {
                                                ...lineaOtros,
                                                id: cargaEditForm.lineasViaje.length > 0 ? cargaEditForm.lineasViaje[cargaEditForm.lineasViaje.length - 1].id + 1 : 1,
                                                lineType: tariffsTypes.otros
                                            }]
                                        }))
                                        setLineaOtros(inicializeLineaViajeCarga)
                                        setCalculateTariff(true)
                                    }}
                                />
                            </TabPanel>
                        </Tabs>

                    </div>

                    {/***************************************************************** TABS ******************************************************************************/}

                </div>

            }


            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Servicios</h6>
                </div>
                <div className="card-body">
                    <CargaCompletaViajesList
                        isEdditing={cargaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                        cargaForm={cargaForm}
                        cargaEditForm={cargaEditForm}
                        saveFormCarga={(carga) => {
                            dispatch(saveFormCarga(carga));
                        }}
                        recalculate={() => {
                            setCalculateTariff(true)
                        }}
                        lineaViaje={lineaViajeToViajesList()}
                        saveLineaViaje={(linea) => {
                            switch (linea.lineType) {
                                case tariffsTypes.horas: {
                                    setLineaHoras(linea)
                                    setFormType(tariffsTypes.horas)
                                    setSelectedIndex(0)
                                    break;
                                }
                                case tariffsTypes.distancia: {
                                    setlineaDistancia(linea)
                                    setFormType(tariffsTypes.distancia)
                                    setSelectedIndex(1)
                                    break;
                                }
                                case tariffsTypes.poblacion: {
                                    setLineaPoblacion(linea)
                                    setFormType(tariffsTypes.poblacion)
                                    setSelectedIndex(2)
                                    break;
                                }
                                case tariffsTypes.trayecto: {
                                    setLineaTrayecto(linea)
                                    setFormType(tariffsTypes.trayecto)
                                    setSelectedIndex(3)
                                    break;
                                }
                                case tariffsTypes.otros: {
                                    setLineaOtros(linea)
                                    setFormType(tariffsTypes.otros)
                                    setSelectedIndex(4)
                                    break;
                                }
                                case "": { /* cuando llega vacío es que eliminamos la linea */
                                    linea.id === lineaDistancia.id && setlineaDistancia(inicializeLineaViajeCarga)
                                    linea.id === lineaHoras.id && setLineaHoras(inicializeLineaViajeCarga)
                                    linea.id === lineaPoblacion.id && setLineaPoblacion(inicializeLineaViajeCarga)
                                    linea.id === lineaTrayecto.id && setLineaTrayecto(inicializeLineaViajeCarga)
                                    linea.id === lineaOtros.id && setLineaOtros(inicializeLineaViajeCarga)
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }}
                    />
                </div>
            </div>


        </Fragment>

    );
};

export default CargaCompletaForm;