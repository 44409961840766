import React, {Fragment, ReactElement, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from '../../ApplicationState';
import {
    downloadAttached, downloadPaqueteriaPDF, setOpenCloseModalUploadFile, addFilesToPaqueteria,
    markPaqAsDelivered, pausePaqueteria, resumePaqueteria, setAutoFocusPaq
} from "./PaqueteriaStore";
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import ClientsAutocomplete from "../../components/Clients/ClientsAutocomplete";
import SupplierAutocomplete from "../Suppliers/SuppliersAutocomplete";
import InputText from "../../common/Inputs/InputText";
import InputNumberString from "../../common/Inputs/InputNumberString";
import InputDate from "../../common/Inputs/InputDate";
import InputNumberSum from "../../common/Inputs/InputNumberSum";
import GoogleAndClientsSuggestions from "../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import {UseDebounce} from "../../common/UseDebounce/UseDebounce";
import EmployeesAutocomplete from "../Employees/EmployeesAutocomplete";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import UploadFile from "../../common/Inputs/UploadFile";
import {useHistory} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import PaqueteriaIncidencesList from "./PaqueteriaIncidencesList";
import {
    initIncidenceType, validateAlphaNumeric, validateFormEmpty, validateNumberFormEmpty, initGeoPosition,
    loginUsers, initialVehicle, company, datosEntregaRequest, estadoTrazaEnum, inicializePaqueteriaTariff,
    paqueteria, inicialicePaqueteria, paqueteriaValidateFieldsResult, getEstadoString, getInfoToCmrForPaqueteria,
    inicialicePaqueteriaValidateFieldsResult, formatedDirection, CapitalizefirstLetter, splitTextBySpaces,
    sumarPlazoEntrega, nombreCompleto, getCoordinatesGoogleURL, addingOrEditing, plazosEntrega, usersTypes,
    tiposArticuladosVehiculos, getFormattedDate, getFormattedDateAndHourShort, incidenciasEntregas,
    suscriptionData, calculateAllTariffsPaqueteria, vehicle, inicializeCargaCompletaTariff
} from "aseguisShared";
import Swal from 'sweetalert2'
import {downloadCMR, saveFormCarga} from "../CargaCompleta/CargaCompletaStore";
import VehicleAutocomplete from "../Vehicles/VehicleAutocomplete";
import ModalCmr from "../../common/ModalCmr/modalCmr";
import {cmrImages} from "../CargaCompleta/CargaCompletaViajesList";
import {GetSuscriptionDataFromLocalStorage, trucoTipDirecciones} from "../../common/commonInterfaces/commonFunctions";
import ReactTooltip from "react-tooltip";
import {requestOneClient} from "../Clients/ClientsStore";
import {requestOneSupplier} from "../Suppliers/SuppliersStore";

interface infoToPaqueteriaForm {
    isEdditing: number;
    addOrEdit: number,
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    paqueteriaForm: paqueteria
    paqueteriaEditForm: paqueteria
    saveFormPaqueteria: (paqueteria: paqueteria) => void,
}

function PaqueteriaFormData(props: infoToPaqueteriaForm): ReactElement {

    const debounceRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const dispatch = useDispatch();
    let history = useHistory()

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const autoFocusActivated: boolean = useSelector((state: ApplicationState) => state.paqueteria.autoFocusActivated);
    const addedHistory: paqueteria[] = useSelector((state: ApplicationState) => state.paqueteria.historyAddList);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const openClosedModalEdit: boolean = useSelector((state: ApplicationState) => state.paqueteria.openCloseEditPaqModal);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);
    const [modalCmrOpen, setModalCmrOpen] = useState(false)
    const [entradaSalidaState, setEntradaSalidaState] = useState(props.paqueteriaEditForm.datosGenerales.entradaSalida);
    const [tariffText, setTariffText] = useState("");
    const [clientsAutoFocus, setClientsAutoFocus] = useState(true);
    const [entradaSalidaAutoFocus, setEntradaSalidaAutoFocus] = useState(true);
    const [cartaPorteAutofocus, setCartaPorteAutofocus] = useState(true);
    const [referenciaAutoFocus, setReferenciaAutofocus] = useState(false);
    const [errorOnClients, setErrorOnClients] = useState(false);
    const [errorOnAddress, setErrorOnAddress] = useState(false);
    const [goToValidateFields, setGoToValidateFields] = useState(false);
    const [validateFieldsForm, setValidateFieldsForm] = useState<paqueteriaValidateFieldsResult>(inicialicePaqueteriaValidateFieldsResult);

    const [tempValueReExp, setTempValueReExp] = useState(0);
    const debouncedValueReExp = UseDebounce(tempValueReExp.toString(), 400);
    useEffect(() => {
        calculateTariff(props.paqueteriaEditForm, "form", "no")
    }, [debouncedValueReExp]);

    const [tempValueDto, setTempValueDto] = useState(0);
    const debouncedValueDto = UseDebounce(tempValueDto.toString(), 400);
    useEffect(() => {
        calculateTariff(props.paqueteriaEditForm, "form", "no")
    }, [debouncedValueDto]);

    useEffect(() => {
        if (!openClosedModalEdit) {
            setGoToValidateFields(true)
            dispatch(setAutoFocusPaq(true))
            props.saveFormPaqueteria({
                ...props.paqueteriaEditForm,
                datosTransporte: {
                    ...props.paqueteriaEditForm.datosTransporte,
                    cmrInfo: getInfoToCmrForPaqueteria(props.paqueteriaEditForm, "init", company)
                }
            });
        }
    }, [addedHistory.length]);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [entradaSalidaState, props.isEdditing]);

    //MODAL upload ficheros
    const modalFilesState: boolean = useSelector((state: ApplicationState) => state.paqueteria.openCloseUploadModal);
    const handleClose = () => dispatch(setOpenCloseModalUploadFile(false))
    const handleShow = () => dispatch(setOpenCloseModalUploadFile(true))
    const [markDelivered, setMarkDelivered] = useState(false)
    const [base64Files, setBase64Files] = useState<string[]>([])
    const onFileUpload = () => {
        if (base64Files.length > 0) {
            if (props.paqueteriaEditForm._id) {
                dispatch(addFilesToPaqueteria({
                    deliveredTime: new Date(),
                    geoPosition: initGeoPosition,
                    imagesBase64: base64Files,
                    qrIdReaded: [props.paqueteriaEditForm._id],
                    whoDeliver: userLogged,
                    markDelivered: markDelivered,
                    tipoIncidencia: initIncidenceType
                }))
            }
        }
    }

    useEffect(() => {
        if (
            errorOnClients === true ||
            errorOnAddress === true ||
            props.paqueteriaEditForm.datosGenerales.entradaSalida.length < 2 ||
            props.paqueteriaEditForm.datosTransporte.address.calle.length < 2 ||
            props.paqueteriaEditForm.datosTransporte.addressDos.calle.length < 2 ||
            props.paqueteriaEditForm.datosTransporte.destName.length < 2 ||
            props.paqueteriaEditForm.datosTransporte.destNameDos.length < 2 ||
            (
                props.paqueteriaEditForm.datosTransporte.referenciaString.length < 2 &&
                props.paqueteriaEditForm.datosGenerales.entradaSalida === "Salida"
            ) ||
            props.paqueteriaEditForm.datosTransporte.bultos < 1 ||
            props.paqueteriaEditForm.datosTransporte.kilos < 1 ||
            props.paqueteriaEditForm.datosEconomicos.subTotal === 0 ||
            //miramos año 0 y 1 porque, en el  input, cuando se hace click en borrar se inicializa el campo con año o 1
            new Date(props.paqueteriaEditForm.datosGenerales.entrega).getFullYear() === 0 ||
            new Date(props.paqueteriaEditForm.datosGenerales.entrega).getFullYear() === 1 ||
            new Date(props.paqueteriaEditForm.datosGenerales.recogida).getFullYear() === 0 ||
            new Date(props.paqueteriaEditForm.datosGenerales.recogida).getFullYear() === 1
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [errorOnClients, errorOnAddress, props.paqueteriaEditForm, validateFieldsForm.anyFieldExist]);

    useEffect(() => {
        if (goToValidateFields) {
            const form: paqueteria = {...props.paqueteriaEditForm, idPaqRender: null}
            axios.post<paqueteriaValidateFieldsResult>('api/paqueteria/validateExists/', form)
                .then((data) => {
                    setValidateFieldsForm(data.data)
                })
                .catch((error) => {
                })
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    const calculateTariff = (paquet: paqueteria, reExpFrom: "tariff" | "form", calculateSupplier?: "no" | undefined) => {
        const calculatedTariff = calculateAllTariffsPaqueteria(paquet, company, reExpFrom, calculateSupplier)
        if (calculatedTariff.whatTariffUse.length > 0) {
            props.saveFormPaqueteria({
                ...paquet,
                datosEconomicos: {
                    ...paquet.datosEconomicos,
                    subTotal: calculatedTariff.subTotal,
                    reexpedicion: calculatedTariff.reexpTariff,
                    descuento: calculatedTariff.descuento,
                    tariffPaq: calculatedTariff.tariff,
                    tariffSelected: calculatedTariff.tariffType,
                    whatTariffUse: calculatedTariff.whatTariffUse,
                    tariffProveedor: calculatedTariff.tariffProveedor,
                    subTotalProveedor: calculatedTariff.subTotalProveedor,
                },
            })
            setTariffText(calculatedTariff.text)
        } else {
            props.saveFormPaqueteria({
                ...paquet,
                datosEconomicos: {
                    ...paquet.datosEconomicos,
                    descuento: calculatedTariff.descuento,
                    tariffPaq: calculatedTariff.tariff,
                    tariffSelected: calculatedTariff.tariffType,
                    whatTariffUse: calculatedTariff.whatTariffUse,
                    tariffProveedor: calculatedTariff.tariffProveedor,
                    subTotalProveedor: calculatedTariff.subTotalProveedor,
                },
            })
            setTariffText(calculatedTariff.text)
        }
    }

    useEffect(() => {
        if (props.paqueteriaEditForm.datosGenerales.entradaSalida.length > 0) {
            setEntradaSalidaState(props.paqueteriaEditForm.datosGenerales.entradaSalida)
        }
        if (props.addOrEdit === addingOrEditing.adding) {
            if (
                props.paqueteriaEditForm.datosGenerales.entradaSalida === "Entrada" &&
                props.paqueteriaEditForm.datosTransporte.addressDos.calle.length === 0
            ) {
                const newPaq: paqueteria = {
                    ...props.paqueteriaEditForm,
                    datosTransporte: {
                        ...props.paqueteriaEditForm.datosTransporte,
                        addressDos: company.address,
                        destNameDos: company.nombre,
                        telDos: company.tel1,
                    },
                }
                props.saveFormPaqueteria({
                    ...newPaq,
                    datosTransporte: {
                        ...newPaq.datosTransporte,
                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "destino", company)
                    },
                })
            }
            if (
                props.paqueteriaEditForm.datosGenerales.entradaSalida === "Salida" &&
                props.paqueteriaEditForm.datosTransporte.address.calle.length === 0
            ) {
                const newPaq: paqueteria = {
                    ...props.paqueteriaEditForm,
                    datosTransporte: {
                        ...props.paqueteriaEditForm.datosTransporte,
                        address: company.address,
                        destName: company.nombre,
                        tel: company.tel1,
                    }
                }
                props.saveFormPaqueteria({
                    ...newPaq,
                    datosTransporte: {
                        ...newPaq.datosTransporte,
                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "origen", company)
                    },
                })
            }
        }
    }, [props.paqueteriaEditForm.datosGenerales.entradaSalida]);

    //para recuperar las tarifas ya que no se guardan en la carga
    useEffect(() => {
        if (props.paqueteriaEditForm.datosGenerales.client._id) {
            if (props.paqueteriaEditForm.datosGenerales.client._id.length > 0 && props.isEdditing === addingOrEditing.edditing) {
                requestOneClient(props.paqueteriaEditForm.datosGenerales.client._id)
                    .then((clientsGetted) => {
                        if (clientsGetted.data.clientsResults.length === 1) {
                            if (props.paqueteriaEditForm.datosGenerales.supplier._id && props.paqueteriaEditForm.datosGenerales.supplier._id.length > 0) {
                                requestOneSupplier(props.paqueteriaEditForm.datosGenerales.supplier._id)
                                    .then((supplier) => {
                                        if (supplier.data.suppliersResults.length === 1) {
                                            props.saveFormPaqueteria({
                                                ...props.paqueteriaEditForm,
                                                datosGenerales: {
                                                    ...props.paqueteriaEditForm.datosGenerales,
                                                    client: clientsGetted.data.clientsResults[0],
                                                    supplier: supplier.data.suppliersResults[0]
                                                }
                                            });
                                        } else {
                                            history.push(allAppRoutes.paqueteriaList)
                                        }
                                    })
                                    .catch((error) => {
                                        history.push(allAppRoutes.paqueteriaList)
                                    })
                            } else {
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        client: clientsGetted.data.clientsResults[0]
                                    }
                                });
                            }
                        } else {
                            history.push(allAppRoutes.paqueteriaList)
                        }
                    })
                    .catch((error) => {
                        history.push(allAppRoutes.paqueteriaList)
                    })
            }
        }
    }, [props.isEdditing]);

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(props.addOrEdit !== addingOrEditing.adding) &&
                            <span> {"# " + props.paqueteriaEditForm.id.toString()}</span>
                        }
                        {(props.addOrEdit === addingOrEditing.adding) &&
                            <span> {" Añadir Paquetería "}</span>
                        }
                    </h6>
                </div>

                {(!openClosedModalEdit && props.addOrEdit === addingOrEditing.edditing) &&
                    <div className="header-buttons">
                        <button
                            className="btn btn-info left-margin btn-sm"
                            onClick={() => {
                                dispatch(downloadPaqueteriaPDF([props.paqueteriaEditForm.id], props.paqueteriaEditForm.datosGenerales.client, ""))
                            }}>
                            Descargar Albarán
                        </button>

                        {props.paqueteriaEditForm.datosEntrega.fileRelation.length > 0 &&
                            <button
                                className="btn btn-info left-margin btn-sm"
                                onClick={() => {
                                    if (props.paqueteriaEditForm._id && props.paqueteriaEditForm._id.length > 0) {
                                        dispatch(downloadAttached(props.paqueteriaEditForm._id))
                                    }
                                }}>
                                Descargar Adjuntos
                            </button>
                        }

                        {userLogged.userType === usersTypes.employee &&
                            <div>

                                <button
                                    className="btn btn-info left-margin btn-sm"
                                    onClick={() => {
                                        handleShow()
                                    }}>
                                    Añadir ficheros
                                </button>

                                {(props.paqueteriaEditForm.datosTransporte.estadoTraza === estadoTrazaEnum.introducida) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "¿Desea pausar este albarán?",
                                                text: "Un albarán en pausa queda en estado 'indefinido', excluido del ciclo de vida habitual y no afectado por 'fuera de plazo'",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonText: "Confirmar",
                                                confirmButtonColor: "#28a745",
                                                cancelButtonColor: "#d33",
                                                cancelButtonText: "Cancelar"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    dispatch(pausePaqueteria(props.paqueteriaEditForm))
                                                }
                                            });
                                        }}>
                                        Pausar albarán
                                    </button>
                                }

                                {(props.paqueteriaEditForm.datosTransporte.estadoTraza === estadoTrazaEnum.enPause) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "¿Desea reanudar este albarán?",
                                                text: "Éste albarán pasará de nuevo al ciclo de vida habitual",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonText: "Confirmar",
                                                confirmButtonColor: "#28a745",
                                                cancelButtonColor: "#d33",
                                                cancelButtonText: "Cancelar"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    dispatch(resumePaqueteria(props.paqueteriaEditForm))
                                                }
                                            });
                                        }}>
                                        Reanudar albarán
                                    </button>
                                }

                                {(props.paqueteriaEditForm.datosEntrega.qrIdReaded.length === 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            if (props.paqueteriaEditForm._id) {
                                                Swal.fire({
                                                    title: "¿Estás seguro?",
                                                    text: "Una vez entregado, esta acción no puede deshacerse",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Confirmar",
                                                    confirmButtonColor: "#28a745",
                                                    cancelButtonColor: "#d33",
                                                    cancelButtonText: "Cancelar"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        let entregaRequest: datosEntregaRequest = {
                                                            deliveredTime: new Date(),
                                                            geoPosition: initGeoPosition,
                                                            qrIdReaded: [props.paqueteriaEditForm._id!],
                                                            whoDeliver: userLogged,
                                                            imagesBase64: [],
                                                            markDelivered: true,
                                                            tipoIncidencia: initIncidenceType
                                                        }
                                                        dispatch(markPaqAsDelivered(entregaRequest))
                                                        Swal.fire({
                                                            title: "¡Listo!",
                                                            text: "Este albarán ha sido entregado",
                                                            icon: "success"
                                                        });
                                                    }
                                                });
                                            }
                                        }}>
                                        Marcar como entregado
                                    </button>
                                }

                                {(props.paqueteriaEditForm.datosEntrega.geoPosition.coords.latitude > 0 && props.paqueteriaEditForm.datosEntrega.geoPosition.coords.longitude > 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            let url = getCoordinatesGoogleURL(
                                                props.paqueteriaEditForm.datosEntrega.geoPosition.coords.latitude,
                                                props.paqueteriaEditForm.datosEntrega.geoPosition.coords.longitude
                                            )
                                            window.open(url);
                                        }}>
                                        Ubicación de entrega
                                    </button>
                                }

                                {(props.paqueteriaEditForm.idAbono && props.paqueteriaEditForm.idAbono.length > 0) &&
                                    <button
                                        className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            history.push(allAppRoutes.abonosForm + props.paqueteriaEditForm.idAbono)
                                        }}>
                                        Abono asociado
                                    </button>
                                }

                            </div>
                        }
                    </div>
                }

                <div className="card-body">

                    <div className="form-row">

                        <div className="form-group col-md-2">
                            <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Fecha de alta </label>
                            <div>{getFormattedDate(props.paqueteriaEditForm.datosGenerales.fecAlta)}</div>
                        </div>

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Fecha albarán"
                            valueEdit={props.paqueteriaEditForm.datosGenerales.recogida}
                            value={props.paqueteriaForm.datosGenerales.recogida}
                            onChange={(value) => {
                                const fecAlbaran = new Date(value)
                                const deliverDate = sumarPlazoEntrega(fecAlbaran, props.paqueteriaEditForm.datosGenerales.client.plazoEntregaPaq, company.diasFestivos);
                                deliverDate.setHours(23);
                                deliverDate.setMinutes(59);
                                const newPaq: paqueteria = {
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        recogida: value,
                                        entrega: deliverDate
                                    }
                                }
                                props.saveFormPaqueteria(newPaq);
                            }}
                        />

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name={"Previsión entrega " +
                                (
                                    (
                                        props.paqueteriaEditForm.datosGenerales.client.plazoEntregaPaq !== plazosEntrega.null &&
                                        props.paqueteriaEditForm.datosGenerales.client.plazoEntregaPaq !== "" &&
                                        userLogged.userType === usersTypes.employee
                                    ) ?
                                        "(" + props.paqueteriaEditForm.datosGenerales.client.plazoEntregaPaq + ")"
                                        :
                                        ""
                                )
                            }
                            valueEdit={props.paqueteriaEditForm.datosGenerales.entrega}
                            value={props.paqueteriaForm.datosGenerales.entrega}
                            onChange={(value) => {
                                const fecEntrega = new Date(value)
                                fecEntrega.setHours(23);
                                fecEntrega.setMinutes(59);
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        entrega: fecEntrega
                                    }
                                });
                            }}
                        />

                        <div className="form-group col-md-2">
                            <label style={{fontWeight: 'bold'}} htmlFor="Estado">Estado: </label>
                            <input
                                name="estadoPaq"
                                disabled={true}
                                type="text"
                                value={getEstadoString(props.paqueteriaEditForm.datosTransporte.estadoTraza)}
                                className="form-control undefined"
                            />
                            <div className="text-danger" style={{height: "20px"}}></div>
                        </div>

                        {(props.paqueteriaEditForm.datosEntrega.qrIdReaded.length > 0 && userLogged.userType === usersTypes.employee) &&
                            <div className="form-group col-md-3">
                                <label style={{fontWeight: 'bold'}} htmlFor="Estado">Entregado por: </label>
                                <input
                                    name="entregadoPor"
                                    disabled={true}
                                    type="text"
                                    value={
                                        props.paqueteriaEditForm.datosEntrega.whoDeliver.name + " " +
                                        props.paqueteriaEditForm.datosEntrega.whoDeliver.apellido1 + ": " +
                                        getFormattedDateAndHourShort(props.paqueteriaEditForm.datosEntrega.deliveredTime)
                                    }
                                    className="form-control undefined"
                                />
                            </div>
                        }

                        {props.paqueteriaEditForm.idBill > 0 &&
                            <div className="form-group col-md-1">
                                <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Factura: </label>
                                <input
                                    name="idBill"
                                    disabled={true}
                                    type="text"
                                    value={props.paqueteriaEditForm.idBill}
                                    className="form-control undefined"
                                />
                            </div>
                        }
                    </div>

                    <div className="form-row">

                        <InputText
                            className={validateFormEmpty(props.paqueteriaEditForm.datosGenerales.entradaSalida, 2)}
                            colMesure="2"
                            editEnabled={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            autoComplete={false}
                            addOrEdit={props.addOrEdit}
                            name="Entrada / Salida"
                            autoFocus={(
                                props.paqueteriaEditForm.datosGenerales.entradaSalida === "" &&
                                entradaSalidaAutoFocus === true
                                && autoFocusActivated
                            ) && true}
                            valueEdit={props.paqueteriaEditForm.datosGenerales.entradaSalida}
                            value={props.paqueteriaForm.datosGenerales.entradaSalida}
                            onChange={(event) => {
                                const firstPosition = event.target.value.toLowerCase().substring(0, 1)
                                if (firstPosition === "e") {
                                    const newPaquet: paqueteria = {
                                        ...props.paqueteriaEditForm,
                                        datosGenerales: {
                                            ...props.paqueteriaEditForm.datosGenerales,
                                            entradaSalida: "Entrada",
                                            fecAlta: props.addOrEdit === addingOrEditing.adding ? new Date() : props.paqueteriaEditForm.datosGenerales.fecAlta
                                        }
                                    }
                                    props.saveFormPaqueteria(newPaquet)
                                    calculateTariff(newPaquet, "tariff")
                                }
                                if (firstPosition === "s") {
                                    const newPaquet: paqueteria = {
                                        ...props.paqueteriaEditForm,
                                        datosGenerales: {
                                            ...props.paqueteriaEditForm.datosGenerales,
                                            entradaSalida: "Salida",
                                            fecAlta: props.addOrEdit === addingOrEditing.adding ? new Date() : props.paqueteriaEditForm.datosGenerales.fecAlta
                                        }
                                    }
                                    props.saveFormPaqueteria(newPaquet)
                                    calculateTariff(newPaquet, "tariff")
                                }
                                dispatch(setAutoFocusPaq(true))
                            }}
                            onFocus={() => {
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        entradaSalida: ""
                                    }
                                })
                                dispatch(setAutoFocusPaq(false))
                            }}
                            onBlur={() => {
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        entradaSalida: entradaSalidaState
                                    }
                                })
                                setEntradaSalidaAutoFocus(false)
                            }}
                        />

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name"> Cliente </label>
                                {(props.isEdditing === addingOrEditing.edditing && props.paqueteriaEditForm.idBill === 0) ?
                                    <ClientsAutocomplete
                                        autoFocus={(
                                            props.paqueteriaEditForm.datosGenerales.entradaSalida !== "" &&
                                            props.paqueteriaEditForm.datosGenerales.client.nombre === "" &&
                                            clientsAutoFocus
                                            && autoFocusActivated
                                        ) && true}
                                        clientName={props.paqueteriaEditForm.datosGenerales.client.nombre ? props.paqueteriaEditForm.datosGenerales.client.nombre : ""}
                                        setClient={(client) => {
                                            const fecAlbaran = new Date(props.paqueteriaEditForm.datosGenerales.recogida)
                                            const deliverDate = sumarPlazoEntrega(fecAlbaran, client.plazoEntregaPaq, company.diasFestivos);
                                            deliverDate.setHours(23);
                                            deliverDate.setMinutes(59);
                                            const newPaq: paqueteria = {
                                                ...props.paqueteriaEditForm,
                                                datosGenerales: {
                                                    ...props.paqueteriaEditForm.datosGenerales,
                                                    client: client,
                                                    entrega: deliverDate,
                                                    fecAlta: props.addOrEdit === addingOrEditing.adding ? new Date() : props.paqueteriaEditForm.datosGenerales.fecAlta
                                                },
                                                datosEconomicos: {
                                                    ...props.paqueteriaEditForm.datosEconomicos,
                                                    tariffPaq: inicializePaqueteriaTariff,
                                                    tariffSelected: "",
                                                    whatTariffUse: inicialicePaqueteria.datosEconomicos.tariffSelected
                                                },
                                                datosTransporte: {
                                                    ...props.paqueteriaEditForm.datosTransporte,
                                                    cartaPorte: "",
                                                },
                                            }
                                            props.saveFormPaqueteria({
                                                ...newPaq,
                                                datosTransporte: {
                                                    ...newPaq.datosTransporte,
                                                    cmrInfo: getInfoToCmrForPaqueteria(newPaq, "cliente", company)
                                                }
                                            });
                                            calculateTariff({
                                                ...newPaq,
                                                datosTransporte: {
                                                    ...newPaq.datosTransporte,
                                                    cmrInfo: getInfoToCmrForPaqueteria(newPaq, "cliente", company)
                                                }
                                            }, "tariff")
                                            setCartaPorteAutofocus(true)
                                            dispatch(setAutoFocusPaq(true))

                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);

                                        }}
                                        onBlur={() => {
                                            setClientsAutoFocus(false)
                                        }}
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        setErrorForm={setErrorOnClients}
                                    />
                                    :
                                    <input
                                        name="nameClient"
                                        disabled={true}
                                        type="text"
                                        value={props.paqueteriaForm.datosGenerales.client.nombre}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        {(userLogged.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="name">Transportista</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <SupplierAutocomplete
                                            supplierName={props.paqueteriaEditForm.datosGenerales.supplier.nombre}
                                            onFocus={() => {
                                                dispatch(setAutoFocusPaq(false))
                                            }}
                                            setSupplier={(supplier) => {
                                                const newPaq: paqueteria = {
                                                    ...props.paqueteriaEditForm,
                                                    datosGenerales: ({
                                                        ...props.paqueteriaEditForm.datosGenerales,
                                                        supplier: supplier,
                                                    }),
                                                    datosEconomicos: ({
                                                        ...props.paqueteriaEditForm.datosEconomicos,
                                                        subTotalProveedor: (supplier.id === props.paqueteriaEditForm.datosGenerales.supplier.id) ? props.paqueteriaEditForm.datosEconomicos.subTotalProveedor : 0,
                                                        tariffProveedor: (supplier.id === props.paqueteriaEditForm.datosGenerales.supplier.id) ? props.paqueteriaEditForm.datosEconomicos.tariffProveedor : inicializePaqueteriaTariff,
                                                    })
                                                }
                                                props.saveFormPaqueteria({
                                                    ...newPaq,
                                                    datosTransporte: {
                                                        ...newPaq.datosTransporte,
                                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "transportista", company)
                                                    }
                                                });
                                                calculateTariff({
                                                    ...newPaq,
                                                    datosTransporte: {
                                                        ...newPaq.datosTransporte,
                                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "transportista", company)
                                                    }
                                                }, "tariff")
                                            }}
                                        />
                                        :
                                        <input
                                            name="transportista"
                                            disabled={true}
                                            type="text"
                                            value={props.paqueteriaForm.datosGenerales.supplier.nombre}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                        <InputText
                            className="form-control undefined"
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            autoComplete={false}
                            addOrEdit={props.addOrEdit}
                            name="Carta de Porte"
                            autoFocus={(
                                props.paqueteriaEditForm.datosGenerales.entradaSalida !== "" &&
                                props.paqueteriaEditForm.datosGenerales.client.nombre !== "" &&
                                props.paqueteriaEditForm.datosTransporte.cartaPorte === "" &&
                                cartaPorteAutofocus
                                && autoFocusActivated
                            ) && true}
                            onBlur={() => {
                                setCartaPorteAutofocus(false)
                                dispatch(setAutoFocusPaq(true))
                            }}
                            onFocus={() => {
                                setCartaPorteAutofocus(true)
                                dispatch(setAutoFocusPaq(false))
                            }}
                            valueEdit={props.paqueteriaEditForm.datosTransporte.cartaPorte}
                            value={props.paqueteriaForm.datosTransporte.cartaPorte}
                            onChange={(event) => {
                                var value = event.target.value
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        cartaPorte: value
                                    }
                                });

                                setValidateFieldsForm({
                                    ...validateFieldsForm,
                                    cartaDePorte: false
                                })

                                if (debounceRef.current) {
                                    clearTimeout(debounceRef.current);
                                }
                                debounceRef.current = setTimeout(() => {
                                    setGoToValidateFields(true);
                                }, 400);

                            }}
                            showInvalidText={
                                validateFieldsForm.cartaDePorte && (
                                    props.paqueteriaEditForm.id === 0 ||
                                    (
                                        props.paqueteriaEditForm.id > 0 &&
                                        props.paqueteriaForm.id > 0 &&
                                        props.paqueteriaEditForm.datosTransporte.cartaPorte !== props.paqueteriaForm.datosTransporte.cartaPorte &&
                                        !addedHistory.some(albaran => albaran.datosTransporte.cartaPorte === props.paqueteriaEditForm.datosTransporte.cartaPorte)
                                    )
                                ) && !addedHistory.some(albaran => albaran.datosTransporte.cartaPorte === props.paqueteriaEditForm.datosTransporte.cartaPorte)
                                    ? "CARTA PORTE ya existente"
                                    : ""
                            }
                        />

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name">
                                    Origen
                                    {(props.isEdditing === addingOrEditing.edditing) &&
                                        trucoTipDirecciones()
                                    }
                                    {entradaSalidaState === "Entrada" &&
                                        <i
                                            className="fa-regular fa-circle-dot"
                                            style={{marginLeft: '5px', fontSize: '0.75em'}}
                                            data-tip={"En albaranes de entrada, se utiliza la dirección origen para calcular la tarifa"}
                                            data-class="register-support-custom-tooltip"
                                        ></i>
                                    }
                                    {(
                                            props.paqueteriaEditForm.datosTransporte.address.codigoPostal.length === 0 &&
                                            props.isEdditing === addingOrEditing.edditing &&
                                            props.paqueteriaEditForm.datosGenerales.entradaSalida === "Entrada"
                                        ) &&
                                        <small style={{color: '#de555d'}}>
                                            &nbsp;&nbsp;{"<CÓDIGO POSTAL no encontrado, no se calcularán tarifas>"}
                                        </small>
                                    }
                                </label>
                                <GoogleAndClientsSuggestions
                                    key={autoFocusActivated ? autoFocusActivated.toString() : ""}
                                    isEditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                                    valueNoEditing={formatedDirection(props.paqueteriaForm.datosTransporte.address)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    clientID={props.paqueteriaEditForm.datosGenerales.client._id ? props.paqueteriaEditForm.datosGenerales.client._id : ""}
                                    autoFocus={(
                                        props.paqueteriaEditForm.datosGenerales.entradaSalida !== "" &&
                                        props.paqueteriaEditForm.datosGenerales.client.nombre !== "" &&
                                        props.paqueteriaEditForm.datosTransporte.addressDos.calle !== "" &&
                                        props.paqueteriaEditForm.datosTransporte.address.calle === "" &&
                                        cartaPorteAutofocus === false &&
                                        autoFocusActivated &&
                                        openClosedModalEdit === false
                                    ) && true}
                                    onBlur={() => {
                                        dispatch(setAutoFocusPaq(false))
                                    }}
                                    activateClientsDirs={true}
                                    initialAddress={props.paqueteriaEditForm.datosTransporte.address}
                                    onClick={(dest, fromModal) => {
                                        dispatch(setAutoFocusPaq(true))
                                        //SI HACEMOS AUTOFOCUS EN REFERENCIA CUANDO VENIMOS DEL MODAL DE ESCOGER CP CUANDO HAY VARIOS, SE VA LA DIRECCION
                                        if (
                                            dest.address.calle.length > 0 &&
                                            dest.name.length > 0 &&
                                            props.paqueteriaEditForm.datosTransporte.referenciaString.length === 0 &&
                                            !fromModal
                                        ) {
                                            setReferenciaAutofocus(true)
                                        }
                                        const newPaq: paqueteria = {
                                            ...props.paqueteriaEditForm,
                                            datosTransporte: {
                                                ...props.paqueteriaEditForm.datosTransporte,
                                                address: dest.address,
                                                destName: dest.name,
                                                tel: dest.tel,
                                            }
                                        }
                                        props.saveFormPaqueteria({
                                            ...newPaq,
                                            datosTransporte: {
                                                ...newPaq.datosTransporte,
                                                cmrInfo: getInfoToCmrForPaqueteria(newPaq, "origen", company)
                                            }
                                        })
                                        calculateTariff({
                                            ...newPaq,
                                            datosTransporte: {
                                                ...newPaq.datosTransporte,
                                                cmrInfo: getInfoToCmrForPaqueteria(newPaq, "origen", company)
                                            }
                                        }, "tariff")
                                    }}
                                    setErrorForm={setErrorOnAddress}
                                />

                            </div>
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="1"
                            autoComplete={false}
                            editEnabled={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Piso/Pta"
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            valueEdit={props.paqueteriaEditForm.datosTransporte.address.puerta}
                            value={props.paqueteriaForm.datosTransporte.address.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        address: {
                                            ...props.paqueteriaEditForm.datosTransporte.address,
                                            puerta: value
                                        }
                                    }
                                });
                            }}
                        />

                        <InputText
                            className={validateFormEmpty(props.paqueteriaEditForm.datosTransporte.destName, 2)}
                            colMesure="3"
                            editEnabled={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            autoComplete={false}
                            addOrEdit={props.addOrEdit}
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            name="Nombre"
                            valueEdit={props.paqueteriaEditForm.datosTransporte.destName}
                            value={props.paqueteriaForm.datosTransporte.destName}
                            onChange={(event) => {
                                var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        destName: value
                                    }
                                });
                            }}
                        />

                        <InputNumberString key={props.paqueteriaEditForm.datosTransporte.destName}
                                           colMesure="2"
                                           format="### ## ## ## ## ## ##"
                                           editEnabled={props.isEdditing}
                                           addOrEdit={props.addOrEdit}
                                           name="Teléfono"
                                           onFocus={() => {
                                               dispatch(setAutoFocusPaq(false))
                                           }}
                                           autoComplete={false}
                                           valueEdit={props.paqueteriaEditForm.datosTransporte.tel}
                                           value={props.paqueteriaForm.datosTransporte.tel}
                                           onChange={(value) => {
                                               props.saveFormPaqueteria({
                                                   ...props.paqueteriaEditForm,
                                                   datosTransporte: {
                                                       ...props.paqueteriaEditForm.datosTransporte,
                                                       tel: value.value
                                                   }
                                               });
                                           }}
                        />

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <div>

                                <label style={{fontWeight: 'bold'}} htmlFor="name">
                                    Destino
                                    {(props.isEdditing === addingOrEditing.edditing) &&
                                        trucoTipDirecciones()
                                    }
                                    {entradaSalidaState === "Salida" &&
                                        <i
                                            className="fa-regular fa-circle-dot"
                                            style={{marginLeft: '5px', fontSize: '0.75em'}}
                                            data-tip={"En albaranes de salida, se utiliza la dirección destino para calcular la tarifa"}
                                            data-class="register-support-custom-tooltip"
                                        ></i>
                                    }
                                    {(
                                            props.paqueteriaEditForm.datosTransporte.addressDos.codigoPostal.length === 0 &&
                                            props.isEdditing === addingOrEditing.edditing &&
                                            props.paqueteriaEditForm.datosGenerales.entradaSalida !== "Entrada"
                                        ) &&
                                        <small style={{color: '#de555d'}}>
                                            &nbsp;&nbsp;{"<CÓDIGO POSTAL no encontrado, no se calcularán tarifas>"}
                                        </small>
                                    }
                                </label>

                                <GoogleAndClientsSuggestions
                                    key={autoFocusActivated ? autoFocusActivated.toString() : ""}
                                    isEditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                                    valueNoEditing={formatedDirection(props.paqueteriaForm.datosTransporte.addressDos)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    clientID={props.paqueteriaEditForm.datosGenerales.client._id ? props.paqueteriaEditForm.datosGenerales.client._id : ""}
                                    autoFocus={(
                                        props.paqueteriaEditForm.datosGenerales.entradaSalida !== "" &&
                                        props.paqueteriaEditForm.datosGenerales.client.nombre !== "" &&
                                        props.paqueteriaEditForm.datosTransporte.address.calle !== "" &&
                                        props.paqueteriaEditForm.datosTransporte.addressDos.calle === "" &&
                                        cartaPorteAutofocus === false &&
                                        autoFocusActivated &&
                                        openClosedModalEdit === false
                                    ) && true}
                                    onBlur={() => {
                                        dispatch(setAutoFocusPaq(false))
                                    }}
                                    activateClientsDirs={true}
                                    initialAddress={props.paqueteriaEditForm.datosTransporte.addressDos}
                                    onClick={(dest, fromModal) => {
                                        dispatch(setAutoFocusPaq(true))
                                        //SI HACEMOS AUTOFOCUS EN REFERENCIA CUANDO VENIMOS DEL MODAL DE ESCOGER CP CUANDO HAY VARIOS CPS, SE VA LA DIRECCION
                                        if (dest.address.calle.length > 0 &&
                                            dest.name.length > 0 &&
                                            props.paqueteriaEditForm.datosTransporte.referenciaString.length === 0 &&
                                            fromModal === false
                                        ) {
                                            setReferenciaAutofocus(true)
                                        }
                                        const newPaq: paqueteria = {
                                            ...props.paqueteriaEditForm,
                                            datosTransporte: {
                                                ...props.paqueteriaEditForm.datosTransporte,
                                                addressDos: dest.address,
                                                destNameDos: dest.name,
                                                telDos: dest.tel,
                                            }
                                        }
                                        props.saveFormPaqueteria({
                                            ...newPaq,
                                            datosTransporte: {
                                                ...newPaq.datosTransporte,
                                                cmrInfo: getInfoToCmrForPaqueteria(newPaq, "destino", company)
                                            }
                                        })
                                        calculateTariff({
                                            ...newPaq,
                                            datosTransporte: {
                                                ...newPaq.datosTransporte,
                                                cmrInfo: getInfoToCmrForPaqueteria(newPaq, "destino", company)
                                            }
                                        }, "tariff")
                                    }}
                                    setErrorForm={setErrorOnAddress}
                                />

                            </div>
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="1"
                            autoComplete={false}
                            editEnabled={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Piso/Pta"
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            valueEdit={props.paqueteriaEditForm.datosTransporte.addressDos.puerta}
                            value={props.paqueteriaForm.datosTransporte.addressDos.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        addressDos: {
                                            ...props.paqueteriaEditForm.datosTransporte.addressDos,
                                            puerta: value
                                        }
                                    }
                                });
                            }}
                        />

                        <InputText
                            className={validateFormEmpty(props.paqueteriaEditForm.datosTransporte.destNameDos, 2)}
                            colMesure="3"
                            editEnabled={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            autoComplete={false}
                            addOrEdit={props.addOrEdit}
                            name="Nombre"
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            valueEdit={props.paqueteriaEditForm.datosTransporte.destNameDos}
                            value={props.paqueteriaForm.datosTransporte.destNameDos}
                            onChange={(event) => {
                                var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        destNameDos: value
                                    }
                                });
                            }}
                        />

                        <InputNumberString key={props.paqueteriaEditForm.datosTransporte.destName}
                                           colMesure="2"
                                           format="### ## ## ## ## ## ##"
                                           editEnabled={props.isEdditing}
                                           addOrEdit={props.addOrEdit}
                                           name="Teléfono"
                                           onFocus={() => {
                                               dispatch(setAutoFocusPaq(false))
                                           }}
                                           autoComplete={false}
                                           valueEdit={props.paqueteriaEditForm.datosTransporte.telDos}
                                           value={props.paqueteriaForm.datosTransporte.telDos}
                                           onChange={(value) => {
                                               props.saveFormPaqueteria({
                                                   ...props.paqueteriaEditForm,
                                                   datosTransporte: {
                                                       ...props.paqueteriaEditForm.datosTransporte,
                                                       telDos: value.value
                                                   }
                                               });
                                           }}
                        />

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name"> Referencia/s <small>(separadas por
                                    espacio)</small></label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        key={props.paqueteriaEditForm.datosTransporte.destName + props.paqueteriaEditForm.datosTransporte.destNameDos}
                                        name="referencia"
                                        type="text"
                                        autoComplete="off"
                                        autoFocus={(
                                            referenciaAutoFocus &&
                                            autoFocusActivated &&
                                            props.paqueteriaEditForm.datosTransporte.address.calle !== "" &&
                                            props.paqueteriaEditForm.datosTransporte.addressDos.calle !== ""
                                        ) && true}
                                        onBlur={() => {
                                            setReferenciaAutofocus(false)
                                        }}
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        value={props.paqueteriaEditForm.datosTransporte.referenciaString}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value).toUpperCase()
                                            props.saveFormPaqueteria({
                                                ...props.paqueteriaEditForm,
                                                datosTransporte: {
                                                    ...props.paqueteriaEditForm.datosTransporte,
                                                    referenciaString: value,
                                                    referencias: splitTextBySpaces(value)
                                                }
                                            });
                                            setValidateFieldsForm({
                                                ...validateFieldsForm,
                                                referenciaExists: false
                                            })

                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);

                                        }}
                                        className={
                                            props.paqueteriaEditForm.datosGenerales.entradaSalida === "Salida"
                                                ?
                                                validateFormEmpty(props.paqueteriaEditForm.datosTransporte.referenciaString, 2)
                                                :
                                                "form-control"
                                        }
                                    />
                                    :
                                    <input
                                        name="referencia"
                                        disabled={true}
                                        type="text"
                                        value={props.paqueteriaForm.datosTransporte.referenciaString}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(
                                            validateFieldsForm.referenciaExists && (
                                                props.paqueteriaEditForm.id === 0 ||
                                                (
                                                    props.paqueteriaEditForm.id > 0 &&
                                                    props.paqueteriaForm.id > 0 &&
                                                    props.paqueteriaEditForm.datosTransporte.referenciaString !== props.paqueteriaForm.datosTransporte.referenciaString
                                                )
                                            )
                                        ) &&
                                        <small>REFERENCIA ya existente</small>
                                    }
                                </div>

                            </div>
                        </div>


                        <InputNumberSum
                            coolMesure={"2"}
                            addOrEdit={props.addOrEdit}
                            isEdditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            autoComplete={false}
                            nameToShow="Bultos"
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            onChange={(valueNumber, valueText) => {
                                const newPaq: paqueteria = {
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        bultos: valueNumber,
                                        bultosString: valueText
                                    }
                                }
                                props.saveFormPaqueteria({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "bultos", company)
                                    }
                                })
                                calculateTariff({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "bultos", company)
                                    }
                                }, "tariff")
                            }}
                            valueNumber={props.paqueteriaForm.datosTransporte.bultos}
                            valueNumberEdit={props.paqueteriaEditForm.datosTransporte.bultos}
                            valueText={props.paqueteriaForm.datosTransporte.bultosString}
                            valueTextEdit={props.paqueteriaEditForm.datosTransporte.bultosString}
                        />


                        <InputNumberSum
                            coolMesure={"2"}
                            decimalScale={2}
                            addOrEdit={props.addOrEdit}
                            isEdditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            autoComplete={false}
                            nameToShow="Kilos"
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            onChange={(valueNumber, valueText) => {
                                const newPaq: paqueteria = {
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        kilos: valueNumber,
                                        kilosString: valueText
                                    }
                                }
                                props.saveFormPaqueteria({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "kilos", company)
                                    }
                                })
                                calculateTariff({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "kilos", company)
                                    }
                                }, "tariff")
                            }}
                            valueNumber={props.paqueteriaForm.datosTransporte.kilos}
                            valueNumberEdit={props.paqueteriaEditForm.datosTransporte.kilos}
                            valueText={props.paqueteriaForm.datosTransporte.kilosString}
                            valueTextEdit={props.paqueteriaEditForm.datosTransporte.kilosString}
                        />

                        <InputNumberSum
                            coolMesure={"2"}
                            decimalScale={2}
                            addOrEdit={props.addOrEdit}
                            isEdditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                            nameToShow="M3"
                            autoComplete={false}
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            onChange={(valueNumber, valueText) => {
                                var pesoVolumetrico = 0
                                var pesoVolumetricoString = ""
                                if (props.paqueteriaEditForm.datosGenerales.client.m3ToKg > 0) {
                                    pesoVolumetrico = valueNumber * props.paqueteriaEditForm.datosGenerales.client.m3ToKg
                                    pesoVolumetricoString = pesoVolumetrico > 0 ? pesoVolumetrico.toString() : ""
                                } else {
                                    pesoVolumetrico = props.paqueteriaEditForm.datosTransporte.kilos
                                    pesoVolumetricoString = props.paqueteriaEditForm.datosTransporte.kilosString
                                }
                                const newPaq: paqueteria = {
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        m3: valueNumber,
                                        m3String: valueText,

                                        kilos: pesoVolumetrico,
                                        kilosString: pesoVolumetricoString
                                    }
                                }
                                props.saveFormPaqueteria({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "m3", company)
                                    }
                                })
                                calculateTariff({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "m3", company)
                                    }
                                }, "tariff")
                            }}
                            valueNumber={props.paqueteriaForm.datosTransporte.m3}
                            valueNumberEdit={props.paqueteriaEditForm.datosTransporte.m3}
                            valueText={props.paqueteriaForm.datosTransporte.m3String}
                            valueTextEdit={props.paqueteriaEditForm.datosTransporte.m3String}
                            withoutFormError={true}
                        />

                    </div>


                    <div className="form-row">

                        <InputText
                            onFocus={() => {
                                dispatch(setAutoFocusPaq(false))
                            }}
                            colMesure="5"
                            editEnabled={props.isEdditing}
                            addOrEdit={props.addOrEdit}
                            name="Observaciones"
                            valueEdit={props.paqueteriaEditForm.datosGenerales.observaciones}
                            autoComplete={false}
                            value={props.paqueteriaForm.datosGenerales.observaciones}
                            onChange={(event) => {
                                const newPaq: paqueteria = {
                                    ...props.paqueteriaEditForm,
                                    datosGenerales: {
                                        ...props.paqueteriaEditForm.datosGenerales,
                                        observaciones: event.target.value.trimStart()
                                    }
                                }
                                props.saveFormPaqueteria({
                                    ...newPaq,
                                    datosTransporte: {
                                        ...newPaq.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "observaciones", company)
                                    }
                                });
                            }}
                        />


                        {(userLogged.userType === usersTypes.employee && suscriptionData.empleados) &&
                            <div className="form-group col-md-3">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="name">Conductor</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <EmployeesAutocomplete
                                            onFocus={() => {
                                                dispatch(setAutoFocusPaq(false))
                                            }}
                                            employeeName={nombreCompleto(props.paqueteriaEditForm.datosGenerales.driver)}
                                            setEmployee={(driver) => {
                                                let newVehicle: vehicle = {...props.paqueteriaEditForm.datosGenerales.vehicle};
                                                let newRemolque: vehicle = props.paqueteriaEditForm.datosGenerales.remolque
                                                    ? {...props.paqueteriaEditForm.datosGenerales.remolque}
                                                    : {...initialVehicle};
                                                if (driver.assignedVehicle && driver.assignedVehicle.matricula.length > 0) {
                                                    newVehicle = {...driver.assignedVehicle};
                                                    newRemolque = {...initialVehicle};
                                                    if (driver.assignedRemolque && driver.assignedRemolque.matricula.length > 0) {
                                                        newRemolque = {...driver.assignedRemolque};
                                                    }
                                                }
                                                props.saveFormPaqueteria({
                                                    ...props.paqueteriaEditForm,
                                                    datosGenerales: ({
                                                        ...props.paqueteriaEditForm.datosGenerales,
                                                        driver: driver,
                                                        vehicle: newVehicle,
                                                        remolque: newRemolque,
                                                    })
                                                });
                                            }}
                                        />
                                        :
                                        <input
                                            name="driver"
                                            disabled={true}
                                            type="text"
                                            value={
                                                props.paqueteriaForm.datosGenerales.driver.name + " " +
                                                props.paqueteriaForm.datosGenerales.driver.apellido1 + " " +
                                                props.paqueteriaForm.datosGenerales.driver.apellido2
                                            }
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }


                        <div
                            className={props.paqueteriaEditForm.datosGenerales.vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora ? "form-group col-md-2" : "form-group col-md-4"}>
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name">Vehículo</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <VehicleAutocomplete
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        vehicleName={props.paqueteriaEditForm.datosGenerales.vehicle.matricula}
                                        setVehicle={(vehicle) => {
                                            const newPaq: paqueteria = {
                                                ...props.paqueteriaEditForm,
                                                datosGenerales: ({
                                                    ...props.paqueteriaEditForm.datosGenerales,
                                                    vehicle: vehicle,
                                                    remolque: initialVehicle
                                                })
                                            }
                                            props.saveFormPaqueteria({
                                                ...newPaq,
                                                datosTransporte: {
                                                    ...newPaq.datosTransporte,
                                                    cmrInfo: getInfoToCmrForPaqueteria(newPaq, "vehicle", company)
                                                }
                                            });
                                        }}
                                        whatShow={"camiones"}
                                    />
                                    :
                                    <input
                                        name="vehicle"
                                        disabled={true}
                                        type="text"
                                        value={
                                            props.paqueteriaForm.datosGenerales.vehicle.matricula + " - " +
                                            props.paqueteriaForm.datosGenerales.vehicle.marca + " " +
                                            props.paqueteriaForm.datosGenerales.vehicle.modelo
                                        }
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        {props.paqueteriaEditForm.datosGenerales.vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora &&
                            <div className="form-group col-md-2">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="name">Remolque</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <VehicleAutocomplete
                                            key={props.paqueteriaEditForm.datosGenerales.remolque ? props.paqueteriaEditForm.datosGenerales.remolque.matricula : ""}
                                            onFocus={() => {
                                                dispatch(setAutoFocusPaq(false))
                                            }}
                                            vehicleName={props.paqueteriaEditForm.datosGenerales.remolque ? props.paqueteriaEditForm.datosGenerales.remolque.matricula : ""}
                                            setVehicle={(vehicle) => {
                                                const newPaq: paqueteria = {
                                                    ...props.paqueteriaEditForm,
                                                    datosGenerales: ({
                                                        ...props.paqueteriaEditForm.datosGenerales,
                                                        remolque: vehicle
                                                    })
                                                }
                                                props.saveFormPaqueteria({
                                                    ...newPaq,
                                                    datosTransporte: {
                                                        ...newPaq.datosTransporte,
                                                        cmrInfo: getInfoToCmrForPaqueteria(newPaq, "vehicle", company)
                                                    }
                                                });
                                            }}
                                            whatShow={"remolques"}
                                        />
                                        :
                                        props.paqueteriaForm.datosGenerales.remolque ?
                                            <input
                                                name="vehicle"
                                                disabled={true}
                                                type="text"
                                                value={
                                                    props.paqueteriaForm.datosGenerales.remolque.matricula + " - " +
                                                    props.paqueteriaForm.datosGenerales.remolque.marca + " " +
                                                    props.paqueteriaForm.datosGenerales.remolque.modelo
                                                }
                                                className="form-control undefined"
                                            />
                                            :
                                            <div></div>
                                    }
                                </div>
                            </div>
                        }

                    </div>

                    <div className="form-row mt-3">

                        <div className="form-group col-md-2">
                            <div>
                                {(props.isEdditing === addingOrEditing.edditing) &&
                                    <div>
                                        {/*tariffText*/}
                                    </div>
                                }
                            </div>
                        </div>

                        {(props.isEdditing === addingOrEditing.edditing && props.paqueteriaEditForm.idBill === 0) &&
                            <>
                                <div className="form-group col-md-1">
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{fontWeight: 'bold'}} htmlFor="name"> Re-expedición</label>
                                    <NumberFormat
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        autoComplete="off"
                                        decimalScale={2}
                                        value={props.paqueteriaEditForm.datosEconomicos.reexpedicion}
                                        onValueChange={(value) => {
                                            const valueNumber = value.floatValue ? value.floatValue : 0
                                            props.saveFormPaqueteria({
                                                ...props.paqueteriaEditForm,
                                                datosEconomicos: {
                                                    ...props.paqueteriaEditForm.datosEconomicos,
                                                    reexpedicion: valueNumber,
                                                }
                                            });
                                            setTempValueReExp(valueNumber)
                                        }}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        className="form-control"
                                        suffix={" \u20AC"}
                                    />
                                </div>
                                <div className="form-group col-md-2">
                                    <label style={{fontWeight: 'bold'}} htmlFor="name"> Descuento (%)</label>
                                    <NumberFormat
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        suffix={" %"}
                                        value={props.paqueteriaEditForm.datosEconomicos.descuento}
                                        className="form-control undefined"
                                        isAllowed={(value: NumberFormatValues) => {
                                            const {floatValue} = value;
                                            const numberValue = floatValue ? floatValue : 0
                                            if (numberValue) {
                                                return numberValue < 100;
                                            } else return true
                                        }}
                                        onValueChange={(value: NumberFormatValues) => {
                                            const {floatValue} = value;
                                            const numberValue = floatValue ? floatValue : 0
                                            props.saveFormPaqueteria({
                                                ...props.paqueteriaEditForm,
                                                datosEconomicos: ({
                                                    ...props.paqueteriaEditForm.datosEconomicos,
                                                    descuento: numberValue
                                                })
                                            });
                                            setTempValueDto(numberValue)
                                        }}
                                    />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{fontWeight: 'bold'}} htmlFor="name"> Importe</label>
                                    <NumberFormat
                                        onFocus={() => {
                                            dispatch(setAutoFocusPaq(false))
                                        }}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        autoComplete="off"
                                        decimalScale={2}
                                        onValueChange={(value) => {
                                            const valueNumber = value.floatValue ? value.floatValue : 0
                                            props.saveFormPaqueteria({
                                                ...props.paqueteriaEditForm,
                                                datosEconomicos: {
                                                    ...props.paqueteriaEditForm.datosEconomicos,
                                                    subTotal: valueNumber,
                                                }
                                            });
                                        }}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={props.paqueteriaEditForm.datosEconomicos.subTotal}
                                        className={validateNumberFormEmpty(props.paqueteriaEditForm.datosEconomicos.subTotal, 0)}
                                        suffix={" \u20AC"}
                                    />
                                </div>

                                {(props.paqueteriaEditForm.datosGenerales.supplier._id && props.paqueteriaEditForm.datosGenerales.supplier._id.length > 0) &&
                                    <div className="form-group col-md-2">
                                        <label style={{fontWeight: 'bold'}} htmlFor="name"> Importe SubContrata</label>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            onValueChange={(value) => {
                                                const valueNumber = value.floatValue ? value.floatValue : 0
                                                props.saveFormPaqueteria({
                                                    ...props.paqueteriaEditForm,
                                                    datosEconomicos: {
                                                        ...props.paqueteriaEditForm.datosEconomicos,
                                                        subTotalProveedor: valueNumber,
                                                    }
                                                });
                                            }}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={props.paqueteriaEditForm.datosEconomicos.subTotalProveedor}
                                            className="form-control undefined"
                                            suffix={" \u20AC"}
                                        />
                                    </div>
                                }

                                <div className="form-group col-md-1">
                                    <div>
                                        {!openClosedModalEdit &&
                                            <button
                                                className="btn btn-info left-margin btn-sm"
                                                style={{marginTop: "32px"}}
                                                onClick={() => {
                                                    calculateTariff(props.paqueteriaEditForm, "tariff")
                                                }}>
                                                Recalcular
                                            </button>
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        {(props.isEdditing !== addingOrEditing.edditing || props.paqueteriaEditForm.idBill > 0) &&
                            <>
                                <div className="form-group col-md-1">
                                </div>
                                <div className="form-group col-md-2">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        autoComplete="off"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={props.paqueteriaForm.datosEconomicos.reexpedicion}
                                        className="form-control undefined"
                                        renderText={value =>
                                            <span>
                                                <label
                                                    style={{fontWeight: 'bold'}}
                                                    htmlFor="name"
                                                >
                                                    Re-expedición
                                                </label>
                                                <input
                                                    className="form-control undefined"
                                                    name="importeFormPaq"
                                                    disabled={true}
                                                    type="text"
                                                    value={value}
                                                />
                                            </span>
                                        }
                                        suffix={" \u20AC"}
                                    />
                                </div>
                                <div className="form-group col-md-2">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        autoComplete="off"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={props.paqueteriaForm.datosEconomicos.descuento}
                                        className="form-control undefined"
                                        renderText={value =>
                                            <span>
                                                <label
                                                    style={{fontWeight: 'bold'}}
                                                    htmlFor="name"
                                                >
                                                    Descuento (%)
                                                </label>
                                                <input
                                                    className="form-control undefined"
                                                    name="importedescuentoPaq"
                                                    disabled={true}
                                                    type="text"
                                                    value={value}
                                                />
                                            </span>
                                        }
                                        suffix={" %"}
                                    />
                                </div>
                                <div className="form-group col-md-2">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        autoComplete="off"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={props.paqueteriaForm.datosEconomicos.subTotal}
                                        className="form-control undefined"
                                        renderText={value =>
                                            <span>
                                                <label>
                                                    <span style={{fontWeight: 'bold'}}>Importe</span>
                                                    {props.paqueteriaForm.datosEconomicos.subTotalOriginal > 0 &&
                                                        <span>{" (agrupada)"}</span>}
                                                </label>
                                                <input
                                                    className="form-control undefined"
                                                    name="importedescuentoPaq"
                                                    disabled={true}
                                                    type="text"
                                                    value={
                                                        value + (props.paqueteriaForm.datosEconomicos.subTotalOriginal > 0 ?
                                                            (" (original: " +
                                                                props.paqueteriaForm.datosEconomicos.subTotalOriginal.toLocaleString('es-ES', {
                                                                    minimumFractionDigits: 2,  // Asegura que siempre haya dos decimales
                                                                    maximumFractionDigits: 2   // Asegura que no haya más de dos decimales
                                                                }) + " €)") : "")
                                                    }
                                                />
                                            </span>
                                        }
                                        suffix={" \u20AC"}
                                    />
                                </div>

                                {(props.paqueteriaForm.datosGenerales.supplier._id && props.paqueteriaForm.datosGenerales.supplier._id.length > 0) &&
                                    <div className="form-group col-md-2">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            displayType="text"
                                            autoComplete="off"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={props.paqueteriaForm.datosEconomicos.subTotalProveedor}
                                            className="form-control undefined"
                                            renderText={value =>
                                                <span>
                                                    <label
                                                        style={{fontWeight: 'bold'}}
                                                        htmlFor="name"
                                                    >
                                                        Importe SubContrata
                                                    </label>
                                                    <input
                                                        className="form-control undefined"
                                                        name="importeSubContrata"
                                                        disabled={true}
                                                        type="text"
                                                        value={value}
                                                    />
                                                </span>
                                            }
                                            suffix={" \u20AC"}
                                        />
                                    </div>
                                }

                                <div className="form-group col-md-1">
                                </div>
                            </>
                        }
                    </div>

                    <div className="form-row mt-3">
                        <div className="form-group col-md-8">
                            <PaqueteriaIncidencesList
                                from="paq"
                                isEdditing={props.paqueteriaEditForm.idBill > 0 ? addingOrEditing.none : props.isEdditing}
                                idForm={props.paqueteriaEditForm._id ? props.paqueteriaEditForm._id : ""}
                                listadoIncidencias={props.paqueteriaEditForm.incidenciasEntregas}
                                setListadoIncidencias={(incidencias) => {
                                    // Calcula la suma de los importes de un array de incidencias
                                    const sumarImportes = (incidencias: incidenciasEntregas[]): number => {
                                        return incidencias.reduce((acumulado, incidencia) => acumulado + incidencia.value, 0);
                                    };
                                    // Calcula la diferencia total de importe entre dos conjuntos de incidencias
                                    const calcularDiferenciaTotalImporte = (originales: incidenciasEntregas[], nuevas: incidenciasEntregas[]): number => {
                                        const sumaOriginales = sumarImportes(originales);
                                        const sumaNuevas = sumarImportes(nuevas);
                                        return sumaNuevas - sumaOriginales;
                                    };

                                    const diferenciaTotalImporte = calcularDiferenciaTotalImporte(props.paqueteriaEditForm.incidenciasEntregas, incidencias);
                                    const newImporte = props.paqueteriaEditForm.datosEconomicos.subTotal + diferenciaTotalImporte
                                    const newPaquet: paqueteria = {
                                        ...props.paqueteriaEditForm,
                                        incidenciasEntregas: incidencias,
                                        datosEconomicos: {
                                            ...props.paqueteriaEditForm.datosEconomicos,
                                            subTotal: newImporte
                                        }
                                    }
                                    props.saveFormPaqueteria(newPaquet)
                                    calculateTariff(newPaquet, "form")
                                }}
                            />
                        </div>

                        {(props.paqueteriaEditForm.datosTransporte.cmrInfo) &&
                            <div className="form-group col-md-4">
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginRight: "20px"
                                }}>
                                    {(props.isEdditing === addingOrEditing.edditing) && (
                                        <img
                                            src={
                                                (
                                                    props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign &&
                                                    props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign.length > 0
                                                )
                                                    ? cmrImages.editSignedCmr
                                                    : cmrImages.editUnsignedCmr
                                            }
                                            style={{
                                                width: '32px',
                                                height: '40px',
                                                marginRight: '10px',
                                                cursor: "pointer"
                                            }}
                                            alt="Icono"
                                            onClick={(event) => {
                                                setModalCmrOpen(true)
                                            }}
                                        />
                                    )}
                                    <img
                                        src={
                                            (
                                                props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign &&
                                                props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign.length > 0
                                            )
                                                ? cmrImages.downloadSignedCmr
                                                : cmrImages.downloadUnsignedCmr
                                        }
                                        style={{
                                            width: '32px',
                                            height: '38px',
                                            cursor: "pointer"
                                        }}
                                        alt="Icono"
                                        onClick={(event) => {
                                            const newCmr = props.paqueteriaEditForm.datosTransporte.cmrInfo
                                            if (newCmr) {
                                                dispatch(downloadCMR(newCmr))
                                            }
                                        }}
                                    />

                                    {
                                        (
                                            props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign &&
                                            props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.sign.length > 0
                                        ) &&
                                        <>
                                            <img
                                                src={require('../../assets/location-2952.png')}
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: "pointer",
                                                    marginLeft: "10px"
                                                }}
                                                alt="Icono"
                                                onClick={() => {
                                                    let url = ""
                                                    if (props.paqueteriaEditForm.datosTransporte.cmrInfo &&
                                                        props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign &&
                                                        props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signPosition
                                                    ) {
                                                        url = getCoordinatesGoogleURL(
                                                            props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signPosition.coords.latitude,
                                                            props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signPosition.coords.longitude,
                                                        )
                                                    }
                                                    window.open(url, '_blank')
                                                }}
                                            />

                                            <div style={{
                                                fontSize: '16px',
                                                marginLeft: "6px"
                                            }}>
                                                {(props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signTime) &&
                                                    new Date(props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signTime).toLocaleDateString('es-ES', {
                                                        day: '2-digit',
                                                        month: '2-digit'
                                                    }) + ' ' +
                                                    new Date(props.paqueteriaEditForm.datosTransporte.cmrInfo.destinatarioSign.signTime).toLocaleTimeString('es-ES', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false
                                                    })
                                                }
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        }
                    </div>


                    {/* MODAL PARA SUBIR ARCHIVOS */}
                    <Modal show={modalFilesState} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Añadir ficheros a albarán (*.pdf, *.tif)</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(props.paqueteriaEditForm.datosEntrega.qrIdReaded == "") &&
                                <div className="form-group ml-4 mt-4 mb-0">
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck"
                                            checked={markDelivered}
                                            onChange={() => {
                                                setMarkDelivered(!markDelivered)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck">Marcar como
                                            entregado</label>
                                    </div>
                                </div>
                            }
                            <UploadFile
                                onFileUpload={onFileUpload}
                                setFilesOnBase64={setBase64Files}
                                colWidth="12"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    {(props.paqueteriaEditForm.datosTransporte.cmrInfo) &&
                        <ModalCmr
                            modalOpened={modalCmrOpen}
                            setModalOpened={setModalCmrOpen}
                            cmrInfo={props.paqueteriaEditForm.datosTransporte.cmrInfo}
                            setCmrInfo={(infoToCmr) => {
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        cmrInfo: infoToCmr,
                                    }
                                });
                            }}
                            reInitCmr={() => {
                                props.saveFormPaqueteria({
                                    ...props.paqueteriaEditForm,
                                    datosTransporte: {
                                        ...props.paqueteriaEditForm.datosTransporte,
                                        cmrInfo: getInfoToCmrForPaqueteria(props.paqueteriaEditForm, "init", company)
                                    }
                                });
                            }}
                        />
                    }

                </div>

                <ReactTooltip/>

            </div>

        </Fragment>

    );
};

export default PaqueteriaFormData;