import React, { Fragment, useEffect, useState } from "react";
import LeftMenu from "../../../src/common/LeftMenu/LeftMenu";
import TopMenu from "../../../src/common/TopMenu/TopMenu";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { ReactElement } from "react";
import ControlPanel from "../ControlPanel/ControlPanel";
import Clients from "../Clients/Clients";
import Vehicles from "../Vehicles/Vehicles";
import Company from "../Company/Company";
import Employees from "../Employees/Employees";
import CargaCompleta from "../CargaCompleta/CargaCompleta";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import Paqueteria from "../Paqueteria/Paqueteria";
import { requestCompany } from "../Company/CompanyStore";
import Suppliers from "../Suppliers/Suppliers";
import VehiclesControles from "../VehiclesControls/VehiclesControls";
import Parametrizacion from "../../components/Parametrizacion/Parametrizacion";
import { requestParametrizacion } from "../Parametrizacion/ParametrizacionStore";
import ReviRepa from "../VehiclesRevisionesReparaciones/ReviRepa";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import MisDatosForm from "../MisDatos/MisDatosForm";
import Bills from "../Bills/Bills";
import UploadScannedAlbaranes from "../UploadScannedAlbaranes/UploadScannedAlbaranes";
import GetRemesaXML from "../RemesaXML/GetRemesaXML";
import {
    GetFirstRouteEnabled, GetSuscriptionDataFromLocalStorage,
    GetUserPermissionsFromLocalStorage
} from "../../common/commonInterfaces/commonFunctions";
import { UserPermissions, usersTypes, suscriptionData } from "aseguisShared";
import Abonos from "../Abonos/Abono";
import Swal from "sweetalert2";
import googlePlayLogo from './../../assets/googlePlay.png';
import appStoreLogo from './../../assets/appStore.png';
import asaeguisLogo from './../../assets/logoSoloAseguis.png';
import ScrollToTop from "../../common/goTop/ScrollToTop";

//DETECCIÓN DE NAVEGADOR Y MOVILES
export const isChrome = /Chrome/.test(navigator.userAgent) && !/Edge|Edg|OPR/.test(navigator.userAgent);
export const isEdge = /Edg\//.test(navigator.userAgent);
export const isMobile = /Android.+mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isTablet = /iPad|Android/i.test(navigator.userAgent) && !/mobile/i.test(navigator.userAgent);

function Admin(): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const history = useHistory();

    let { permissions, userType } = GetUserPermissionsFromLocalStorage()
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);
    const primaryColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.primaryColor);
    const secondColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.secondColor);

    let [primaryColor, setPrimaryColor] = useState(localStorage.getItem('firstColor') || "");
    let [secondColor, setSecondColor] = useState(localStorage.getItem('secondColor') || "");
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (primaryColorState && primaryColorState.length > 0) {
            setPrimaryColor(primaryColorState)
        }
    }, [primaryColorState])

    useEffect(() => {
        if (secondColorState && secondColorState.length > 0) {
            setSecondColor(secondColorState)
        }
    }, [secondColorState])

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    const showAlert = localStorage.getItem('noShowAlert') !== 'true';
    useEffect(() => {
        const chromeText =
            'Para garantizar el máximo rendimiento, recomendamos utilizar Google Chrome. ' +
            'Otros navegadores pueden limitar la funcionalidad';
        if (showAlert && !isChrome && !isMobile && !isTablet) {
            Swal.fire({
                title: 'Optimizado para Chrome',
                text: chromeText,
                icon: 'warning',
                confirmButtonText: 'Entendido',
                confirmButtonColor: '#0275d8'
            }).then((result) => {
                localStorage.setItem('noShowAlert', 'true');
            });
        }
    }, []);

    useEffect(() => {
        dispatch(requestParametrizacion());
        dispatch(requestCompany());
    }, []);

    /******************** CARGAMOS COLOR BARRA SCROLL *************************/
    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`
      ::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }
    `, styleSheet.cssRules.length);

        styleSheet.insertRule(`
      ::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
      }
    `, styleSheet.cssRules.length);

        styleSheet.insertRule(`
      ::-webkit-scrollbar-thumb {
        background-color: ${primaryColor};
      }
    `, styleSheet.cssRules.length);
    }, [primaryColor]);
    /******************** CARGAMOS COLOR BARRA SCROLL *************************/

    if (isMobile) {
        return (
            <div style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                fontSize: '16px',
                color: '#333',
                textAlign: 'center',
                backgroundColor: '#f7f7f7'
            }}>
                <img src={asaeguisLogo} alt="App Store" style={{ width: '150px', height: 'auto', marginBottom: "30px" }} />
                <p style={{ margin: '5px 0' }}>Utiliza <span style={{ fontWeight: "bold" }}> Aseguis </span> en un ordenador o tablet</p>
                <p style={{ margin: '25px 0' }}>También puedes descargar nuestra aplicación:</p>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    flexWrap: 'wrap'
                }}>
                    <a href="https://apps.apple.com/es/app/aseguis/id6468611846" target="_blank">
                        <img src={appStoreLogo} alt="App Store" style={{ width: '180px', height: 'auto' }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.tspmobile&hl=es&gl=US" target="_blank">
                        <img src={googlePlayLogo} alt="Google Play" style={{ width: '225px', height: 'auto' }} />
                    </a>
                </div>
                <button
                    onClick={() => {
                        localStorage.clear()
                        history.push(allAppRoutes.login)
                    }}
                    style={{
                        marginTop: "50px",
                        width: "200px",
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#fff',
                        backgroundColor: '#007bff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    ¡OK!
                </button>
            </div>
        );
    }

    return (
        <Fragment>

            <div className="App" id="wrapper" style={{ borderRight: `1px solid ${primaryColor}` }}>

                <LeftMenu />

                <div
                    id="content-wrapper"
                    className="d-flex flex-column "
                    style={{ backgroundColor: secondColor }}
                >

                    <TopMenu />

                    <div
                        className="container-fluid"
                        id="containerFluid"
                        style={{ paddingTop: "14px"}}
                    >

                        <ScrollToTop/>
                        
                        <Switch>

                            {/* PANEL DE CONTROL*/}
                            {(permissions.panel !== UserPermissions.deshabilitado) &&
                                <Route exact path={allAppRoutes.leftMenuPanel}> <ControlPanel /> </Route>
                            }

                            {/* COMPANIA */}
                            {(permissions.compania !== UserPermissions.deshabilitado) &&
                                <Route path={allAppRoutes.leftMenuCompany}> <Company /></Route>
                            }

                            {/* EMPLOYEES */}
                            {permissions.employees !== UserPermissions.deshabilitado &&
                                <Route path={allAppRoutes.leftMenuEmployees}><Employees /></Route>
                            }

                            {/* SUPPLIERS */}
                            {(permissions.proveedores !== UserPermissions.deshabilitado && suscriptionData.proveedoresTransportes) &&
                                <Route path={allAppRoutes.leftMenuSuppliers}><Suppliers /></Route>
                            }

                            {/* AJUSTES */}
                            {permissions.isAdmin === true &&
                                <Route path={allAppRoutes.leftMenuSettings}> <Parametrizacion /></Route>
                            }

                            {/* VEHICLES */}
                            {permissions.vehicles !== UserPermissions.deshabilitado &&
                                <Route path={allAppRoutes.leftMenuVehicles}><Vehicles /></Route>
                            }
                            {(permissions.vehiclesControls !== UserPermissions.deshabilitado && suscriptionData.controlesVehiculos) &&
                                <Route path={allAppRoutes.leftMenuVehiclesControls}><VehiclesControles /></Route>
                            }
                            {(permissions.vehiclesReviRepa !== UserPermissions.deshabilitado && suscriptionData.reviRepa) &&
                                <Route path={allAppRoutes.leftMenuVehiclesReviRepa}> <ReviRepa /></Route>
                            }

                            {/* CLIENTES  */}
                            {(permissions.clients !== UserPermissions.deshabilitado) &&
                                <Route path={allAppRoutes.leftMenuClients}> <Clients /> </Route>
                            }

                            {/* TRANSPORTES  */}
                            {permissions.cargaCompleta !== UserPermissions.deshabilitado &&
                                <Route path={allAppRoutes.leftMenuCargaCompleta}> <CargaCompleta /> </Route>
                            }
                            {(permissions.paqueteria !== UserPermissions.deshabilitado && suscriptionData.paqueteria) &&
                                <Route path={allAppRoutes.leftMenuPaqueteria}> <Paqueteria /> </Route>
                            }
                            {(permissions.paqueteria === UserPermissions.modificar && permissions.cargaCompleta === UserPermissions.modificar && userType === usersTypes.employee && suscriptionData.entregasMasivas) &&
                                <Route exact path={allAppRoutes.leftMenuScan}><UploadScannedAlbaranes /></Route>
                            }

                            {/* FACTURAS  */}
                            {permissions.bills !== UserPermissions.deshabilitado &&
                                <Route path={allAppRoutes.leftMenuBills}><Bills /></Route>
                            }
                            {(userType === usersTypes.employee && suscriptionData.remesas) &&
                                <Route exact path={allAppRoutes.leftMenuRemesa}><GetRemesaXML /></Route>
                            }
                            {permissions.bills !== UserPermissions.deshabilitado &&
                                <Route path={allAppRoutes.leftMenuAbonos}><Abonos /></Route>
                            }

                            {/* MIS DATOS  */}
                            <Route exact path={allAppRoutes.myUser}> <MisDatosForm /> </Route>

                            {/* SI NO ES NINGUNO, REDIRECCIONAMOS AL PANEL */}
                            <Redirect from="*" to={GetFirstRouteEnabled()} />

                        </Switch>

                    </div>

                </div>

            </div>

        </Fragment>
    )
};

export default Admin;