"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicialiceEmployeeValidateFieldsResult = exports.inicialiceEmployee = void 0;
const commonFunctions_1 = require("./commonFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
exports.inicialiceEmployee = {
    _id: null,
    id: 0,
    fecAlta: new Date(),
    fecAntiguedad: new Date(),
    fecNacimi: new Date(),
    name: "",
    apellido1: "",
    apellido2: "",
    address: commonFunctions_1.inicialiceAddress,
    tel1: "",
    tel2: "",
    email: "",
    isChofer: false,
    nif: "",
    vtoCap: new Date(),
    permisosUsuario: commonFunctions_1.inicialicePermisosUsuario,
    numSegSocial: "",
    vtoPrevencion: new Date(),
    vtoRevMedica: new Date(),
    vtoTacografo: new Date(),
    assignedVehicle: vehiclesFunctions_1.initialVehicle,
    assignedRemolque: vehiclesFunctions_1.initialVehicle,
    observaciones: ""
};
exports.inicialiceEmployeeValidateFieldsResult = {
    nifExists: false,
    emailExists: false,
    tel1Exists: false,
    tel2Exists: false,
    numSegSocialExists: false,
    anyFieldExist: false
};
