import React, {Fragment, ReactElement, useEffect, ChangeEvent} from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from '../../../ApplicationState';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import FormTrayectoList from "./FormTrayectoList";
import {
    company, vehicleType, lineaViajeCarga, inicialiceDestinationsLinea, inicializeLineaViajeCarga, calculateIva,
    inicialiceCargaCompleta, cargaCompleta, tariffCargaCompleta, validateNumberFormEmpty, addingOrEditing, supplier
} from 'aseguisShared';

interface infoToFormTrayecto {
    isEdditing: number;
    addOrEdit: number,
    saveLineaTrayecto: (lineaDestino: lineaViajeCarga) => void
    lineaTrayecto: lineaViajeCarga
    saveExistingLinea: () => void
    addLinea: () => void
    vehicleType: vehicleType
    trayectosClient: tariffCargaCompleta[]
    trayectosCompany: tariffCargaCompleta[]
    trayectosProveedor: tariffCargaCompleta[]
    supplier: supplier
}

function FormTrayecto(props: infoToFormTrayecto): ReactElement {

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm || inicialiceCargaCompleta);
    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva

    const [errorForm, setErrorForm] = React.useState(false);
    const [ImportTrayecto, setImportTrayecto] = React.useState(props.lineaTrayecto.importe);

    useEffect(() => {
        props.saveLineaTrayecto(props.lineaTrayecto)
    }, [props.vehicleType]);

    useEffect(() => {
        //si hay error en algun sitio, grabamos error general
        if (
            props.lineaTrayecto.importe <= 0 ||
            (
                props.lineaTrayecto.destinations[0] &&
                props.lineaTrayecto.destinations[0].address.calle.length < 2 &&
                props.lineaTrayecto.destinations[0].destName.length < 2 &&
                props.lineaTrayecto.destinations[0].address.placeId.length < 2
            )
            ||
            (
                props.lineaTrayecto.destinations[1] &&
                props.lineaTrayecto.destinations[1].address.calle.length < 2 &&
                props.lineaTrayecto.destinations[1].destName.length < 2 &&
                props.lineaTrayecto.destinations[0].address.placeId.length < 2
            )
        ) {
            setErrorForm(true)
        } else {
            setErrorForm(false)
        }
    }, [props.lineaTrayecto]);

    useEffect(() => {
        // si la longitud es 0, creamos las dos instancias, para origen y destino
        if (props.lineaTrayecto.destinations.length === 0) {
            props.saveLineaTrayecto({
                ...props.lineaTrayecto,
                destinations: [...props.lineaTrayecto.destinations, {...inicialiceDestinationsLinea}, {...inicialiceDestinationsLinea}]
            })
        }
    }, [])

    const calculateDiscount = (value: number) => {
        const importToDiscount = (ImportTrayecto * value) / 100;
        const newSubtotal = ImportTrayecto - importToDiscount;
        const newIVA = calculateIva(newSubtotal, porIva)
        const newTotal = newSubtotal + newIVA
        props.saveLineaTrayecto({
            ...props.lineaTrayecto,
            descuento: value,
            importe: newSubtotal,
            importeOriginal: ImportTrayecto,
            total: newIVA,
            iva: newTotal
        })
    }

    const calculateNewImport = (value: number) => {
        const newIVA = calculateIva(value, porIva)
        const newTotal = value + newIVA
        props.saveLineaTrayecto({
            ...props.lineaTrayecto,
            descuento: 0,
            importe: value,
            importeOriginal: value,
            total: newIVA,
            iva: newTotal
        })
    }

    return (
        <Fragment>

            <div className="card-body">

                <div>

                    <FormTrayectoList
                        vehicleType={props.vehicleType}
                        lineaTrayecto={props.lineaTrayecto}
                        trayectosClient={props.trayectosClient}
                        trayectosCompany={props.trayectosCompany}
                        trayectosProveedor={props.trayectosProveedor}
                        selectTrayecto={(selectedTrayecto, trayectoProveedor) => {
                            const newIVA = calculateIva(selectedTrayecto.value, porIva)
                            const newTotal = selectedTrayecto.value + newIVA
                            props.saveLineaTrayecto({
                                ...props.lineaTrayecto,
                                destinations: props.lineaTrayecto.destinations.map((dest, index) => {
                                    if (index === 0) {
                                        return {
                                            ...dest,
                                            cargaDescarga: "Origen",
                                            address: selectedTrayecto.origin.address,
                                            destName: selectedTrayecto.origin.name
                                        }
                                    }
                                    if (index === 1) {
                                        return {
                                            ...dest,
                                            cargaDescarga: "Destino",
                                            address: selectedTrayecto.destiny.address,
                                            destName: selectedTrayecto.destiny.name
                                        }
                                    }
                                    return dest;
                                }),
                                importe: selectedTrayecto.value,
                                descuento: 0,
                                iva: newIVA,
                                total: newTotal,
                                importeProveedor: trayectoProveedor.value,
                            })

                            setImportTrayecto(selectedTrayecto.value)
                        }}
                    />

                    &nbsp;

                    <div className="form-row">
                        <div className="form-group col-md-7">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="name"> Otros conceptos </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="concepto"
                                        type="text"
                                        value={props.lineaTrayecto.concepto}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            props.saveLineaTrayecto({
                                                ...props.lineaTrayecto,
                                                concepto: event.target.value.trimStart(),
                                            })
                                        }}
                                        className="form-control"
                                    />
                                    : <div>{props.lineaTrayecto.concepto}</div>}
                            </div>
                        </div>

                        <div className="form-group col-md-1">
                        </div>

                        <div className="form-group col-md-1" style={{marginTop: '8px'}}>
                            <div> Descuento (%):</div>
                            <div style={{marginTop: "14px"}}> Importe:</div>
                            {(props.supplier._id && props.supplier._id.length > 0) &&
                                <div style={{marginTop: "14px"}}> SubContrata:</div>
                            }
                        </div>

                        <div className="form-group col-md-2">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix={" %"}
                                value={props.lineaTrayecto.descuento}
                                className="form-control undefined"
                                isAllowed={(value: NumberFormatValues) => {
                                    const {floatValue} = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    if (numberValue) {
                                        return numberValue < 100;
                                    } else return true
                                }}
                                onValueChange={(value, sourceInfo) => {
                                    const {floatValue} = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    //solo si cambia por el evento, actualizamos
                                    if (sourceInfo.source === "event") {
                                        calculateDiscount(numberValue)
                                    }
                                }}
                            />
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                onValueChange={(value, sourceInfo) => {
                                    const {floatValue} = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    //solo si cambia por el evento, actualizamos
                                    if (sourceInfo.source === "event") {
                                        calculateNewImport(numberValue)
                                        setImportTrayecto(numberValue)
                                    }
                                }}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={props.lineaTrayecto.importe}
                                className={validateNumberFormEmpty(props.lineaTrayecto.importe, 1)}
                                suffix={" \u20AC"}
                            />
                            {(props.supplier._id && props.supplier._id.length > 0) &&
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    onValueChange={(value, sourceInfo) => {
                                        props.saveLineaTrayecto({
                                            ...props.lineaTrayecto,
                                            importeProveedor: value.floatValue ? value.floatValue : 0
                                        })
                                    }}
                                    fixedDecimalScale={true}
                                    allowNegative={true}
                                    value={props.lineaTrayecto.importeProveedor}
                                    className="form-control undefined"
                                    renderText={value => <div
                                        style={{fontWeight: "bold", marginTop: "14px"}}>{value}</div>}
                                    suffix={" \u20AC"}
                                />
                            }
                        </div>

                    </div>

                </div>

                <br></br>

                {props.isEdditing === addingOrEditing.edditing &&
                    <div className="form-row">

                        {/* LIMPIAR FORMULARIO LINEA VIAJE*/}
                        <div className="form-group col-md-6">
                            <button className="btn btn-info btn-sm"
                                    onClick={() => {
                                        props.saveLineaTrayecto(inicializeLineaViajeCarga)
                                    }}>
                                Limpiar formulario
                            </button>
                        </div>

                        <div className="form-group col-md-6 right">
                            {/* GUARDAR FILA EDITADA A VIAJES*/}
                            {props.lineaTrayecto.id > 0 &&
                                <button
                                    className={errorForm === false ? "btn btn-info btn-sm left-margin" : "btn btn-info btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (errorForm === false) {
                                            props.saveExistingLinea()
                                            props.saveLineaTrayecto(inicializeLineaViajeCarga)
                                        }
                                    }}>
                                    Aplicar cambios
                                </button>
                            }

                            {/* ANADIR FILA A VIAJES*/}
                            <button
                                className={errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (errorForm === false) {
                                        props.addLinea()
                                        props.saveLineaTrayecto(inicializeLineaViajeCarga)
                                    }
                                }}>
                                Añadir fila
                            </button>

                        </div>

                    </div>
                }

            </div>

        </Fragment>

    );
};

export default FormTrayecto;