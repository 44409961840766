import React, {Fragment, ReactElement} from "react";
import NumberFormat from 'react-number-format';
import {downloadCMR} from "./CargaCompletaStore";
import {
    formatedDirWithName, addingOrEditing, formattLineType, cargaCompleta,
    company, tariffsTypes, lineaViajeCarga, getInfoToCmrForCarga,
    infoToCreateCMR, initInfoToCreateCMR,
    destinationsLinea, getCoordinatesGoogleURL, usersTypes
} from "aseguisShared";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../ApplicationState";
import {calculateConceptoHoras} from "./CargaCompletaForms/FormHoras";
import ModalCmr from "../../common/ModalCmr/modalCmr";
import ImageCmrDrag from "../../common/ModalCmr/imageCmrDrag";
import {GetUserPermissionsFromLocalStorage} from "../../common/commonInterfaces/commonFunctions";

export const cmrImages = {
    downloadSignedCmr: require('../../assets/cmr_download_signed.png'),
    downloadUnsignedCmr: require('../../assets/cmr_download_unsigned2.png'),

    editSignedCmr: require('../../assets/cmr_icon_signed.png'),
    editUnsignedCmr: require('../../assets/cmr_icon_unsigned.png'),
};

interface infoToCargaCompletaViajesList {
    isEdditing: number;
    cargaForm: cargaCompleta;
    cargaEditForm: cargaCompleta;
    lineaViaje: lineaViajeCarga;
    saveFormCarga: (cargaCompleta: cargaCompleta) => void;
    saveLineaViaje: (lineaViaje: lineaViajeCarga) => void;
    recalculate: () => void;
}

function CargaCompletaViajesList(props: infoToCargaCompletaViajesList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const {userType, permissions} = GetUserPermissionsFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const [modalCmrOpen, setModalCmrOpen] = React.useState(false)
    const [indexLineaCmr, setIndexLineaCmr] = React.useState(0)
    const [indexDestCmr, setIndexDestCmr] = React.useState(0)

    const getCmrImages = (dest: destinationsLinea) => {
        if (dest.cargaDescarga !== "Descarga" && dest.cargaDescarga !== "Destino") {
            if (dest.cmrInfo && dest.cmrInfo.remitenteSign.sign && dest.cmrInfo.remitenteSign.sign.length > 0) {
                return ({downloadCmr: cmrImages.downloadSignedCmr, editCmr: cmrImages.editSignedCmr, signed: true});
            }
        } else if (dest.cargaDescarga === "Descarga" || dest.cargaDescarga === "Destino") {
            if (dest.cmrInfo && dest.cmrInfo.destinatarioSign.sign && dest.cmrInfo.destinatarioSign.sign.length > 0) {
                return ({downloadCmr: cmrImages.downloadSignedCmr, editCmr: cmrImages.editSignedCmr, signed: true});
            }
        }
        return ({downloadCmr: cmrImages.downloadUnsignedCmr, editCmr: cmrImages.editUnsignedCmr, signed: false});
    };

    const showDistanciaLine = (linViaje: lineaViajeCarga, isEdit: boolean) => {
        return (
            <td onClick={() => {
            }}>
                <table className="table-sm table-bordered" style={{width: "100%", position: "static"}}>
                    <tbody>
                    {linViaje.destinations.map((dest, index) => (
                        <tr className={`table-row`} key={index} style={{height: 60}}>
                            <td
                                style={{
                                    verticalAlign: 'middle',
                                    whiteSpace: "nowrap"
                                }}
                                onClick={() => {
                                    if (isEdit) {
                                        props.saveLineaViaje(linViaje)
                                    }
                                }}
                            >
                                {dest.cargaDescarga}
                            </td>
                            <td
                                style={{
                                    verticalAlign: 'middle',
                                    whiteSpace: "nowrap"
                                }}
                                onClick={() => {
                                    if (isEdit) {
                                        props.saveLineaViaje(linViaje)
                                    }
                                }}
                            >
                                {formatedDirWithName(dest.address, dest.destName)}
                            </td>

                            {index > 0 && linViaje.destinationsDistances[index - 1] ?
                                <td
                                    style={{
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        whiteSpace: "nowrap"
                                    }}
                                    onClick={() => {
                                        if (isEdit) {
                                            props.saveLineaViaje(linViaje)
                                        }
                                    }}
                                >
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={(linViaje.destinationsDistances[index - 1].rows[0]?.elements[0]?.distance?.value / 1000) || 0}
                                        className="form-control undefined"
                                        renderText={value => <div>{value}</div>}
                                        suffix={" km"}
                                    />
                                </td>
                                :
                                linViaje.lineType === tariffsTypes.distancia ?
                                    <td style={{whiteSpace: "nowrap"}}
                                        onClick={() => {
                                            if (isEdit) {
                                                props.saveLineaViaje(linViaje)
                                            }
                                        }}
                                    >
                                    </td>
                                    : null
                            }

                            <td
                                style={{
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    whiteSpace: "nowrap",
                                    alignItems: "center"
                                }}
                            >
                                {(dest.cargaDescarga.length > 0 && dest.cmrInfo) &&
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: "center",
                                        gap: '5px'
                                    }}>
                                        {(props.isEdditing === addingOrEditing.edditing) &&
                                            <ImageCmrDrag
                                                imageCmr={getCmrImages(dest).editCmr}
                                                indexLinea={props.cargaEditForm.lineasViaje.findIndex(lineaViaje => lineaViaje.id === linViaje.id)}
                                                indexDestination={index}
                                                onCopyCMRInfo={(indexLineaOrigen, indexDestiOrigen, indexLineaDestino, indexDestiDestino) => {
                                                    let newCargaWithCmrDestinoUpdated: cargaCompleta = {...props.cargaEditForm};
                                                    if (newCargaWithCmrDestinoUpdated.lineasViaje[indexLineaOrigen].destinations[indexDestiOrigen].cmrInfo && newCargaWithCmrDestinoUpdated.lineasViaje[indexLineaDestino].destinations[indexDestiDestino].cmrInfo) {
                                                        const origenCmrInfo = newCargaWithCmrDestinoUpdated.lineasViaje[indexLineaOrigen].destinations[indexDestiOrigen].cmrInfo!;
                                                        const destinoCmrInfo = newCargaWithCmrDestinoUpdated.lineasViaje[indexLineaDestino].destinations[indexDestiDestino].cmrInfo!;
                                                        const cmrOrigenWithoutSigns: infoToCreateCMR = {
                                                            ...origenCmrInfo,
                                                            remitenteSign: destinoCmrInfo.remitenteSign,
                                                            destinatarioSign: destinoCmrInfo.destinatarioSign,
                                                        };
                                                        newCargaWithCmrDestinoUpdated.lineasViaje[indexLineaDestino].destinations[indexDestiDestino].cmrInfo = cmrOrigenWithoutSigns;
                                                        props.saveFormCarga(newCargaWithCmrDestinoUpdated);
                                                    }
                                                }}
                                                onClick={() => {
                                                    const indexLineaViaje = props.cargaEditForm.lineasViaje.findIndex(lineaViaje => lineaViaje.id === linViaje.id);
                                                    if (indexLineaViaje >= 0) {
                                                        setIndexLineaCmr(indexLineaViaje)
                                                        setIndexDestCmr(index)
                                                        setModalCmrOpen(true)
                                                    }
                                                }}
                                            />
                                        }
                                        <img
                                            src={getCmrImages(dest).downloadCmr}
                                            style={{
                                                width: '32px',
                                                height: '38px',
                                                cursor: "pointer"
                                            }}
                                            alt="Icono"
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                const indexLineaViaje = props.cargaEditForm.lineasViaje.findIndex(lineaViaje => lineaViaje.id === linViaje.id);
                                                if (indexLineaViaje >= 0) {
                                                    const newCmr = dest.cmrInfo
                                                    if (newCmr) {
                                                        dispatch(downloadCMR(newCmr))
                                                    }
                                                }
                                            }}
                                        />

                                        <>
                                            {(getCmrImages(dest).signed) &&
                                                <>
                                                    <img
                                                        src={require('../../assets/location-2952.png')}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            cursor: "pointer",
                                                            marginLeft: "6px"
                                                        }}
                                                        alt="Icono"
                                                        onClick={() => {
                                                            let url = ""
                                                            if (dest.cmrInfo && dest.cargaDescarga !== "Descarga" && dest.cargaDescarga !== "Destino") {
                                                                if (dest.cmrInfo && dest.cmrInfo.remitenteSign && dest.cmrInfo.remitenteSign && dest.cmrInfo.remitenteSign.signPosition) {
                                                                    url = getCoordinatesGoogleURL(
                                                                        dest.cmrInfo.remitenteSign.signPosition.coords.latitude,
                                                                        dest.cmrInfo.remitenteSign.signPosition.coords.longitude,
                                                                    )
                                                                }
                                                            } else {
                                                                if (dest.cmrInfo && dest.cmrInfo.destinatarioSign && dest.cmrInfo.destinatarioSign && dest.cmrInfo.destinatarioSign.signPosition) {
                                                                    url = getCoordinatesGoogleURL(
                                                                        dest.cmrInfo?.destinatarioSign.signPosition.coords.latitude,
                                                                        dest.cmrInfo?.destinatarioSign.signPosition.coords.longitude,
                                                                    )
                                                                }
                                                            }
                                                            window.open(url, '_blank')
                                                        }}
                                                    />

                                                    <div style={{fontSize: '16px'}}>
                                                        {(dest.cmrInfo && dest.cargaDescarga !== "Descarga" && dest.cargaDescarga !== "Destino") ?
                                                            <>
                                                                {(dest.cmrInfo.remitenteSign && dest.cmrInfo.remitenteSign.signTime) &&
                                                                    new Date(dest.cmrInfo.remitenteSign.signTime).toLocaleDateString('es-ES', {
                                                                        day: '2-digit',
                                                                        month: '2-digit'
                                                                    }) + ' ' +
                                                                    new Date(dest.cmrInfo.remitenteSign.signTime).toLocaleTimeString('es-ES', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {(dest.cmrInfo.destinatarioSign && dest.cmrInfo.destinatarioSign.signTime) &&
                                                                    new Date(dest.cmrInfo.destinatarioSign.signTime).toLocaleDateString('es-ES', {
                                                                        day: '2-digit',
                                                                        month: '2-digit'
                                                                    }) + ' ' +
                                                                    new Date(dest.cmrInfo.destinatarioSign.signTime).toLocaleTimeString('es-ES', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </>

                                    </div>
                                }
                            </td>
                        </tr>
                    ))}
                    {linViaje.destinationsDistances.length > 1 &&
                        <tr className={`table-row`}>
                            <td style={{pointerEvents: 'none'}}>
                                {""}
                            </td>
                            <td scope="row" style={{
                                textAlign: 'right',
                                verticalAlign: 'middle',
                                whiteSpace: "nowrap",
                                pointerEvents: 'none'
                            }}>
                                {"Total km:"}
                            </td>
                            <td scope="row" style={{
                                pointerEvents: 'none',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                whiteSpace: "nowrap",
                            }}>
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={linViaje.totalDistance}
                                    className="form-control undefined"
                                    renderText={value => <div>{value}</div>}
                                    suffix={" km"}
                                />
                            </td>
                            <td style={{pointerEvents: 'none'}}>
                                {""}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
                {linViaje.concepto.length > 0 &&
                    <div style={{marginTop: "10px"}}>
                        {"- Otros conceptos: " + linViaje.concepto}
                    </div>
                }
            </td>
        )
    }


    const listEditCargas: JSX.Element[] = props.cargaEditForm.lineasViaje.map((linViaje, index) => {

        return (
            <tr className={`table-row ${(props.lineaViaje.id === linViaje.id) ? "selected" : ""}`}
                key={index}>
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }} onClick={() => {
                    props.saveLineaViaje(linViaje)
                }}>
                    {formattLineType(linViaje.lineType)}
                </td>

                {(linViaje.lineType === tariffsTypes.distancia || linViaje.lineType === tariffsTypes.poblacion || linViaje.lineType === tariffsTypes.trayecto) &&
                    showDistanciaLine(linViaje, true)
                }
                {(linViaje.lineType === tariffsTypes.horas) &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }} onClick={() => {
                        props.saveLineaViaje(linViaje)
                    }}>
                        <div style={{marginTop: "10px"}}>
                            {calculateConceptoHoras(linViaje)}
                            {linViaje.concepto.length > 0 &&
                                <>
                                    <br/>
                                    {"- Otros conceptos: " + linViaje.concepto}
                                </>
                            }
                        </div>
                    </td>
                }
                {(linViaje.lineType === tariffsTypes.otros) &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }} onClick={() => {
                        props.saveLineaViaje(linViaje)
                    }}>
                        <div style={{marginTop: "10px"}}>
                            {"- " + linViaje.concepto}
                        </div>
                    </td>
                }

                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }} onClick={() => {
                    props.saveLineaViaje(linViaje)
                }}>
                    <NumberFormat
                        renderText={value => <div>{value}</div>}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={" %"}
                        value={linViaje.descuento}
                        className="form-control undefined"
                    />
                </td>
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }} onClick={() => {
                    props.saveLineaViaje(linViaje)
                }}>
                    <b>
                        <NumberFormat
                            renderText={value => <div>{value}</div>}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            suffix={" \u20AC"}
                            value={linViaje.importe}
                            className="form-control undefined"
                        />
                    </b>
                </td>
                {
                    ((props.isEdditing === addingOrEditing.edditing && props.cargaEditForm.datosGenerales.supplier._id && props.cargaEditForm.datosGenerales.supplier._id.length > 0) ||
                        (props.isEdditing !== addingOrEditing.edditing && props.cargaForm.datosGenerales.supplier._id && props.cargaForm.datosGenerales.supplier._id.length > 0))
                    &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }} onClick={() => {
                        props.saveLineaViaje(linViaje)
                    }}>
                        <NumberFormat
                            renderText={value => <div>{linViaje.importeProveedor == 0 ? "-" : value}</div>}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            suffix={" \u20AC"}
                            value={linViaje.importeProveedor}
                            className="form-control undefined"
                        />
                    </td>
                }
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }} onClick={() => {
                    props.saveLineaViaje(linViaje)
                }}>
                    {(linViaje.lineType === tariffsTypes.distancia || linViaje.lineType === tariffsTypes.poblacion) ?
                        <span>{linViaje.cobrarRetorno}</span>
                        :
                        <span style={{color: "grey"}}>-</span>
                    }
                </td>
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }}>
                    <button className="btn btn-sm btn-success btn-red"
                            onClick={() => {
                                if (props.lineaViaje.id === linViaje.id) {
                                    props.saveLineaViaje({
                                        ...linViaje,
                                        lineType: ""
                                    });
                                }

                                props.saveFormCarga({
                                    ...props.cargaEditForm,
                                    lineasViaje: props.cargaEditForm.lineasViaje.filter(lineaviaje => lineaviaje.id !== linViaje.id)
                                });

                                props.recalculate()
                            }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>
            </tr>
        );
    });

    const listOriginalCargas: JSX.Element[] = props.cargaForm.lineasViaje.map((linViaje, index) => {
        return (
            <tr key={index}>
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }}>
                    {formattLineType(linViaje.lineType)}
                </td>
                {(linViaje.lineType === tariffsTypes.distancia || linViaje.lineType === tariffsTypes.poblacion || linViaje.lineType === tariffsTypes.trayecto) &&
                    showDistanciaLine(linViaje, false)
                }
                {(linViaje.lineType === tariffsTypes.horas) &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }}>
                        <div style={{marginTop: "10px"}}>
                            {calculateConceptoHoras(linViaje)}
                            {linViaje.concepto.length > 0 &&
                                <>
                                    <br/>
                                    {"- Otros conceptos: " + linViaje.concepto}
                                </>
                            }
                        </div>
                    </td>
                }
                {(linViaje.lineType === tariffsTypes.otros) &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }}>
                        <div style={{marginTop: "10px"}}>
                            {"- " + linViaje.concepto}
                        </div>
                    </td>
                }

                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }}>
                    <NumberFormat
                        renderText={value => <div>{value}</div>}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={" %"}
                        value={linViaje.descuento}
                        className="form-control undefined"
                    />
                </td>
                <td style={{
                    fontWeight: "bold",
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }}>
                    <NumberFormat
                        renderText={value => <div>{value}</div>}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        suffix={" \u20AC"}
                        value={linViaje.importe}
                        className="form-control undefined"
                    />
                </td>
                {
                    (
                        (
                            (props.isEdditing === addingOrEditing.edditing && props.cargaEditForm.datosGenerales.supplier._id && props.cargaEditForm.datosGenerales.supplier._id.length > 0) ||
                            (props.isEdditing !== addingOrEditing.edditing && props.cargaForm.datosGenerales.supplier._id && props.cargaForm.datosGenerales.supplier._id.length > 0)
                        )
                        && (userType === usersTypes.employee)
                    ) &&
                    <td style={{
                        verticalAlign: 'middle',
                        whiteSpace: "nowrap"
                    }} onClick={() => {
                        props.saveLineaViaje(linViaje)
                    }}>
                        <NumberFormat
                            renderText={value => <div>{linViaje.importeProveedor == 0 ? "-" : value}</div>}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            suffix={" \u20AC"}
                            value={linViaje.importeProveedor}
                            className="form-control undefined"
                        />
                    </td>
                }
                <td style={{
                    verticalAlign: 'middle',
                    whiteSpace: "nowrap"
                }}>
                    {(linViaje.lineType === tariffsTypes.distancia || linViaje.lineType === tariffsTypes.poblacion) ?
                        <span>{linViaje.cobrarRetorno}</span>
                        :
                        <span>-</span>
                    }
                </td>
            </tr>);
    });


    return (
        <Fragment>

            <div className="table-responsive table-hover portlet-800" key={props.isEdditing}>
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Concepto</th>
                        <th scope="col">Descuento</th>
                        <th scope="col">SubTotal</th>
                        {
                            (
                                (
                                    (props.isEdditing === addingOrEditing.edditing && props.cargaEditForm.datosGenerales.supplier._id && props.cargaEditForm.datosGenerales.supplier._id.length > 0) ||
                                    (props.isEdditing !== addingOrEditing.edditing && props.cargaForm.datosGenerales.supplier._id && props.cargaForm.datosGenerales.supplier._id.length > 0)
                                )
                                && (userType === usersTypes.employee)
                            ) &&
                            <th scope="col">SubContrata</th>
                        }
                        <th scope="col">Cobrar Retorno</th>
                        {(props.isEdditing === addingOrEditing.edditing) &&
                            <th scope="col">Eliminar</th>
                        }
                    </tr>
                    </thead>

                    {(props.isEdditing === addingOrEditing.edditing) &&
                        <tbody>
                        {listEditCargas}
                        </tbody>
                    }

                    {(props.isEdditing !== addingOrEditing.edditing) &&
                        <tbody>
                        {listOriginalCargas}
                        </tbody>
                    }

                </table>

            </div>

            <br></br>

            <div className="form-row">
                <div className="form-group col-md-9">
                </div>
                <div className="form-group col-md-3">
                    <div className=''>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={(props.isEdditing !== addingOrEditing.edditing ? props.cargaForm.datosEconomicos.subTotal : props.cargaEditForm.datosEconomicos.subTotal)}
                            className="form-control undefined"
                            renderText={value => <div style={{fontWeight: "bold"}}>{"Importe total: " + value}</div>}
                            suffix={" \u20AC"}
                        />
                        {
                            (
                                (
                                    (props.isEdditing === addingOrEditing.edditing && props.cargaEditForm.datosGenerales.supplier._id && props.cargaEditForm.datosGenerales.supplier._id.length > 0) ||
                                    (props.isEdditing !== addingOrEditing.edditing && props.cargaForm.datosGenerales.supplier._id && props.cargaForm.datosGenerales.supplier._id.length > 0)
                                )
                                && (userType === usersTypes.employee)
                            ) &&
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={true}
                                value={(props.isEdditing !== addingOrEditing.edditing ? props.cargaForm.datosEconomicos.subTotalProveedor : props.cargaEditForm.datosEconomicos.subTotalProveedor)}
                                className="form-control undefined"
                                renderText={value => {
                                    const importeCliente = props.isEdditing !== addingOrEditing.edditing ? props.cargaForm.datosEconomicos.subTotal : props.cargaEditForm.datosEconomicos.subTotal
                                    const importeProveedor = props.isEdditing !== addingOrEditing.edditing ? props.cargaForm.datosEconomicos.subTotalProveedor : props.cargaEditForm.datosEconomicos.subTotalProveedor
                                    let diferencia = 0
                                    if (importeProveedor) {
                                        diferencia = importeCliente - importeProveedor
                                    }
                                    return (
                                        <div>
                                            <span style={{
                                                fontWeight: "bold",
                                                marginTop: 15
                                            }}>{"SubContrata: " + value}
                                            </span>
                                            &nbsp;
                                            {diferencia > 0 &&
                                                <span style={{marginTop: 15}}>
                                                    <NumberFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowNegative={true}
                                                        value={diferencia}
                                                        className="form-control undefined"
                                                        renderText={value => {
                                                            return (
                                                                <span>
                                                                    {" (-" + value + " €)"}
                                                                </span>
                                                            )
                                                        }}
                                                    />
                                            </span>
                                            }
                                        </div>
                                    )
                                }}
                                suffix={" \u20AC"}
                            />
                        }
                    </div>
                </div>
            </div>


            {props.cargaEditForm.lineasViaje.length > 0 &&
                <ModalCmr
                    modalOpened={modalCmrOpen}
                    setModalOpened={setModalCmrOpen}
                    cmrInfo={props.cargaEditForm.lineasViaje[indexLineaCmr]?.destinations[indexDestCmr]?.cmrInfo || initInfoToCreateCMR}
                    setCmrInfo={(infoToCmr) => {
                        props.saveFormCarga({
                            ...props.cargaEditForm,
                            lineasViaje: props.cargaEditForm.lineasViaje.map((linea, index) => {
                                if (index !== indexLineaCmr) return linea;
                                const updatedDestinations = linea.destinations.map((destino, idx) => {
                                    if (idx !== indexDestCmr) return destino;
                                    return {...destino, cmrInfo: infoToCmr};
                                });
                                return {...linea, destinations: updatedDestinations};
                            }),
                        });
                    }}
                    reInitCmr={() => {
                        props.saveFormCarga({
                            ...props.cargaEditForm,
                            lineasViaje: props.cargaEditForm.lineasViaje.map((linea, indexLinea) => {
                                if (indexLinea !== indexLineaCmr) return linea;
                                const updatedDestinations = linea.destinations.map((destino, indexDest) => {
                                    if (indexDest !== indexDestCmr || !destino.cmrInfo) return destino;
                                    return {
                                        ...destino,
                                        cmrInfo: getInfoToCmrForCarga(props.cargaEditForm, linea, indexDest, "init", company),
                                    };
                                });
                                return {
                                    ...linea,
                                    destinations: updatedDestinations,
                                };
                            }),
                        });
                    }}
                />
            }

        </Fragment>

    );
};

export default CargaCompletaViajesList;
