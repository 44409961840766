import React, { ReactElement, ReactNode } from "react";
import imageLogo from "../../assets/newLogo3.png";
import './Register.css';

interface RegisterTemplateProps {
    children: ReactNode;
}

function RegisterTemplate({ children }: RegisterTemplateProps): ReactElement {
    return (
        <div id="register-page-container">
            <div id="register-side-bar">
                <img src={imageLogo} alt="Logo" />
                <p style={{ fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px" }}>
                    <span>El software de gestión en la nube diseñado </span>
                    <span>para pymes y profesionales del transporte</span>
                </p>
                <br></br>
                <p>
                    <span>Todo lo que necesitas para gestionar tu empresa </span>
                    <span>desde cualquier lugar</span>
                </p>
            </div>
            <div id="register-form-container">
                {children}
            </div>
        </div>
    );
}

export default RegisterTemplate;
