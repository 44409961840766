"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialControlVehicleType = exports.initialVehicle = exports.initialLastRevision = exports.initialVehicleType = exports.inicialiceVehicleValidateFieldsResult = void 0;
const commonEnums_1 = require("../enums/commonEnums");
exports.inicialiceVehicleValidateFieldsResult = {
    matriculaExists: false,
    anyFieldExist: false
};
exports.initialVehicleType = {
    id: 0,
    description: "",
    observaciones: "",
    estructura: "",
    tractoraRemolque: "",
    typeVehicle: "",
    capacidadCarga: 0,
    tieneTacografo: false
};
exports.initialLastRevision = {
    id: 0,
    fecha: new Date(),
    fecProxRev: new Date(),
    observaciones: "",
    km: 0,
    proxKm: 0
};
exports.initialVehicle = {
    _id: null,
    id: 0,
    marca: "",
    modelo: "",
    matricula: "",
    finItv: new Date(),
    fecAlta: new Date(),
    fecMatricula: new Date(),
    vehicleType: exports.initialVehicleType,
    lastControl: {
        id: 0,
        controlsList: [],
        controlsObservaciones: "",
        associatedVehicle: null
    },
    kms: 0,
    lastRevision: exports.initialLastRevision,
    revTacografo: new Date(),
    observaciones: ""
};
exports.initialControlVehicleType = {
    id: 0,
    result: "",
    fecAlta: new Date(),
    description: "",
    periodicidad: commonEnums_1.Periodicidades.diaria,
    observaciones: ""
};
