import {useDispatch, useSelector} from "react-redux";
import React, {Fragment} from "react";
import {ApplicationState} from '../../ApplicationState';
import Buttons from "../../common/Buttons/Buttons";
import {
    addOrEditCarga, addCarga, editCarga, saveFormCarga, setFormsCarga, saveSearchFilterCarga,
    deleteOneCargaCompleta, setValidateCargaForm, downloadCargaPDF, setHistoryAddListCarga,
} from "./CargaCompletaStore";
import CargaCompletaList from "./CargaCompletaList";
import CargaCompletaForm from "./CargaCompletaForm";
import {
    loginUsers, searchFilterList, cargaCompleta, inicialiceCargaCompletaValidateFieldsResult,
    addingOrEditing, UserPermissions, inicialiceCargaCompleta
} from "aseguisShared";
import {GetUserPermissionsFromLocalStorage,} from "../../common/commonInterfaces/commonFunctions";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import {requestOneClient} from "../Clients/ClientsStore";
import CargaCompletaHistoryList from "./CargaCompletaHistoryList";
import {requestOneSupplier} from "../Suppliers/SuppliersStore";


const CargaCompleta: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();

    let {permissions} = GetUserPermissionsFromLocalStorage()

    const searchFilterCarga: searchFilterList = useSelector((state: ApplicationState) => state.cargaCompleta.searchFilterCarga);
    const addedHistory: cargaCompleta[] = useSelector((state: ApplicationState) => state.cargaCompleta.historyAddList);
    const cargaForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaForm);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm);
    const addingOrEditCarga: addingOrEditing = useSelector((state: ApplicationState) => state.cargaCompleta.addOrEditCarga) || addingOrEditing.none;
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [restoreLines, setRestoreLines] = React.useState(false);

    const [errorForm, setErrorForm] = React.useState(false);

    function EditCarga(cargaCompleta: cargaCompleta): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsCarga(cargaCompleta));
        dispatch(addOrEditCarga(addingOrEditing.edditing));
    }

    function addCargaForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsCarga({
            ...inicialiceCargaCompleta,
        }));
        dispatch(addOrEditCarga(addingOrEditing.adding));
    }

    function addNew(): void {
        if (!errorForm) {
            dispatch(addCarga({
                ...cargaEditForm,
                datosGenerales: {
                    ...cargaEditForm.datosGenerales,
                    user: userLogged
                }
            }));
        }
        dispatch(setValidateCargaForm(inicialiceCargaCompletaValidateFieldsResult))
    }

    function saveEdited(): void {
        if (!errorForm) {
            dispatch(editCarga({
                ...cargaEditForm,
                idCargaRender: null,
                datosEntrega: {
                    ...cargaEditForm.datosEntrega,
                    fileRelation: []
                }
            }));
            setEditEnabled(addingOrEditing.none);
        }
    }

    function restoreCargaEdit(): void {
        if (cargaForm.datosGenerales.client._id) {
            requestOneClient(cargaForm.datosGenerales.client._id)
                .then((client) => {
                    if (cargaForm.datosGenerales.supplier._id && cargaForm.datosGenerales.supplier._id.length > 0) {
                        requestOneSupplier(cargaForm.datosGenerales.supplier._id)
                            .then((supplier) => {
                                dispatch(saveFormCarga({
                                    ...cargaForm,
                                    datosGenerales: {
                                        ...cargaForm.datosGenerales,
                                        client: client.data.clientsResults[0],
                                        supplier: supplier.data.suppliersResults[0]
                                    }
                                }));
                            })
                            .catch((error) => {
                                usePath.push(allAppRoutes.cargaCompletaList)
                            })
                    } else {
                        dispatch(saveFormCarga({
                            ...cargaForm,
                            datosGenerales: {
                                ...cargaForm.datosGenerales,
                                client: client.data.clientsResults[0]
                            }
                        }));
                    }
                })
                .catch((error) => {
                    usePath.push(allAppRoutes.cargaCompletaList)
                })
        }
        setRestoreLines(true)
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    const deleteCarga = () => {
        dispatch(deleteOneCargaCompleta(cargaEditForm));
        setEditEnabled(addingOrEditing.none);
        usePath.replace(allAppRoutes.cargaCompletaList);
    }

    return (

        <Fragment>

            <Switch>

                {(permissions.cargaCompleta !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.cargaCompletaList}>
                        <CargaCompletaList
                            onClick={(cargaUpdate) => {
                                EditCarga(cargaUpdate)
                                usePath.push({pathname: allAppRoutes.cargaCompletaForm + cargaUpdate._id});
                            }}
                            addCarga={() => {
                                addCargaForm()
                                usePath.push(allAppRoutes.cargaCompletaForm + "new");
                            }}
                            searchFilterCarga={searchFilterCarga}
                            saveSearchFilterCarga={(searchFilter) => {
                                dispatch(saveSearchFilterCarga(searchFilter));
                            }}
                        />
                    </Route>
                }

                {permissions.cargaCompleta !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.cargaCompletaForm + ":id"}>
                        <CargaCompletaForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            addOrEdit={addingOrEditCarga}
                            restoreLines={restoreLines}
                            setRestoreLines={setRestoreLines}
                            setErrorForm={setErrorForm}
                            errorForm={errorForm}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.cargaCompletaList}/>

            </Switch>

            {/*BOTON GUARDAR*/}
            {usePathname !== allAppRoutes.cargaCompletaList &&
                <Buttons
                    addOrEdit={addingOrEditCarga}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.cargaCompletaList);
                        dispatch(setHistoryAddListCarga([]))
                    }}
                    delete={(cargaEditForm.idBill === 0) ? deleteCarga : undefined}
                    deleteText={userLogged.name}
                    enableModify={true}
                    add={addNew}
                    save={saveEdited}
                    from="Carga completa"
                    thereIsList={true}
                    restoreForm={restoreCargaEdit}
                    disableSaveIfError={(errorForm === true) ? true : false}
                />
            }

            {(addedHistory.length > 0 && usePathname.includes(allAppRoutes.cargaCompletaForm) && addingOrEditCarga === addingOrEditing.adding) &&
                <div className="card shadow mb-4">
                    <div className="header-buttons">
                        <button
                            className="btn btn-info left-margin btn-sm"
                            onClick={() => {
                                var cargas: number[] = []
                                for (var val of addedHistory) {
                                    if (val._id) {
                                        cargas.push(val.id)
                                    }
                                }
                                dispatch(downloadCargaPDF(cargas, null))
                            }}>
                            Imprimir albaranes
                        </button>
                        <button
                            className="btn btn-secondary left-margin btn-sm"
                            onClick={() => {
                                dispatch(setHistoryAddListCarga([]))
                            }}>
                            Limpiar historial
                        </button>
                    </div>
                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">Historial</h6>
                    </div>
                    <div className="card-body">
                        <CargaCompletaHistoryList
                            isEdditing={editEnabled}
                            addOrEdit={addingOrEditCarga}
                            cargaCompletaForm={cargaForm}
                            cargaCompletaEditForm={cargaEditForm}
                        />
                    </div>
                </div>
            }

        </Fragment>

    );
};

export default CargaCompleta;
