import React, { Fragment, ReactElement  } from "react";
import InputText from "../../common/Inputs/InputText";
import InputNumber from "../../common/Inputs/InputNumber";
import {
    inicialiceSearchFilterList, searchFilterList,
    validateAlphaNumeric, addingOrEditing
} from "aseguisShared";

interface infoToVehiclesListFilter {
    searchFilterVehicles: searchFilterList
    saveSearchFilterVehicles: (filter: searchFilterList) => void
}
function VehiclesListFilter(props: infoToVehiclesListFilter): ReactElement {

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputNumber
                    small={true}
                    colMesure="2"
                    format="##########"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID Vehículo"
                    valueEdit={props.searchFilterVehicles.id}
                    value={props.searchFilterVehicles.id}
                    onChange={(value) => {
                        const valueNumber = value.floatValue ? value.floatValue : 0
                        props.saveSearchFilterVehicles({
                            ...props.searchFilterVehicles,
                            pageNum: 1,
                            id: valueNumber,
                            withTimeout: true
                        })                       
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    autoComplete={false}
                    addOrEdit={addingOrEditing.none}
                    name="Matrícula"
                    valueEdit={props.searchFilterVehicles.plateVehicle}
                    value={props.searchFilterVehicles.plateVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterVehicles({
                            ...props.searchFilterVehicles,
                            pageNum: 1,
                            plateVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Marca / modelo"
                    autoComplete={false}
                    valueEdit={props.searchFilterVehicles.marcaVehicle}
                    value={props.searchFilterVehicles.marcaVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterVehicles({
                            ...props.searchFilterVehicles,
                            pageNum: 1,
                            marcaVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Tipo de vehículo"
                    valueEdit={props.searchFilterVehicles.typeVehicle}
                    value={props.searchFilterVehicles.typeVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterVehicles({
                            ...props.searchFilterVehicles,
                            pageNum: 1,
                            typeVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-1"
                        onClick={() => {
                            props.saveSearchFilterVehicles({ ...props.searchFilterVehicles, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-1"
                        onClick={() => {
                            props.saveSearchFilterVehicles(inicialiceSearchFilterList)
                        }}>
                        Limpiar filtro
                        
                    </button>
                </div>

            </div>

        </Fragment >

    );
    
};

export default VehiclesListFilter;
