import {
    inicializeCargaCompletaTariff, tariffCargaCompleta, tariffValuesPaq,
    company, vehicleType, address, cargaCompleta, cobrarRetornoTypes,
    lineaViajeCarga, locationType, postalCode, calculateIva, getTariffPriority
} from "aseguisShared";

/***************************************** FUNCIONES PARA CALCULAR CARGAS COMPLETAS ****************************************/
/***************************************** FUNCIONES PARA CALCULAR CARGAS COMPLETAS ****************************************/
/***************************************** FUNCIONES PARA CALCULAR CARGAS COMPLETAS ****************************************/

//*** INTERFACES ***
//*** INTERFACES ***

export interface calculatedCargaTariff {
    text: string;
    eurosHora: number;
    subTotal: number;
    subTotalOriginal: number;
    total: number;
    descuento: number;
    iva: number;
    porIva: number;
    tariff: tariffCargaCompleta;
    type: locationType;
    incrementoAlCentro: number;
    subTotalProveedor: number
    tariffProveedor: tariffCargaCompleta;
    horasProveedor: number
}

export const inicializeCalculatedCargaTariff: calculatedCargaTariff = {
    text: 'No existe tarifa',
    eurosHora: 0,
    subTotal: 0,
    subTotalOriginal: 0,
    total: 0,
    descuento: 0,
    iva: 0,
    porIva: 0,
    tariff: inicializeCargaCompletaTariff,
    type: locationType.none,
    incrementoAlCentro: 0,
    subTotalProveedor: 0,
    tariffProveedor: inicializeCargaCompletaTariff,
    horasProveedor: 0
};

//***************************************************** CALCULAMOS POR HORAS ****************************************************************
//***************************************************** CALCULAMOS POR HORAS ****************************************************************
//***************************************************** CALCULAMOS POR HORAS ****************************************************************
export function calculateHorasCarga(lineaViajeHoras: lineaViajeCarga, cargaEditForm: cargaCompleta, company: company, recalculate: boolean): calculatedCargaTariff {

    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva

    let calculatedTariff: calculatedCargaTariff = {
        ...inicializeCalculatedCargaTariff,
        porIva: porIva,
    }

    if (lineaViajeHoras.horas > 0) {
        if (cargaEditForm.datosGenerales.client.tariff && cargaEditForm.datosGenerales.client.tariff.porHoras) {
            cargaEditForm.datosGenerales.client.tariff.porHoras.map(tariffHoras => {
                if (tariffHoras.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                    const calc = calculateHoras(tariffHoras, lineaViajeHoras, porIva, recalculate)
                    calculatedTariff = {...calc}
                }
            })
        }
        if (calculatedTariff.subTotal === 0) {
            if (company.tariff && company.tariff.porHoras) {
                company.tariff.porHoras.map(tariffHoras => {
                    if (tariffHoras.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                        const calc = calculateHoras(tariffHoras, lineaViajeHoras, porIva, recalculate)
                        calculatedTariff = {...calc}
                    }
                })
            }
        }

        //Calcular tarifas proveedor
        if (cargaEditForm.datosGenerales.supplier.tariff && cargaEditForm.datosGenerales.supplier.tariff.porHoras) {
            cargaEditForm.datosGenerales.supplier.tariff.porHoras.map(tariffHoras => {
                if (tariffHoras.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                    const newLineaViajeHoras: lineaViajeCarga = {
                        ...lineaViajeHoras,
                        horas: lineaViajeHoras.horasProveedor || lineaViajeHoras.horas
                    }
                    const calculoProveedor = calculateHoras(tariffHoras, newLineaViajeHoras, porIva, true)
                    calculatedTariff = {
                        ...calculatedTariff,
                        subTotalProveedor: calculoProveedor.subTotalOriginal,
                        tariffProveedor: calculoProveedor.tariff,
                        horasProveedor: newLineaViajeHoras.horas
                    }
                }
            })
        }

    }

    if (calculatedTariff.subTotal === 0) {
        calculatedTariff = {
            ...inicializeCalculatedCargaTariff,
            porIva: porIva,
            eurosHora: lineaViajeHoras.eurosHora ? lineaViajeHoras.eurosHora : 0,
            subTotal: (lineaViajeHoras.eurosHora && lineaViajeHoras.eurosHora > 0 && lineaViajeHoras.horas > 0) ? (lineaViajeHoras.eurosHora * lineaViajeHoras.horas) : lineaViajeHoras.importe,
            descuento: lineaViajeHoras.descuento,
        }
    }

    if (calculatedTariff.subTotal === 0 && lineaViajeHoras.eurosHora && lineaViajeHoras.eurosHora > 0 && lineaViajeHoras.horas > 0) {
        const importe = lineaViajeHoras.eurosHora * lineaViajeHoras.horas
        const importeIVA = calculateIva(importe, porIva)
        const total = importe + importeIVA

        calculatedTariff = ({
            ...calculatedTariff,
            eurosHora: lineaViajeHoras.eurosHora ? lineaViajeHoras.eurosHora : 0,
            subTotal: importe,
            subTotalOriginal: importe,
            total: total,
            iva: importeIVA,
            porIva: porIva,
        })
    }

    // si hay descuentos, los aplicamos
    if (lineaViajeHoras.descuento > 0) {
        const importToDiscount = (calculatedTariff.subTotal * lineaViajeHoras.descuento) / 100;
        const newSubtotal = calculatedTariff.subTotal - importToDiscount;
        const newIVA = calculateIva(newSubtotal, porIva)
        const newTotal = newSubtotal + newIVA
        calculatedTariff = ({
            ...calculatedTariff,
            subTotal: newSubtotal,
            subTotalOriginal: calculatedTariff.subTotal,
            descuento: lineaViajeHoras.descuento,
            total: newTotal,
            iva: newIVA,
            porIva: porIva,
        })
    }

    return calculatedTariff
}

/** CALCULAMOS HORAS **/
/** CALCULAMOS HORAS **/
/** CALCULAMOS HORAS **/
function calculateHoras(tariffCarga: tariffCargaCompleta, lineaViaje: lineaViajeCarga, porIva: number, recalculate: boolean): calculatedCargaTariff {
    var found = false
    var tariffMaxValue = 0
    var tariffHasta = 0
    var tariffValue = 0
    var calculatedTariff = {...inicializeCalculatedCargaTariff}
    var text = "No existe tarifa"
    const newValue = lineaViaje.horas

    tariffCarga.tariffs.map((tariff) => {

        var calculatedValue = 0
        var importe = 0

        if ((newValue <= tariff.hasta && newValue > 0) && (found === false)) {

            calculatedValue = tariff.value * newValue

            if (calculatedValue < tariffCarga.minImport && newValue > 0) {
                calculatedValue = tariffCarga.minImport
            }

            if (tariffMaxValue > calculatedValue) {
                calculatedValue = tariffMaxValue
            } else {
                tariffHasta = tariff.hasta
                tariffValue = tariff.value
            }

            if (lineaViaje.eurosHora && lineaViaje.eurosHora != tariff.value && recalculate === false) {
                calculatedValue = lineaViaje.eurosHora * newValue
                tariffHasta = 0
                tariffValue = lineaViaje.eurosHora
            }

            if (calculatedValue > importe) {
                text = "Tipo vehículo: " + tariffCarga.vehicleType.description + ", Hasta: " + tariffHasta + "h, Valor:  " + tariffValue + "€, Valor mín: " + tariffCarga.minImport + "€"

                importe = calculatedValue

                const importeIVA = calculateIva(importe, porIva)
                const total = importe + importeIVA

                if (importe > calculatedTariff.subTotal) {
                    calculatedTariff = ({
                        text: text,
                        eurosHora: tariffValue,
                        subTotal: importe,
                        subTotalOriginal: importe,
                        total: total,
                        descuento: 0,
                        iva: importeIVA,
                        porIva: porIva,
                        tariff: tariffCarga,
                        type: locationType.none,
                        incrementoAlCentro: 0,
                        subTotalProveedor: 0,
                        horasProveedor: 0,
                        tariffProveedor: inicializeCargaCompletaTariff
                    })
                }
            }

            found = true
        }

        tariffMaxValue = tariff.value * tariff.hasta
        tariffHasta = tariff.hasta
        tariffValue = tariff.value

    })

    return calculatedTariff
}

//***************************************************** CALCULAMOS POR DISTANCIA ****************************************************************
//***************************************************** CALCULAMOS POR DISTANCIA ****************************************************************
//***************************************************** CALCULAMOS POR DISTANCIA ****************************************************************
export function calculateDistanciaCarga(lineaViajeCarga: lineaViajeCarga, cargaEditForm: cargaCompleta, company: company): calculatedCargaTariff {

    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva

    var calculatedTariff: calculatedCargaTariff = {
        ...inicializeCalculatedCargaTariff,
        porIva: porIva,
    }

    if (lineaViajeCarga.totalDistance > 0) {

        if (cargaEditForm.datosGenerales.client.tariff && cargaEditForm.datosGenerales.client.tariff.porDistancia) {
            cargaEditForm.datosGenerales.client.tariff.porDistancia.map(tariffDistancia => {
                if (tariffDistancia.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                    const calc = calculateDistancia(tariffDistancia, lineaViajeCarga.totalDistance, porIva)
                    calculatedTariff = {...calc}
                }
            })
        }
        if (calculatedTariff.subTotal === 0) {
            if (company.tariff && company.tariff.porDistancia) {
                company.tariff.porDistancia.map(tariffDistancia => {
                    if (tariffDistancia.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                        const calc = calculateDistancia(tariffDistancia, lineaViajeCarga.totalDistance, porIva)
                        calculatedTariff = {...calc}
                    }
                })
            }
        }

        //Calcular tarifas proveedor
        if (cargaEditForm.datosGenerales.supplier.tariff && cargaEditForm.datosGenerales.supplier.tariff.porDistancia) {
            cargaEditForm.datosGenerales.supplier.tariff.porDistancia.map(tariffDistancia => {
                if (tariffDistancia.vehicleType.description === cargaEditForm.datosGenerales.typeVehicle.description) {
                    const calculoProveedor = calculateDistancia(tariffDistancia, lineaViajeCarga.totalDistance, porIva)
                    calculatedTariff = {
                        ...calculatedTariff,
                        subTotalProveedor: calculoProveedor.subTotalOriginal,
                        tariffProveedor: calculoProveedor.tariff
                    }
                }
            })
        }
    }

    if (calculatedTariff.subTotal === 0) {
        calculatedTariff = {
            ...inicializeCalculatedCargaTariff,
            porIva: porIva,
            subTotal: lineaViajeCarga.importe,
            descuento: lineaViajeCarga.descuento,
        }
    }

    //calculamos retorno
    if (calculatedTariff.subTotal > 0) {
        switch (lineaViajeCarga.cobrarRetorno) {
            case cobrarRetornoTypes.no: {
                break;
            }
            case cobrarRetornoTypes.medioViaje: {
                const medioViaje = calculatedTariff.subTotal / 2
                calculatedTariff.subTotal = calculatedTariff.subTotal + medioViaje;
                const medioViajeProveedor = calculatedTariff.subTotalProveedor / 2
                calculatedTariff.subTotalProveedor = calculatedTariff.subTotalProveedor + medioViajeProveedor;
                break;
            }
            case cobrarRetornoTypes.viajeCompleto: {
                calculatedTariff.subTotal = calculatedTariff.subTotal + calculatedTariff.subTotal
                calculatedTariff.subTotalProveedor = calculatedTariff.subTotalProveedor + calculatedTariff.subTotalProveedor;
                break;
            }
            default:
                break;
        }
    }


    // si hay descuentos, los aplicamos
    if (lineaViajeCarga.descuento > 0) {
        const importToDiscount = (calculatedTariff.subTotal * lineaViajeCarga.descuento) / 100;
        const newSubtotal = calculatedTariff.subTotal - importToDiscount;
        const newIVA = calculateIva(newSubtotal, porIva)
        const newTotal = newSubtotal + newIVA
        calculatedTariff = ({
            ...calculatedTariff,
            subTotal: newSubtotal,
            subTotalOriginal: calculatedTariff.subTotal,
            descuento: lineaViajeCarga.descuento,
            total: newTotal,
            iva: newIVA,
            porIva: porIva,
        })
    }


    return calculatedTariff
}

//*********** DISTANCIAS ************
function calculateDistancia(tariffCarga: tariffCargaCompleta, newValue: number, porIva: number): calculatedCargaTariff {
    var found = false
    var tariffMaxValue = 0
    var tariffHasta = 0
    var tariffValue = 0
    var calculatedTariff = {...inicializeCalculatedCargaTariff}
    var text = "No existe tarifa"

    tariffCarga.tariffs.map((tariff) => {

        var calculatedValue = 0
        var importe = 0

        if ((newValue <= tariff.hasta && newValue > 0) && (found === false)) {

            if (tariffCarga.multiplicateValue === true) {
                calculatedValue = tariff.value * newValue
            } else {
                calculatedValue = tariff.value
            }

            if (calculatedValue < tariffCarga.minImport && newValue > 0) {
                calculatedValue = tariffCarga.minImport
            }

            if (tariffMaxValue > calculatedValue) {
                calculatedValue = tariffMaxValue
            } else {
                tariffHasta = tariff.hasta
                tariffValue = tariff.value
            }

            if (calculatedValue > importe) {
                text = "Tipo vehículo: " + tariffCarga.vehicleType.description + ", Tarifa Hasta: " + tariffHasta + "km, Valor :  " + tariffValue + "€"

                importe = calculatedValue

                const importeIVA = calculateIva(importe, porIva)
                const total = importe + importeIVA

                if (importe > calculatedTariff.subTotal) {
                    calculatedTariff = ({
                        text: text,
                        eurosHora: 0,
                        subTotal: importe,
                        subTotalOriginal: importe,
                        total: total,
                        descuento: 0,
                        iva: importeIVA,
                        porIva: porIva,
                        tariff: tariffCarga,
                        type: locationType.none,
                        incrementoAlCentro: 0,
                        subTotalProveedor: 0,
                        horasProveedor: 0,
                        tariffProveedor: inicializeCargaCompletaTariff
                    })
                }
            }

            found = true
        }

        if (tariffCarga.multiplicateValue === true) {
            tariffMaxValue = tariff.value * tariff.hasta
        } else {
            tariffMaxValue = tariff.value
        }

        tariffHasta = tariff.hasta
        tariffValue = tariff.value

    })

    if (found === false && tariffCarga.auxImport > 0) {
        const lastTariffHasta = tariffCarga.tariffs[tariffCarga.tariffs.length - 1].hasta
        const lastTariffValue = tariffCarga.tariffs[tariffCarga.tariffs.length - 1].value

        const valueToCalc = newValue - lastTariffHasta

        const importCalculated = valueToCalc * tariffCarga.auxImport
        const importCalculated2 = importCalculated + lastTariffValue

        const importeIVA = calculateIva(importCalculated2, porIva)
        const total = importCalculated2 + importeIVA

        text = "Tipo vehículo: " + tariffCarga.vehicleType.description + ", Tarifa Hasta " + lastTariffHasta + "km: " + lastTariffValue + "€, más " /* valueToCalc*/ + (Math.round(valueToCalc * 100) / 100).toFixed(2) + "km a " + tariffCarga.auxImport + "€ el km: " + /*importCalculated */ (Math.round(importCalculated * 100) / 100).toFixed(2) + "€. Valor total: " + /*importCalculated2 */ (Math.round(importCalculated2 * 100) / 100).toFixed(2) + "€";

        calculatedTariff = ({
            text: text,
            eurosHora: 0,
            subTotal: importCalculated2,
            subTotalOriginal: importCalculated2,
            porIva: porIva,
            descuento: 0,
            total: total,
            iva: importeIVA,
            tariff: tariffCarga,
            type: locationType.none,
            incrementoAlCentro: 0,
            subTotalProveedor: 0,
            horasProveedor: 0,
            tariffProveedor: inicializeCargaCompletaTariff
        })
    }

    return calculatedTariff
}


//***************************************************** CALCULAMOS POR POBLACION ****************************************************************
//***************************************************** CALCULAMOS POR POBLACION ****************************************************************
//***************************************************** CALCULAMOS POR POBLACION ****************************************************************
export function calculatePoblacionCarga(lineaPoblacion: lineaViajeCarga, cargaEditForm: cargaCompleta, company: company): calculatedCargaTariff {

    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva
    var calculatedTariff = {...inicializeCalculatedCargaTariff, porIva: porIva}

    var DestinyAddress = lineaPoblacion.destinations[1].address
    var postalCode = lineaPoblacion.destinations[1].address.cpMongo


    if (DestinyAddress && cargaEditForm.datosGenerales.typeVehicle) {

        if (cargaEditForm.datosGenerales.client.tariff && cargaEditForm.datosGenerales.client.tariff.porPoblacion) {
            const clientCalc = calculateLineaDestinoCarga(DestinyAddress, cargaEditForm.datosGenerales.typeVehicle, cargaEditForm.datosGenerales.client.tariff.porPoblacion, postalCode, porIva)
            calculatedTariff = {...clientCalc}
        }
        if (calculatedTariff.subTotal === 0) {
            if (company.tariff && company.tariff.porPoblacion) {
                const companyCalc = calculateLineaDestinoCarga(DestinyAddress, cargaEditForm.datosGenerales.typeVehicle, company.tariff.porPoblacion, postalCode, porIva)
                calculatedTariff = {...companyCalc}
            }
        }

        //calculamos tarifa proveedor
        if (cargaEditForm.datosGenerales.supplier.tariff && cargaEditForm.datosGenerales.supplier.tariff.porPoblacion) {
            const supplierCalc = calculateLineaDestinoCarga(DestinyAddress, cargaEditForm.datosGenerales.typeVehicle, cargaEditForm.datosGenerales.supplier.tariff.porPoblacion, postalCode, porIva)
            calculatedTariff = {
                ...calculatedTariff,
                subTotalProveedor: supplierCalc.subTotalOriginal,
                tariffProveedor: supplierCalc.tariff
            }
        }

        let importeOriginal = calculatedTariff.subTotal
        if ((calculatedTariff.subTotal === 0) && (lineaPoblacion.importe > calculatedTariff.subTotal)) {
            calculatedTariff.subTotal = lineaPoblacion.importe
            importeOriginal = lineaPoblacion.importe
        }


        //miramos si hay incremento por distancia al centro
        let distanceKm = lineaPoblacion.centroDistanceValue / 1000 //de Metros a KM


        //Calculamos incremento por distancia al centro para cliente
        if (lineaPoblacion.tariffCarga.locTariffs[0] && lineaPoblacion.tariffCarga.locTariffs[0].extraTariffs.length > 0) {
            let found: tariffValuesPaq = {hasta: 0, value: 0}
            let importe = 0;
            lineaPoblacion.tariffCarga.locTariffs[0].extraTariffs.map((tariff) => {
                if ((distanceKm >= tariff.hasta)) {
                    found = tariff
                }
            })
            if (found.hasta > 0 && found.value > 0) {
                let text = calculatedTariff.text + " incremento distancia al centro: +" + found.value + " € (desde " + found.hasta + "km)"
                importe = calculatedTariff.subTotal + found.value
                const importeIVA = calculateIva(importe, porIva)
                const total = importe + importeIVA
                if (importe > calculatedTariff.subTotal) {
                    calculatedTariff = ({
                        ...calculatedTariff,
                        subTotalOriginal: importeOriginal,
                        subTotal: importe,
                        total: total,
                        iva: importeIVA,
                        porIva: porIva,
                        text: text,
                        incrementoAlCentro: found.value
                    })
                }
            }
        }


        //Calculamos incremento por distancia al centro para PROVEEDOR
        if (lineaPoblacion.tariffProveedor) {
            if (lineaPoblacion.tariffProveedor.locTariffs[0] && lineaPoblacion.tariffProveedor.locTariffs[0].extraTariffs.length > 0) {
                let found: tariffValuesPaq = {hasta: 0, value: 0}
                let importe = 0;
                lineaPoblacion.tariffProveedor.locTariffs[0].extraTariffs.map((tariff) => {
                    if ((distanceKm >= tariff.hasta)) {
                        found = tariff
                    }
                })
                if (found.hasta > 0 && found.value > 0) {
                    //let text = calculatedTariff.text + " incremento distancia al centro: +" + found.value + " € (desde " + found.hasta + "km)"
                    importe = calculatedTariff.subTotalProveedor + found.value
                    if (importe > calculatedTariff.subTotalProveedor) {
                        calculatedTariff = ({
                            ...calculatedTariff,
                            subTotalProveedor: importe,
                            //text: text,
                        })
                    }
                }
            }
        }


        //calculamos retorno
        if (calculatedTariff.subTotal > 0) {
            switch (lineaPoblacion.cobrarRetorno) {
                case cobrarRetornoTypes.no: {
                    break;
                }
                case cobrarRetornoTypes.medioViaje: {
                    const medioViaje = calculatedTariff.subTotal / 2
                    calculatedTariff.subTotal = calculatedTariff.subTotal + medioViaje;
                    const medioViajeProveedor = calculatedTariff.subTotalProveedor / 2
                    calculatedTariff.subTotalProveedor = calculatedTariff.subTotalProveedor + medioViajeProveedor;
                    break;
                }
                case cobrarRetornoTypes.viajeCompleto: {
                    calculatedTariff.subTotal = calculatedTariff.subTotal + calculatedTariff.subTotal
                    calculatedTariff.subTotalProveedor = calculatedTariff.subTotalProveedor + calculatedTariff.subTotalProveedor;
                    break;
                }
                default:
                    break;
            }
        }

        // si hay descuentos, los aplicamos
        if (lineaPoblacion.descuento > 0) {
            const importToDiscount = (calculatedTariff.subTotal * lineaPoblacion.descuento) / 100;
            const newSubtotal = calculatedTariff.subTotal - importToDiscount;
            const newIVA = calculateIva(newSubtotal, porIva)
            const newTotal = newSubtotal + newIVA
            calculatedTariff = ({
                ...calculatedTariff,
                subTotal: newSubtotal,
                subTotalOriginal: importeOriginal,
                descuento: lineaPoblacion.descuento,
                total: newTotal,
                iva: newIVA,
                porIva: porIva,
            })
        }

    }

    return calculatedTariff
}

/*** CALCULAMOS UNA LINEA VIAJE POBLACION ***/
/*** CALCULAMOS UNA LINEA VIAJE POBLACION ***/
export function calculateLineaDestinoCarga(DestinyAddress: address, vehiType: vehicleType, tariffs: tariffCargaCompleta[], postalCodeAddressInfo: postalCode, porIva: number): calculatedCargaTariff {

    var calculatedTariff = {...inicializeCalculatedCargaTariff}
    var maxPriorityTariff = 0

    if (DestinyAddress.codigoPostal !== '') {

        tariffs.map(tariffs => {

            if (tariffs.vehicleType.description === vehiType.description) {

                tariffs.locTariffs.map((locTariff) => {

                    var tariffMayorFound = false
                    var tariffEqualFound = false

                    let Pob = ""

                    if (
                        (locTariff.locationType === locationType.cp && locTariff.postalCode.idCp === postalCodeAddressInfo.idCp) ||
                        (locTariff.locationType === locationType.localidad && locTariff.postalCode.idPoblacion === postalCodeAddressInfo.idPoblacion) ||
                        (locTariff.locationType === locationType.provincia && locTariff.postalCode.idProvincia === postalCodeAddressInfo.idProvincia)
                    ) {
                        if (getTariffPriority(locTariff.locationType) > maxPriorityTariff) {
                            maxPriorityTariff = getTariffPriority(locTariff.locationType)
                            tariffMayorFound = true
                        }
                        if (getTariffPriority(locTariff.locationType) === maxPriorityTariff) {
                            maxPriorityTariff = getTariffPriority(locTariff.locationType)
                            tariffEqualFound = true
                        }


                        if (locTariff.locationType === locationType.cp && locTariff.postalCode.idCp === postalCodeAddressInfo.idCp) {
                            Pob = ", Localidad: " + locTariff.postalCode.poblacion + " (CP: " + locTariff.postalCode.cp + "), "
                        }

                        if (locTariff.locationType === locationType.localidad && locTariff.postalCode.idPoblacion === postalCodeAddressInfo.idPoblacion) {
                            Pob = ", Localidad: " + locTariff.postalCode.poblacion + ", "
                        }

                        if (locTariff.locationType === locationType.provincia && locTariff.postalCode.idProvincia === postalCodeAddressInfo.idProvincia) {
                            Pob = ", Provincia: " + locTariff.postalCode.provincia + ", "
                        }

                    }

                    if (tariffMayorFound) {
                        const text = "Tipo vehículo: " + tariffs.vehicleType.description + Pob + " Valor :  " + locTariff.value;

                        const importeIVA = calculateIva(locTariff.value, porIva)
                        const total = locTariff.value + importeIVA

                        calculatedTariff = ({
                            text: text,
                            eurosHora: 0,
                            subTotal: locTariff.value,
                            subTotalOriginal: locTariff.value,
                            total: total,
                            descuento: 0,
                            iva: importeIVA,
                            porIva: porIva,
                            tariff: {
                                ...tariffs,
                                locTariffs: [locTariff]
                            },
                            type: locTariff.locationType,
                            incrementoAlCentro: 0,
                            subTotalProveedor: 0,
                            horasProveedor: 0,
                            tariffProveedor: inicializeCargaCompletaTariff
                        })
                    }

                    if (tariffEqualFound) {
                        if (locTariff.value > calculatedTariff.subTotal) {
                            const text = "Tipo vehículo: " + tariffs.vehicleType.description + Pob + ", Valor :  " + locTariff.value;

                            const importeIVA = calculateIva(locTariff.value, porIva)
                            const total = locTariff.value + importeIVA

                            calculatedTariff = ({
                                text: text,
                                eurosHora: 0,
                                subTotal: locTariff.value,
                                subTotalOriginal: locTariff.value,
                                total: total,
                                descuento: 0,
                                porIva: porIva,
                                iva: importeIVA,
                                tariff: {
                                    ...tariffs,
                                    locTariffs: [locTariff]
                                },
                                type: locTariff.locationType,
                                incrementoAlCentro: 0,
                                subTotalProveedor: 0,
                                horasProveedor: 0,
                                tariffProveedor: inicializeCargaCompletaTariff
                            })
                        }
                    }

                })

            }

        })
    }

    return calculatedTariff
}


//********************** CALCULAMOS TODAS LAS LINEAS **********************************
//********************** CALCULAMOS TODAS LAS LINEAS **********************************
//********************** CALCULAMOS TODAS LAS LINEAS **********************************
export function calculateAllLineasCarga(lineasViaje: lineaViajeCarga[], ivaClient: number, ivaCompany: number): calculatedCargaTariff {

    const porIva = ivaClient > 0 ? ivaClient : ivaCompany

    let calculatedTariff = {...inicializeCalculatedCargaTariff, porIva: porIva};

    const {subTotal, subTotalProveedor} = lineasViaje.reduce((acumuladores, lineaViaje) => {
        acumuladores.subTotal += lineaViaje.importe;
        acumuladores.subTotalProveedor += (lineaViaje.importeProveedor || 0);
        return acumuladores;
    }, {subTotal: 0, subTotalProveedor: 0});

    const iva = porIva > 0 ? ((subTotal * porIva) / 100) : 0

    const total = subTotal + iva

    calculatedTariff = ({
        ...calculatedTariff,
        total: total,
        subTotal: subTotal,
        iva: iva,
        porIva: porIva,
        subTotalProveedor: subTotalProveedor
    })

    return calculatedTariff
}