import {useDispatch, useSelector} from "react-redux";
import React, {Fragment} from "react";
import {ApplicationState} from '../../ApplicationState';
import SuppliersList from "./SuppliersList";
import SupplierForm from "./SuppliersForm";
import {
    initialState, editSupplier, saveSupplierEditForm, deleteSupplier,
    addSupplier, addOrEditSupplier, setFormsSupplier, saveSearchFilterSupplier, requestOneSupplier
} from './SuppliersStore';
import {GetUserPermissionsFromLocalStorage} from "../../common/commonInterfaces/commonFunctions";
import {
    addingOrEditing, UserPermissions, supplier,
    searchFilterList, inicialicePermisosUsuario, inicialiceTariff, tariff
} from "aseguisShared";
import Buttons from "../../common/Buttons/Buttons";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import OptionButton from "../../common/Inputs/OptionButton";
import Tariff from "../../common/Tariff/Tariff";

const Suppliers: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const usePathname = useLocation().pathname;
    const usePath = useHistory()
    let {permissions} = GetUserPermissionsFromLocalStorage()

    const supplierForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierForm);
    const supplierEditForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierEditForm);
    const addingOrEdit: addingOrEditing = useSelector((state: ApplicationState) => state.suppliers.addOrEditSuppliers) || addingOrEditing.none;
    const searchFilterSuppliers: searchFilterList = useSelector((state: ApplicationState) => state.suppliers.searchFilterSuppliers);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);
    const [errorOnTariffs, setErrorOnTariffs] = React.useState(false);

    function saveTariff(tariff: tariff): void {
        dispatch(saveSupplierEditForm({...supplierEditForm, tariff: tariff}));
    }

    function editUser(supplier: supplier): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsSupplier(supplier));
        dispatch(addOrEditSupplier(addingOrEditing.edditing));
    }

    function addsupplierForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsSupplier(initialState.supplierForm));
        dispatch(addOrEditSupplier(addingOrEditing.adding));
    }

    function addNewSupplier(): void {
        if (errorOnForm === false && errorOnTariffs === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addSupplier(supplierEditForm));
        }
    }

    function saveEditedSupplier(): void {
        if (errorOnForm === false && errorOnTariffs === false) {
            setEditEnabled(addingOrEditing.none);
            if (supplierEditForm.email.length === 0) {
                dispatch(editSupplier({
                    ...supplierEditForm,
                    permisosUsuario: {
                        ...inicialicePermisosUsuario,
                        idAssociated: supplierEditForm.permisosUsuario.idAssociated
                    }
                }));
            } else {
                dispatch(editSupplier(supplierEditForm));
            }
        }
    }

    function restoreSupplierEdit(): void {
        dispatch(saveSupplierEditForm(supplierForm));
    }

    //para tener siempre la ultima versión...
    const getSupplier = async (idToSearch: string) => {
        if (idToSearch === "new") {
            dispatch(addOrEditSupplier(addingOrEditing.adding));
            setEditEnabled(addingOrEditing.edditing)
        } else {
            requestOneSupplier(idToSearch)
                .then((supplier) => {
                    if (supplier.data.suppliersResults.length === 1) {
                        dispatch(addOrEditSupplier(addingOrEditing.edditing));
                        dispatch(setFormsSupplier(supplier.data.suppliersResults[0]))
                    } else {
                        usePath.push(allAppRoutes.suppliersList)
                    }
                })
                .catch((error) => {
                    usePath.push(allAppRoutes.suppliersList)
                })
        }
    }
    //...para tener siempre la ultima versión

    return (

        <Fragment>

            {(usePathname !== allAppRoutes.suppliersList && permissions.proveedores !== UserPermissions.deshabilitado) &&
                <div className="row">
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.suppliersForm}
                            text={"Información general"}
                            linkTo={allAppRoutes.suppliersForm + (supplierEditForm._id ? supplierEditForm._id : "new")}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.suppliersTariffs}
                            text={"Tarifas"}
                            linkTo={allAppRoutes.suppliersTariffs + (supplierEditForm._id ? supplierEditForm._id : "new")}
                        />
                    </div>
                </div>
            }

            <Switch>
                {(permissions.proveedores !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.suppliersList}>
                        <SuppliersList
                            onClick={(supplier) => {
                                editUser(supplier)
                                usePath.push(allAppRoutes.suppliersForm + supplier._id);
                            }}
                            addSupplier={() => {
                                addsupplierForm()
                                usePath.push(allAppRoutes.suppliersForm + "new");
                            }}
                            searchSuppliersFilter={searchFilterSuppliers}
                            saveSearchSuppliersFilter={(searchFilterClients) => {
                                dispatch(saveSearchFilterSupplier(searchFilterClients));
                            }}
                        />
                    </Route>
                }
                {permissions.proveedores !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.suppliersForm + ":id"}>
                        <SupplierForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                            doOnRender={getSupplier}
                        />
                    </Route>
                }
                {permissions.proveedores !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.suppliersTariffs + ":id"}>
                        <Tariff
                            originalTariff={supplierForm.tariff || inicialiceTariff}
                            cardText={addingOrEdit !== addingOrEditing.adding ? ("# " + supplierEditForm.id) : "Añadir Proveedor"}
                            editTariff={supplierEditForm.tariff || inicialiceTariff}
                            setEditTariff={saveTariff}
                            isEdditing={editEnabled}
                            setErrorForm={setErrorOnTariffs}
                            errorForm={errorOnTariffs}
                            doOnRender={getSupplier}
                        />
                    </Route>
                }
                <Redirect from="*" to={allAppRoutes.suppliersList}/>
            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.suppliersList &&
                <Buttons
                    addOrEdit={addingOrEdit}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.suppliersList);
                    }}
                    add={addNewSupplier}
                    save={saveEditedSupplier}
                    from="proveedor"
                    thereIsList={true}
                    restoreForm={restoreSupplierEdit}
                    delete={() => {
                        dispatch(deleteSupplier(supplierEditForm));
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.suppliersList);
                    }}
                    deleteText={supplierEditForm.nif}
                    disableSaveIfError={(errorOnForm === true || errorOnTariffs === true) ? true : false}
                />
            }

        </Fragment>

    );
};

export default Suppliers;
