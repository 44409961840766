import React, { Fragment, ReactElement  } from "react";
import InputText from "../../common/Inputs/InputText";
import InputNumber from "../../common/Inputs/InputNumber";
import {
    addingOrEditing, validateAlphaNumeric, validateEmail, validateNumeric,
    inicialiceSearchFilterList, searchFilterList
} from "aseguisShared";

interface infoToEmployeesListFilter {
    searchFilterEmployees: searchFilterList
    saveSearchFilterEmployees: (filter: searchFilterList) => void
}
function EmployeesListFilter(props: infoToEmployeesListFilter): ReactElement {

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-25px" }}>

                <InputNumber
                    small={true}
                    colMesure="2"
                    format="##########"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID Trabajador"
                    valueEdit={props.searchFilterEmployees.id}
                    value={props.searchFilterEmployees.id}
                    onChange={(value) => {
                        const valueNumber = value.floatValue ? value.floatValue : 0
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            id: valueNumber,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Nif"
                    valueEdit={props.searchFilterEmployees.nif}
                    value={props.searchFilterEmployees.nif}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            nif: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Dirección"
                    valueEdit={props.searchFilterEmployees.address}
                    value={props.searchFilterEmployees.address}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            address: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Tel"
                    autoComplete={false}
                    valueEdit={props.searchFilterEmployees.tel}
                    value={props.searchFilterEmployees.tel}
                    onChange={(event) => {
                        var value = validateNumeric(event.target.value)
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            tel: value,
                            withTimeout: true
                        })
                    }}
                />

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    autoComplete={false}
                    addOrEdit={addingOrEditing.none}
                    name="Nombre"
                    valueEdit={props.searchFilterEmployees.employeeName}
                    value={props.searchFilterEmployees.employeeName}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            employeeName: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Email"
                    valueEdit={props.searchFilterEmployees.email}
                    value={props.searchFilterEmployees.email}
                    onChange={(event) => {
                        var value = validateEmail(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterEmployees({
                            ...props.searchFilterEmployees,
                            pageNum: 1,
                            email: value,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-1"
                        onClick={() => {
                            props.saveSearchFilterEmployees({ ...props.searchFilterEmployees, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-1"
                        onClick={() => {
                            props.saveSearchFilterEmployees(inicialiceSearchFilterList)
                            
                        }}>
                        Limpiar filtro
                        
                    </button>
                </div>

            </div>

        </Fragment >

    );
    
};

export default EmployeesListFilter;
