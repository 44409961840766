"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicializeSupplier = exports.inicialiceSupplierValidateFieldsResult = void 0;
const commonFunctions_1 = require("./commonFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
exports.inicialiceSupplierValidateFieldsResult = {
    nifExists: false,
    nombreExists: false,
    razonSocialExists: false,
    emailExists: false,
    tel1Exists: false,
    tel2Exists: false,
    anyFieldExist: false
};
exports.inicializeSupplier = {
    _id: null,
    fecAlta: new Date(),
    id: 0,
    nif: "",
    nombre: "",
    razonSocial: "",
    address: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    tel1: "",
    tel2: "",
    email: "",
    permisosUsuario: commonFunctions_1.inicialicePermisosUsuario,
    observaciones: "",
    tariff: tariffFunctions_1.inicialiceTariff,
};
