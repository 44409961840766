import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, ReactElement } from "react";
import { useHistory } from 'react-router-dom';
import { forgotPassword } from './LoginStore';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { isEmailValidate, logInRequest, validateEmail, validateFormEmail, } from "aseguisShared";
import RegisterTemplate from "../Register/RegisterTemplate";
import './../Register/Register.css';
import { ApplicationState } from "../../ApplicationState";

function ForgotPassword(): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const history = useHistory()

    const appLoading = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [formEmail, setFormEmail] = React.useState("")

    function emailChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = validateEmail(event.target.value)
        setFormEmail(value)
    }

    const passwordReset = async () => { // Añade async aquí
        if (isEmailValidate(formEmail) && !appLoading) {
            const logIn: logInRequest = {
                email: formEmail,
                password: "",
                from: "web"
            };
            try {
                await dispatch(forgotPassword(logIn)); // Añade await aquí
                history.push(allAppRoutes.login); // Esto ahora se ejecutará después de que la promesa se resuelva
            } catch (error) {
                console.error("Failed to reset password:", error);
                // Manejar el error aquí, si es necesario
            }
        }
    }

    return (

        <RegisterTemplate>

            <div className="login-container">

                <div>
                    <h5 className="h3 text-gray-900 mb-2 login-title">
                        <p>¡Vaya, parece que olvidaste tu contraseña! </p>
                    </h5>
                    <h5 className="h4 text-gray-900 login-subtitle">
                        <p>No te preocupes, solo necesitamos tu dirección de correo electrónico y te enviaremos un enlace para que puedas restablecer tu contraseña y estar de vuelta en un abrir y cerrar de ojos.</p>
                    </h5>
                </div>

                <br></br>

                <div className="form-group">
                    <input
                        id="register-input emailLogin forgotpassword"
                        name="emailLogin"
                        placeholder={"Correo electrónico"}
                        type="text"
                        autoComplete="off"
                        className={formEmail.length > 0 ? validateFormEmail(formEmail) : "form-control undefined"}
                        value={formEmail}
                        required
                        onChange={emailChanged}
                    />
                </div>

                <br></br>

                <div>
                    <button
                        className={"btn btn-primary btn-user btn-block"}
                        type="button"
                        disabled={!isEmailValidate(formEmail) || appLoading}
                        onClick={passwordReset}
                    >
                        {!appLoading ?
                            <span>¡Recuperar acceso!</span>
                            :
                            <span className="spinner-border spinner-border-sm"></span>
                        }
                    </button>
                </div>

                <div>
                    <button
                        className={"btn btn-secondary btn-user btn-block mt-2"}
                        type="button"
                        onClick={() => {
                            history.push(allAppRoutes.login)
                        }}
                    >
                        <span>Cancelar</span>
                    </button>
                </div>

            </div>

        </RegisterTemplate>

    );
}


export default ForgotPassword;
