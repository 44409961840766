import React, {Fragment, ReactElement} from "react";
import {inicialiceDate, inicialiceSearchFilterList, searchFilterList} from "aseguisShared"

interface infoToInputCalendarButtons {
    width: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8",
    searchFilter: searchFilterList
    setSearchFilter: (searchFilter: searchFilterList) => void;
}

function InputCalendarButtons(props: infoToInputCalendarButtons): ReactElement {

    return (
        <Fragment>

            <div className={"form-group col-md-" + props.width}>
                <br></br>

                <button
                    className="btn btn-info btn-sm mt-1"
                    disabled={
                        props.searchFilter.recogidaDesde.getTime() === inicialiceDate.year1000.getTime() ||
                        props.searchFilter.recogidaHasta.getTime() === inicialiceDate.year1000.getTime() ||
                        props.searchFilter.recogidaDesde.getTime() !== props.searchFilter.recogidaHasta.getTime()
                    }
                    style={{
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                    }}
                    onClick={() => {
                        const yesterday = new Date(props.searchFilter.recogidaDesde);
                        yesterday.setDate(yesterday.getDate() - 1);
                        props.setSearchFilter({
                            ...inicialiceSearchFilterList,
                            recogidaDesde: yesterday,
                            recogidaHasta: yesterday
                        });
                    }}
                >
                    <i className="fas fa-arrow-left"></i>
                </button>

                <button
                    className={"btn btn-info btn-sm mt-1"}
                    style={{
                        borderRadius: '0',
                        borderRight: 'none',
                    }}
                    onClick={() => {
                        props.setSearchFilter({
                            ...inicialiceSearchFilterList,
                            recogidaDesde: new Date(),
                            recogidaHasta: new Date()
                        })
                    }}
                >
                    Hoy
                </button>
                <button
                    className="btn btn-info btn-sm mt-1"
                    disabled={
                        props.searchFilter.recogidaDesde.getTime() === inicialiceDate.year1000.getTime() ||
                        props.searchFilter.recogidaHasta.getTime() === inicialiceDate.year1000.getTime() ||
                        props.searchFilter.recogidaDesde.getTime() !== props.searchFilter.recogidaHasta.getTime()
                    }
                    style={{
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                    }}
                    onClick={() => {
                        const tomorrow = new Date(props.searchFilter.recogidaDesde);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        props.setSearchFilter({
                            ...inicialiceSearchFilterList,
                            recogidaDesde: tomorrow,
                            recogidaHasta: tomorrow
                        });
                    }}
                >
                    <i className="fas fa-arrow-right"></i>
                </button>
            </div>

        </Fragment>

    );
};

export default InputCalendarButtons;