import React, { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { saveFormPaqueteria, addOrEditPaqueteria, setFormsPaqueteria, requestOnePaqueteria } from "./PaqueteriaStore";
import { requestCompany } from "../Company/CompanyStore";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import PaqueteriaFormData from "./PaqueteriaFormData";
import { urlParmsForm, addingOrEditing, paqueteria, suscriptionData } from "aseguisShared";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToPaqueteriaForm {
    isEdditing: number;
    setEdditing: (value: addingOrEditing) => void,
    addOrEdit: number,
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    add: () => void,
}

function PaqueteriaForm(props: infoToPaqueteriaForm): ReactElement {

    const dispatch = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const paqueteriaForm: paqueteria = useSelector((state: ApplicationState) => state.paqueteria.paqueteriaForm);
    const paqueteriaEditForm: paqueteria = useSelector((state: ApplicationState) => state.paqueteria.paqueteriaEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        /* obtenemos compania para coger tarifas generales*/
        dispatch(requestCompany());
    }, []);

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditPaqueteria(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOnePaqueteria(id)
                .then((paq) => {
                    if (paq.data.paqueteriasResults.length === 1) {
                        dispatch(addOrEditPaqueteria(addingOrEditing.edditing));
                        dispatch(setFormsPaqueteria(paq.data.paqueteriasResults[0]))
                    } else {
                        history.push(allAppRoutes.paqueteriaList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.paqueteriaList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    /*** PARA AÑADIR PAQUETERIA CON ALT+M ***/
    const [goToAdd, setGoToAdd] = useState(false)
    useEffect(() => {
        if (goToAdd === true && isAppLoading === false && suscriptionData.isSuscriptionActive) {
            const elementoActivo = document.activeElement as HTMLElement;
            elementoActivo?.blur?.();
            props.add()
            setGoToAdd(false)
        }
    }, [goToAdd])
    /*** PARA AÑADIR PAQUETERIA CONT ALT+M***/

    /*** para que se añada paqueteria con ALT+M... ***/
    const [addListener, setAddListener] = useState(false)

    const handleKeyUp = useCallback((event) => {
        //event.key === "µ" es para MAC
        if (event.altKey && (event.key === "m" || event.key === "M" || event.key === "µ") && suscriptionData.isSuscriptionActive) {
            setGoToAdd(true)
        }
    }, [setAddListener])

    useEffect(() => {
        if (!props.errorForm && props.addOrEdit === addingOrEditing.adding) {
            setAddListener(true)
        } else {
            setAddListener(false)
        }
    }, [props.errorForm, paqueteriaEditForm])

    useEffect(() => {
        if (addListener) {
            window.addEventListener("keyup", handleKeyUp)
        } else {
            window.removeEventListener("keyup", handleKeyUp)
        }
        return () => {
            window.removeEventListener("keyup", handleKeyUp)
        }
    }, [addListener, handleKeyUp])
    /*** ...para que se añada paqueteria con ALT+M ***/

    return (
        <Fragment>

            <PaqueteriaFormData
                isEdditing={props.isEdditing}
                addOrEdit={props.addOrEdit}
                setErrorForm={props.setErrorForm}
                errorForm={props.errorForm}
                paqueteriaForm={paqueteriaForm}
                paqueteriaEditForm={paqueteriaEditForm}
                saveFormPaqueteria={(paqueteria) => {
                    dispatch(saveFormPaqueteria(paqueteria))
                }}
            />

        </Fragment>

    );
};

export default PaqueteriaForm;