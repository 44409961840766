import React from 'react';
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../ApplicationState";

const ScrollToTop = () => {

    const carga = useSelector((state: ApplicationState) => state.cargaCompleta.searchFilterCarga.pageNum);
    const paqs = useSelector((state: ApplicationState) => state.paqueteria.searchFilterPaqueteria.pageNum);
    const clientes = useSelector((state: ApplicationState) => state.clients.searchFilterClients);
    const empleados = useSelector((state: ApplicationState) => state.employees.searchFilterEmployees);
    const proveedores = useSelector((state: ApplicationState) => state.suppliers.searchFilterSuppliers);
    const vehiculos = useSelector((state: ApplicationState) => state.vehicles.searchFilterVehicles);
    const vehiControl = useSelector((state: ApplicationState) => state.vehiclesControls.searchFilterControlVehicles);
    const reviRepa = useSelector((state: ApplicationState) => state.reviRepa.searchFilterReviRepa);
    const facturas = useSelector((state: ApplicationState) => state.bills.searchFilterBills);
    const abonos = useSelector((state: ApplicationState) => state.abonos.searchFilterAbonos);

    const {pathname} = useLocation();
    const topRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }, [carga, paqs, clientes, empleados,
        proveedores, vehiculos, vehiControl,
        reviRepa, facturas, abonos
    ]);

    useEffect(() => {
        if (topRef.current) {
            window.scrollTo({behavior: 'smooth'});
        }
    }, [pathname]);

    return <div ref={topRef}/>; // Elemento de anclaje
};

export default ScrollToTop;
