import React, {Fragment, ReactElement, useState, useEffect, useRef, ChangeEvent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from '../../ApplicationState';
import {
    saveSupplierEditForm,
    validateIfExistsFields,
    setValidateSupplierForm,
    addOrEditSupplier,
    setFormsSupplier,
    requestOneSupplier
} from './SuppliersStore';
import NumberFormat from 'react-number-format';
import {useHistory, useParams} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import {
    isEmailValidate, validateAlphaNumeric, validateEmail, validateFormEmail, validateFormEmpty,
    supplier, addingOrEditing, getFormattedDate, supplierValidateFieldsResult,
    CapitalizefirstLetter, formatedDirection, urlParmsForm
} from "aseguisShared";
import GoogleAndClientsSuggestions from "../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import InputText from "../../common/Inputs/InputText";
import {saveFormEmployee} from "../Employees/EmployeesStore";

interface infoToSupplierForm {
    isEdditing: number
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
    doOnRender?: (id: string) => void,
}

function SupplierForm(props: infoToSupplierForm): ReactElement {

    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch: React.Dispatch<any> = useDispatch();

    const supplier: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierForm);
    const editSupplierForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierEditForm);
    const validateFieldsForm: supplierValidateFieldsResult = useSelector((state: ApplicationState) => state.suppliers.validateFieldsForm);
    const addingOrEditSupp: addingOrEditing = useSelector((state: ApplicationState) => state.suppliers.addOrEditSuppliers) || addingOrEditing.none;
    const [errorAddressForm, setErrorAddressForm] = useState(false);
    const [goToValidateFields, setGoToValidateFields] = useState(false);

    useEffect(() => {
        if (
            (editSupplierForm.nif.length < 2) ||
            (editSupplierForm.nombre.length < 2) ||
            (editSupplierForm.razonSocial.length < 2) ||
            (editSupplierForm.email && editSupplierForm.email.length > 0 && isEmailValidate(editSupplierForm.email) === false)
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(errorAddressForm)
        }
    }, [editSupplierForm, errorAddressForm, validateFieldsForm.anyFieldExist])

    useEffect(() => {
        if (goToValidateFields === true) {
            dispatch(validateIfExistsFields(editSupplierForm));
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    //para tener siempre la ultima versión...
    let {id} = useParams<urlParmsForm>();
    useEffect(() => {
        if (props.doOnRender && id) {
            props.doOnRender(id)
        }
    }, [id]);

    return (
        <Fragment>
            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditSupp !== addingOrEditing.adding) &&
                            <span> {"# " + editSupplierForm.id.toString()}</span>
                        }
                        {(addingOrEditSupp === addingOrEditing.adding) &&
                            <span> {" Añadir Proveedor "}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Fecha de Alta</label>
                                <div>{getFormattedDate(editSupplierForm.fecAlta)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row" style={{marginTop: "20px"}}>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_nif">NIF / CIF</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editSupplierForm.nif}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            dispatch(saveSupplierEditForm({
                                                ...editSupplierForm,
                                                nif: value,
                                                fecAlta: addingOrEditSupp === addingOrEditing.adding ? new Date() : editSupplierForm.fecAlta
                                            }));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                nifExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editSupplierForm.nif, 2)}
                                        id="nif"/>
                                    :
                                    <input
                                        name="suppNif"
                                        disabled={true}
                                        type="text"
                                        value={supplier.nif}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.nifExists) &&
                                        <small>NIF ya asignado a otro proveedor</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}}>Dirección</label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={formatedDirection(supplier.address)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    activateClientsDirs={false}
                                    initialAddress={editSupplierForm.address}
                                    onClick={(dest, fromModal) => {
                                        dispatch(saveSupplierEditForm({
                                            ...editSupplierForm,
                                            address: dest.address,
                                            nombre: editSupplierForm.nombre.length > 0 ? editSupplierForm.nombre : dest.name,
                                            razonSocial: editSupplierForm.nombre.length > 0 ? editSupplierForm.nombre : dest.name,
                                            tel1: editSupplierForm.tel1.length > 0 ? editSupplierForm.tel1 : dest.tel,
                                        }));
                                    }}
                                    setErrorForm={setErrorAddressForm}
                                />
                            </div>
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="2"
                            autoComplete={false}
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditSupp}
                            name="Piso/Pta"
                            valueEdit={editSupplierForm.address.puerta}
                            value={supplier.address.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                dispatch(saveSupplierEditForm({
                                    ...editSupplierForm,
                                    address: {
                                        ...editSupplierForm.address,
                                        puerta: value
                                    }
                                }));
                            }}
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_name">Nombre</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editSupplierForm.nombre}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveSupplierEditForm({...editSupplierForm, nombre: value}));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                nombreExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editSupplierForm.nombre, 2)}
                                        id="nombre"/>
                                    :
                                    <input
                                        name="supName"
                                        disabled={true}
                                        type="text"
                                        value={supplier.nombre}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.nombreExists) &&
                                        <small> NOMBRE ya asignado a otro proveedor</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_razonSocial">Razón social</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editSupplierForm.razonSocial}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveSupplierEditForm({...editSupplierForm, razonSocial: value}));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                razonSocialExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editSupplierForm.razonSocial, 2)}
                                        id="razonSocial"/>
                                    :
                                    <input
                                        name="suprazonSocial"
                                        disabled={true}
                                        type="text"
                                        value={supplier.razonSocial}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.razonSocialExists) &&
                                        <small> RAZON SOCIAL ya asignado a otro proveedor</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_tel1">Tel. 1</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={editSupplierForm.tel1}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveSupplierEditForm({...editSupplierForm, tel1: value.value}));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                tel1Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}/>
                                    :
                                    <input
                                        name="suptel1"
                                        disabled={true}
                                        type="text"
                                        value={supplier.tel1}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.tel1Exists) &&
                                        <small> TEL ya asignado a otro proveedor</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_tel2">Tel. 2</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={editSupplierForm.tel2}
                                        id="tel2"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveSupplierEditForm({...editSupplierForm, tel2: value.value}));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                tel2Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}/>
                                    :
                                    <input
                                        name="suptel1"
                                        disabled={true}
                                        type="text"
                                        value={supplier.tel2}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.tel2Exists) &&
                                        <small> TEL ya asignado a otro proveedor</small>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_email">Email</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editSupplierForm.email}
                                        onChange={(event) => {
                                            var value = validateEmail(event.target.value)
                                            dispatch(saveSupplierEditForm({...editSupplierForm, email: value}));
                                            dispatch(setValidateSupplierForm({
                                                ...validateFieldsForm,
                                                emailExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={(editSupplierForm.email && editSupplierForm.email.length > 0) ? validateFormEmail(editSupplierForm.email) : "form-control"}
                                        id="email"/>
                                    :
                                    <input
                                        name="supemail"
                                        disabled={true}
                                        type="text"
                                        value={supplier.email}
                                        className="form-control undefined"
                                    />
                                }
                                <div className="text-danger" style={{height: "20px"}}>
                                    {(validateFieldsForm.emailExists) &&
                                        <small> EMAIL ya asignado a otro proveedor</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-8">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="observaciones">Observaciones</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="observaciones"
                                        type="text"
                                        value={editSupplierForm.observaciones}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            dispatch(saveSupplierEditForm({
                                                ...editSupplierForm,
                                                observaciones: event.target.value.trimStart()
                                            }));
                                        }}
                                        className="form-control undefined"/>
                                    :
                                    <div>
                                        <input
                                            className="form-control undefined"
                                            name="observ"
                                            disabled={true}
                                            type="text"
                                            value={supplier.observaciones ? supplier.observaciones : ""}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {/*(     // DE MOMENTO LO COMENTAMOS
                (props.isEdditing === addingOrEditing.edditing && editSupplierForm.email.length > 0) ||
                (props.isEdditing !== addingOrEditing.edditing && supplier.email.length > 0)
            ) &&
                <UserPermissionForm
                    userType={usersTypes.supplier}
                    userLoggedType={userLogged.userType}
                    userLogguedPermissions={userLogged.permisosUsuario}
                    isEdditing={props.isEdditing}
                    nif={editSupplierForm.nif}
                    associatedId={editSupplierForm.permisosUsuario.idAssociated}
                    permisosUsuario={supplier.permisosUsuario}
                    permisosUsuarioEdit={editSupplierForm.permisosUsuario}
                    setPermisosUsuarioEdit={(permisos) => {
                        dispatch(saveSupplierEditForm({
                            ...editSupplierForm,
                            permisosUsuario: permisos
                        }));
                    }}
                />
            */}


        </Fragment>

    );
};

export default SupplierForm;