import React, {Fragment, ReactElement, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from '../../ApplicationState';
import {saveCompanyForm} from './CompanyStore';
import NumberFormat from 'react-number-format';
import {
    validateFormEmpty, validateAlphaNumeric, validateEmail, CapitalizefirstLetter,
    formatedDirection, company, addingOrEditing, albaranesPaqueteriaQueMostrar,
    suscriptionData, tiposRegimenFiscal
} from "aseguisShared";
import GoogleAndClientsSuggestions from "../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import {GetSuscriptionDataFromLocalStorage} from "../../common/commonInterfaces/commonFunctions";
import {saveClientEditForm} from "../Clients/ClientsStore";
import InputText from "../../common/Inputs/InputText";

interface infoToCompanyForm {
    isEdditing: number,
    setEdditing: (number: number) => void,
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
}

function CompanyForm(props: infoToCompanyForm): ReactElement {

    const dispatch = useDispatch();
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const companyEditForm: company = useSelector((state: ApplicationState) => state.company.companyEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [errorAddressForm, setErrorAddressForm] = useState(false);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (
            (companyEditForm.nif.length < 2) ||
            (companyEditForm.nombre.length < 2) ||
            (companyEditForm.razonSocial.length < 2)
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(errorAddressForm)
        }
    }, [companyEditForm, errorAddressForm])

    const opcionesDías = [
        {value: 1, label: 'Lunes'},
        {value: 2, label: 'Martes'},
        {value: 3, label: 'Miércoles'},
        {value: 4, label: 'Jueves'},
        {value: 5, label: 'Viernes'},
        {value: 6, label: 'Sábado'},
        {value: 0, label: 'Domingo'},
    ];

    const opcionesEspana = [
        {value: "carta", label: 'Carta de Porte Nacional (España)'},
        {value: "cmr", label: 'CMR Electrónico (Internacional)'},
    ];

    const opcionesRegimen = [
        {value: tiposRegimenFiscal.estimacionDirecta, label: 'Régimen de Estimación Directa'},
        {value: tiposRegimenFiscal.irpf1, label: 'Retención IRPF Actividades Empresariales (1%)'},
    ];

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        <span> {"# " + companyEditForm.nombre}</span>
                    </h6>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_nif">NIF / CIF</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.nif}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            dispatch(saveCompanyForm({...companyEditForm, nif: value}));
                                        }}
                                        className={validateFormEmpty(companyEditForm.nif, 2)}
                                        id="nif"/>
                                    :
                                    <input
                                        name="companyNif"
                                        disabled={true}
                                        type="text"
                                        value={company.nif}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_name">Nombre</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.nombre}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveCompanyForm({...companyEditForm, nombre: value}));
                                        }}
                                        className={validateFormEmpty(companyEditForm.nombre, 2)}
                                        id="nombre"/>
                                    :
                                    <input
                                        name="companyName"
                                        disabled={true}
                                        type="text"
                                        value={company.nombre}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_razonSocial">Razón social</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.razonSocial}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveCompanyForm({...companyEditForm, razonSocial: value}));
                                        }}
                                        className={validateFormEmpty(companyEditForm.razonSocial, 2)}
                                        id="razonSocial"/>
                                    :
                                    <input
                                        name="companyrazonSocial"
                                        disabled={true}
                                        type="text"
                                        value={company.razonSocial}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_email">Email</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.email}
                                        onChange={(event) => {
                                            var value = validateEmail(event.target.value)
                                            dispatch(saveCompanyForm({...companyEditForm, email: value}));
                                        }}
                                        className="form-control undefined"
                                        id="email"/>
                                    :
                                    <input
                                        name="companyrazonSocial"
                                        disabled={true}
                                        type="text"
                                        value={company.email}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_email">Dirección web</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.webPage}
                                        onChange={(event) => {
                                            dispatch(saveCompanyForm({
                                                ...companyEditForm,
                                                webPage: event.target.value
                                            }));
                                        }}
                                        className="form-control undefined"
                                        id="webPage"/>
                                    :
                                    <input
                                        name="tel2Company"
                                        disabled={true}
                                        type="text"
                                        value={company.webPage}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_tel2">Dirección</label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={formatedDirection(company.address)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    activateClientsDirs={false}
                                    initialAddress={companyEditForm.address}
                                    onClick={(dest, fromModal) => {
                                        dispatch(saveCompanyForm({
                                            ...companyEditForm,
                                            address: dest.address,
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="2"
                            autoComplete={false}
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Piso/Pta"
                            valueEdit={companyEditForm.address.puerta}
                            value={company.address.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                dispatch(saveCompanyForm({
                                    ...companyEditForm,
                                    address: {
                                        ...companyEditForm.address,
                                        puerta: value
                                    }
                                }));
                            }}
                        />

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_tel1">Tel. 1</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={companyEditForm.tel1}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveCompanyForm({...companyEditForm, tel1: value.value}));
                                        }}/>
                                    :
                                    <input
                                        name="tel1Company"
                                        disabled={true}
                                        type="text"
                                        value={company.tel1}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_tel2">Tel. 2</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={companyEditForm.tel2}
                                        id="tel2"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveCompanyForm({...companyEditForm, tel2: value.value}));
                                        }}/>
                                    :
                                    <input
                                        name="tel2Company"
                                        disabled={true}
                                        type="text"
                                        value={company.tel2}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Otros Datos</h6>
                </div>
                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Importe I.V.A (%)</label>
                                {props.isEdditing === addingOrEditing.edditing ?
                                    <NumberFormat
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        suffix=" %"
                                        isAllowed={value => {
                                            if (value.floatValue == null) {
                                                return value.formattedValue === ''
                                            } else {
                                                return (value.floatValue <= 100 && value.floatValue >= 1)
                                            }
                                        }}
                                        allowNegative={false}
                                        value={companyEditForm.porIva}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveCompanyForm({
                                                ...companyEditForm,
                                                porIva: value.floatValue || 0
                                            }));
                                        }}/>
                                    :
                                    <NumberFormat
                                        renderText={value => {
                                            return (
                                                <input
                                                    name="ivacompany"
                                                    disabled={true}
                                                    type="text"
                                                    value={value}
                                                    className="form-control undefined"
                                                />
                                            )
                                        }}
                                        displayType="text"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        suffix={" %"}
                                        value={company.porIva}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_name">Núm cuenta bancaria</label>
                                {(props.isEdditing === addingOrEditing.edditing /*&& isAppLoading === false*/) ?
                                    <input
                                        type="text"
                                        value={companyEditForm.ccc}
                                        onChange={(event) => {
                                            var value = event.target.value.toUpperCase()
                                            dispatch(saveCompanyForm({...companyEditForm, ccc: value}));
                                        }}
                                        className="form-control undefined"
                                        id="ccc"/>
                                    :
                                    <input
                                        name="ccc"
                                        disabled={true}
                                        type="text"
                                        value={company.ccc}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_razonSocial">
                                    Tipo de carta de porte
                                </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <Select
                                        options={opcionesEspana}
                                        value={opcionesEspana.filter(option => companyEditForm.cmrCarta?.includes(option.value))}
                                        onChange={(selectedOption) => {
                                            dispatch(saveCompanyForm({
                                                ...companyEditForm,
                                                cmrCarta: selectedOption?.value || "cmr"
                                            }));
                                        }}
                                        closeMenuOnSelect={true}
                                        placeholder="Definir carta de porte en España"
                                        className="basic-select"
                                        classNamePrefix="select"
                                        noOptionsMessage={({inputValue}) => inputValue.length === 0 ? 'No hay opciones' : `No se encontraron resultados para "${inputValue}"`}
                                    />
                                    :
                                    <Select
                                        options={opcionesEspana}
                                        isDisabled={true}
                                        onChange={() => {
                                        }}
                                        isSearchable={false}
                                        isClearable={false}
                                        menuIsOpen={false}
                                        value={opcionesEspana.filter(option => company.cmrCarta?.includes(option.value))}
                                        closeMenuOnSelect={false}
                                        placeholder="Definir carta de porte en España"
                                        className="basic-select"
                                        classNamePrefix="select"
                                    />
                                }
                            </div>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Días festivos</label>
                                {props.isEdditing === addingOrEditing.edditing ?
                                    <Select
                                        options={opcionesDías}
                                        isMulti
                                        value={opcionesDías.filter(option => companyEditForm.diasFestivos?.includes(option.value))}
                                        onChange={(selectedOptions) => {
                                            const diasFestivos = selectedOptions.map(option => option.value);
                                            dispatch(saveCompanyForm({...companyEditForm, diasFestivos: diasFestivos}));
                                        }}
                                        closeMenuOnSelect={false}
                                        placeholder="Selecciona los días festivos de la semana"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        noOptionsMessage={({inputValue}) => inputValue.length === 0 ? 'No hay opciones' : `No se encontraron resultados para "${inputValue}"`}
                                    />
                                    :
                                    <Select
                                        options={opcionesDías}
                                        isMulti
                                        isDisabled={true}
                                        onChange={() => {
                                        }}
                                        isSearchable={false}
                                        isClearable={false}
                                        menuIsOpen={false}
                                        value={opcionesDías.filter(option => company.diasFestivos?.includes(option.value))}
                                        closeMenuOnSelect={false}
                                        placeholder="Sin días festivos seleccionados"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="regimenFiscal">
                                    Tipo de régimen fiscal
                                </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <Select
                                        options={opcionesRegimen}
                                        value={opcionesRegimen.filter(option => companyEditForm.regimenFiscal?.includes(option.value))}
                                        onChange={(selectedOption) => {
                                            dispatch(saveCompanyForm({
                                                ...companyEditForm,
                                                regimenFiscal: selectedOption?.value || tiposRegimenFiscal.estimacionDirecta
                                            }));
                                        }}
                                        closeMenuOnSelect={true}
                                        placeholder="Régimen de Estimación Directa"
                                        className="basic-select"
                                        classNamePrefix="select"
                                        noOptionsMessage={({inputValue}) => inputValue.length === 0 ? 'No hay opciones' : `No se encontraron resultados para "${inputValue}"`}
                                    />
                                    :
                                    <Select
                                        options={opcionesRegimen}
                                        isDisabled={true}
                                        isSearchable={false}
                                        isClearable={false}
                                        menuIsOpen={false}
                                        value={opcionesRegimen.filter(option => company.regimenFiscal?.includes(option.value))}
                                        closeMenuOnSelect={false}
                                        placeholder="Régimen de Estimación Directa"
                                        className="basic-select"
                                        classNamePrefix="select"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {(suscriptionData.paqueteria) &&
                <div className="card shadow mb-4">
                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">Ajustes albaranes paquetería</h6>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="form-group col-md-3">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Que mostrar en albaranes de
                                        entrada</label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={companyEditForm.albaranesPaqEntradaMostrar}
                                            className={"form-control undefined"}
                                            onChange={(event) => {
                                                dispatch(saveCompanyForm({
                                                    ...companyEditForm,
                                                    albaranesPaqEntradaMostrar: event.currentTarget.value
                                                }));
                                            }}>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.origenDestino}>{albaranesPaqueteriaQueMostrar.origenDestino}</option>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.clienteOrigen}>{albaranesPaqueteriaQueMostrar.clienteOrigen}</option>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.clienteDestino}>{albaranesPaqueteriaQueMostrar.clienteDestino}</option>
                                        </select>
                                        :
                                        <input
                                            name="queMostrarAlbaranesentrada"
                                            disabled={true}
                                            type="text"
                                            value={company.albaranesPaqEntradaMostrar}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Que mostrar en albaranes de
                                        salida</label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={companyEditForm.albaranesPaqSalidaMostrar}
                                            className={"form-control undefined"}
                                            onChange={(event) => {
                                                dispatch(saveCompanyForm({
                                                    ...companyEditForm,
                                                    albaranesPaqSalidaMostrar: event.currentTarget.value
                                                }));
                                            }}>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.origenDestino}>{albaranesPaqueteriaQueMostrar.origenDestino}</option>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.clienteOrigen}>{albaranesPaqueteriaQueMostrar.clienteOrigen}</option>
                                            <option
                                                value={albaranesPaqueteriaQueMostrar.clienteDestino}>{albaranesPaqueteriaQueMostrar.clienteDestino}</option>
                                        </select>
                                        :
                                        <input
                                            name="queMostrarAlbaranesSalida"
                                            disabled={true}
                                            type="text"
                                            value={company.albaranesPaqSalidaMostrar}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <div>
                                    <label
                                        style={{fontWeight: 'bold'}}
                                        htmlFor="input_iva"
                                    >
                                        Imprimir dos albaranes por página &nbsp;
                                        <span
                                            data-tip="Solo afecta a paquetería, el cambio se aplicará en los nuevos albaranes o en los existentes que se modifiquen. En carga completa siempre se imprime un albarán por página">(*)</span>
                                    </label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={companyEditForm.dosAlbaranesPorPag === true ? "Si" : "No"}
                                            className="form-control undefined"
                                            id="panelDeControl"
                                            onChange={(event) => {
                                                var result = false;
                                                if (event.currentTarget.value === "Si") {
                                                    var result = true;
                                                }
                                                dispatch(saveCompanyForm({
                                                    ...companyEditForm,
                                                    dosAlbaranesPorPag: result
                                                }));
                                            }}>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                        :
                                        <input
                                            name="dosPagPorAlbaran"
                                            disabled={true}
                                            type="text"
                                            value={company.dosAlbaranesPorPag ? "Si" : "No"}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            }

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Ajustes facturación</h6>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <div>
                                <label
                                    style={{fontWeight: 'bold'}}
                                    htmlFor="facturatedNotDelivery"
                                >
                                    Facturar albaranes no entregados
                                </label>
                                {props.isEdditing === addingOrEditing.edditing ?
                                    <select
                                        value={companyEditForm.billNotDelivered === true ? "Si" : "No"}
                                        className="form-control undefined"
                                        id="billNotDelivered"
                                        onChange={(event) => {
                                            var result = false;
                                            if (event.currentTarget.value === "Si") {
                                                var result = true;
                                            }
                                            dispatch(saveCompanyForm({...companyEditForm, billNotDelivered: result}));
                                        }}>
                                        <option value="Si">Si</option>
                                        <option value="No">No</option>
                                    </select>
                                    :
                                    <input
                                        name="facturarNoEntregados"
                                        disabled={true}
                                        type="text"
                                        value={company.billNotDelivered ? "Si" : "No"}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        {(suscriptionData.paqueteria) &&
                            <div className="form-group col-md-3">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Mostrar reexpediciones
                                        paquetería en facturas</label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={companyEditForm.showPaqsReexpsOnBills === true ? "Si" : "No"}
                                            className={"form-control undefined"}
                                            onChange={(event) => {
                                                var result = false;
                                                if (event.currentTarget.value === "Si") {
                                                    var result = true;
                                                }
                                                dispatch(saveCompanyForm({
                                                    ...companyEditForm,
                                                    showPaqsReexpsOnBills: result
                                                }));
                                            }}>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                        :
                                        <input
                                            name="mostrarReexBill"
                                            disabled={true}
                                            type="text"
                                            value={company.showPaqsReexpsOnBills ? "Si" : "No"}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                        {(suscriptionData.paqueteria) &&
                            <div className="form-group col-md-3">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Mostrar incidencias
                                        paquetería en facturas</label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={companyEditForm.showPaqsIncidencesOnBills === true ? "Si" : "No"}
                                            className={"form-control undefined"}
                                            onChange={(event) => {
                                                var result = false;
                                                if (event.currentTarget.value === "Si") {
                                                    var result = true;
                                                }
                                                dispatch(saveCompanyForm({
                                                    ...companyEditForm,
                                                    showPaqsIncidencesOnBills: result
                                                }));
                                            }}>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                        :
                                        <input
                                            name="mostrarIncBill"
                                            disabled={true}
                                            type="text"
                                            value={company.showPaqsIncidencesOnBills ? "Si" : "No"}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{fontWeight: 'bold'}} htmlFor="input_iva">Mostrar incidencias cargas en
                                    facturas</label>
                                {props.isEdditing === addingOrEditing.edditing ?
                                    <select
                                        value={companyEditForm.showCargasIncidencesOnBills === true ? "Si" : "No"}
                                        className={"form-control undefined"}
                                        onChange={(event) => {
                                            var result = false;
                                            if (event.currentTarget.value === "Si") {
                                                var result = true;
                                            }
                                            dispatch(saveCompanyForm({
                                                ...companyEditForm,
                                                showCargasIncidencesOnBills: result
                                            }));
                                        }}>
                                        <option value="Si">Si</option>
                                        <option value="No">No</option>
                                    </select>
                                    :
                                    <input
                                        name="mostrarIncBill"
                                        disabled={true}
                                        type="text"
                                        value={company.showCargasIncidencesOnBills ? "Si" : "No"}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReactTooltip/> {/*Para sacar notas*/}

        </Fragment>

    );
};

export default CompanyForm;