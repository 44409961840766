import {useSelector} from "react-redux";
import React, {Fragment, ReactElement, useState, ChangeEvent} from "react";
import {ApplicationState} from '../../../ApplicationState';
import {
    locTariffs,
    tariffCargaCompleta,
    lineaViajeCarga,
    compareAddress,
    formatedDirWithName,
    vehicleType, inicialiceLocTariff
} from 'aseguisShared';
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';

interface infoToFormTrayectoList {
    lineaTrayecto: lineaViajeCarga
    trayectosClient: tariffCargaCompleta[]
    trayectosCompany: tariffCargaCompleta[]
    trayectosProveedor: tariffCargaCompleta[]
    selectTrayecto: (locTariffs: locTariffs, locTariffsProveedor: locTariffs) => void
    vehicleType: vehicleType
}

function FormTrayectoList(props: infoToFormTrayectoList): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [trayectoSearch, setTrayectoSearch] = useState("")

    function buildTrayectos(): locTariffs[] {

        var trayectos: locTariffs[] = []

        if (props.trayectosClient.length > 0) {
            props.trayectosClient.map(trayectosClient => {
                if (trayectosClient.vehicleType.id === props.vehicleType.id) {
                    trayectosClient.locTariffs.map(locTariffsClient => {
                        if (trayectoSearch != "") {
                            const origen = formatedDirWithName(locTariffsClient.origin.address, locTariffsClient.origin.name)
                            const destino = formatedDirWithName(locTariffsClient.destiny.address, locTariffsClient.destiny.name)
                            if (origen.toLowerCase().includes(trayectoSearch) || destino.toLowerCase().includes(trayectoSearch)) {
                                trayectos.push(locTariffsClient)
                            }
                        } else {
                            trayectos.push(locTariffsClient)
                        }
                    })
                }
            })
        }

        if (props.trayectosCompany.length > 0) {
            props.trayectosCompany.map(trayectosCompany => {
                if (trayectosCompany.vehicleType.id === props.vehicleType.id) {
                    trayectosCompany.locTariffs.map(locTariffsCompany => {
                        if (trayectoSearch !== "") {
                            const origen = formatedDirWithName(locTariffsCompany.origin.address, locTariffsCompany.origin.name)
                            const destino = formatedDirWithName(locTariffsCompany.destiny.address, locTariffsCompany.destiny.name)
                            if (origen.toLowerCase().includes(trayectoSearch) || destino.toLowerCase().includes(trayectoSearch)) {
                                trayectos.push(locTariffsCompany)
                            }
                        } else {
                            trayectos.push(locTariffsCompany)
                        }
                    })
                }
            })
        }

        return trayectos
    }

    function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        setTrayectoSearch(event.target.value)
    }

    function renderSuggestion(direction: string): JSX.Element {
        const matches = Match(direction, trayectoSearch)
        const parts = Parse(direction, matches)

        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : '';
                    return (
                        <span className={className} key={index}>{part.text}</span>
                    );
                })}
            </span>
        );
    }

    const trayectosList: JSX.Element[] = buildTrayectos().map((trayecto, index) => {

        var selectedOrigin = false
        var selectedDestiny = false

        if (trayecto.origin && trayecto.origin.address && props.lineaTrayecto && props.lineaTrayecto.destinations[0] && props.lineaTrayecto.destinations[0].address) {
            if (compareAddress(trayecto.origin.address, props.lineaTrayecto.destinations[0].address)) {
                selectedOrigin = true
            }
        }

        if (trayecto.destiny && trayecto.destiny.address && props.lineaTrayecto && props.lineaTrayecto.destinations[1] && props.lineaTrayecto.destinations[1].address) {
            if (compareAddress(trayecto.destiny.address, props.lineaTrayecto.destinations[1].address)) {
                selectedDestiny = true
            }
        }

        return (
            <Fragment key={index}>
                {
                    <tr className={`table-row ${(selectedOrigin === true && selectedDestiny === true) ? "selected" : ""}`}
                        onClick={() => {

                            let locTariffProveedor = inicialiceLocTariff
                            props.trayectosProveedor.map(trayectosProveedor => {
                                if (trayectosProveedor.vehicleType.id === props.vehicleType.id) {
                                    trayectosProveedor.locTariffs.map(locTariffsProveedor => {
                                        if (compareAddress(locTariffsProveedor.origin.address, trayecto.origin.address) && compareAddress(locTariffsProveedor.destiny.address, trayecto.destiny.address)) {
                                            locTariffProveedor = locTariffsProveedor
                                        }
                                    })
                                }
                            })

                            props.selectTrayecto(trayecto, locTariffProveedor)
                        }}
                        key={index}
                    >
                        <td><span style={{fontWeight: "bold"}}>{index}</span></td>
                        <td>{renderSuggestion(formatedDirWithName(trayecto.origin.address, trayecto.origin.name))}</td>
                        <td>{renderSuggestion(formatedDirWithName(trayecto.destiny.address, trayecto.destiny.name))}</td>
                    </tr>
                }
            </Fragment>
        )

    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">

                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">Lista de trayectos</h6>

                        <label htmlFor="input_name"> </label>

                        <div>
                            <input
                                type="text"
                                name="carga_search"
                                value={trayectoSearch}
                                onChange={onValueChanged}
                                className="form-control undefined"
                                id="carga_search"
                                placeholder="Busque por origen o destino"/>
                        </div>
                    </div>

                    {(isAppLoading === false) ?
                        <div className="card-body">
                            <div className="table-responsive table-hover portlet-400">

                                <table className="table">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Origen</th>
                                        <th scope="col">Destino</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {buildTrayectos().length > 0 ?
                                        trayectosList
                                        :
                                        <tr>
                                            <th>{"No existen trayectos"}</th>
                                        </tr>
                                    }
                                    </tbody>

                                </table>
                            </div>
                        </div>

                        :

                        <div className="alert alert-success" style={{display: 'flex', justifyContent: 'center'}}>
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                        </div>
                    }

                </div>
            </div>


        </Fragment>

    );
};

export default FormTrayectoList;
