import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from '../../ApplicationState';
import {
    addingOrEditing, tiposArticuladosVehiculos, tiposEstructuraVehiculos,
    controlVehicle, getFormattedDate, getFormattedDateAndHour, controlVehicleTypes,
    initialVehicle, CalculatelistVehiclesControl, CapitalizefirstLetter,
    formatearMatricula, urlParmsForm,
    validateAlphaNumeric,
} from "aseguisShared"
import {
    addOrEditVehicleControl,
    requestOneVehicleControl,
    saveFormVehicleControl,
    setFormsVehicleControl
} from "./VehiclesControlsStore";
import InputNumber from "../../common/Inputs/InputNumber";
import {useHistory, useParams} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";
import VehicleAutocomplete from "../Vehicles/VehicleAutocomplete";

interface infoToVehicleForm {
    isEdditing: number;
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

export const VehiclesControlsForm = (props: infoToVehicleForm) => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const vehicleControlForm: controlVehicle = useSelector((state: ApplicationState) => state.vehiclesControls.controlVehicleForm);
    const vehicleControlEditForm: controlVehicle = useSelector((state: ApplicationState) => state.vehiclesControls.controlvehicleEditForm);
    const addOrEditControlVehicles: number = useSelector((state: ApplicationState) => state.vehiclesControls.addOrEditControlVehicles);
    const vehiclesControlsTypes: controlVehicleTypes[] = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.vehiclesControlesTypes);

    const [errorVehicleForm, setErrorVehicleForm] = useState(false);

    //para tener siempre la ultima versión...
    let history = useHistory()
    let {id} = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditVehicleControl(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneVehicleControl(id)
                .then((vehicleControl) => {
                    if (vehicleControl.data.controlsVehiclesResults.length === 1) {
                        dispatch(addOrEditVehicleControl(addingOrEditing.edditing));
                        dispatch(setFormsVehicleControl(vehicleControl.data.controlsVehiclesResults[0]))
                    } else {
                        history.push(allAppRoutes.vehiclesControlsList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.vehiclesControlsList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    const thereIsAnyControlNokWithoutNotes = () => {
        for (var control of vehicleControlEditForm.controlList) {
            if (control.result === 'nok' && control.observaciones.length === 0) {
                return true
            }
        }
        return false
    }
    useEffect(() => {
        if (
            (vehicleControlEditForm.controlList.length < 1) ||
            (thereIsAnyControlNokWithoutNotes())
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(errorVehicleForm)
        }
    }, [vehicleControlEditForm.controlList.length, thereIsAnyControlNokWithoutNotes()])

    const CalculatelistControls = CalculatelistVehiclesControl(
        vehiclesControlsTypes,
        vehicleControlEditForm.vehicle.lastControl.controlsList,
        (vehicleControlEditForm.vehicle.vehicleType.tractoraRemolque != "" ? vehicleControlEditForm.remolque.lastControl.controlsList : undefined)
    )

    interface checkIfExistInterface {
        encontrado: boolean,
        observaciones: string,
        result: string
    }

    function checkIfExistControl(controlType: controlVehicleTypes): checkIfExistInterface {
        var encontrado = false
        var observaciones = ""
        var result = ""
        vehicleControlEditForm.controlList.map((control) => {
            if (control.description == controlType.description) {
                encontrado = true
                observaciones = control.observaciones
                result = control.result
            }
        });
        return {encontrado, observaciones, result}
    }

    const listVehiclesControlTypesEditNoDone: JSX.Element[] = CalculatelistControls.noRealizados.map((controlType) => {
        const controlExists = checkIfExistControl(controlType).encontrado
        const observaciones = checkIfExistControl(controlType).observaciones
        const controlExistsResult = checkIfExistControl(controlType).result
        const originalObservaciones = controlType.observaciones
        return (
            <div className="form-group col-md-12" key={controlType.id}>
                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button
                        type="button"
                        className="btn btn-warning"
                        style={
                            controlExistsResult === "nok" ?
                                {
                                    backgroundColor: '#FF8F00',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    color: "white",
                                    width: 50,

                                    borderRightColor: 'white',
                                    borderColor: '#E3E3E3'
                                }
                                :
                                {
                                    backgroundColor: 'white',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    color: "black",
                                    width: 50,
                                    borderRightColor: 'white',
                                    borderColor: '#E3E3E3'
                                }}
                        onClick={() => {
                            if (!controlExists) {
                                dispatch(
                                    saveFormVehicleControl({
                                        ...vehicleControlEditForm,
                                        controlList:
                                            [...vehicleControlEditForm.controlList,
                                                {
                                                    ...controlType,
                                                    fecAlta: new Date(),
                                                    result: "nok",
                                                    observaciones: originalObservaciones
                                                }]
                                    })
                                )
                            } else {
                                dispatch(
                                    saveFormVehicleControl({
                                        ...vehicleControlEditForm,
                                        controlList: vehicleControlEditForm.controlList.map((control) => {
                                            if (control.description === controlType.description) {
                                                return {
                                                    ...control,
                                                    result: "nok",
                                                    observaciones: originalObservaciones
                                                }
                                            }
                                            return control;
                                        })
                                    })
                                )
                            }
                        }}
                    >
                        Mal
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary"
                        style={
                            (controlExistsResult !== "ok" && controlExistsResult !== "nok") ?
                                {
                                    backgroundColor: 'grey',
                                    color: "white",
                                    width: 50,
                                    borderRightColor: 'white',
                                    borderLeftColor: 'white',
                                    borderColor: '#E3E3E3'
                                }
                                :
                                {
                                    backgroundColor: 'white',
                                    color: "black",
                                    width: 50,
                                    borderRightColor: 'white',
                                    borderLeftColor: 'white',
                                    borderColor: '#E3E3E3'
                                }}
                        onClick={() => {
                            if (controlExists) {
                                dispatch(
                                    saveFormVehicleControl({
                                        ...vehicleControlEditForm,
                                        controlList: vehicleControlEditForm.controlList.filter(control => control.id != controlType.id)
                                    })
                                )
                            }
                        }}
                    >
                        -
                    </button>

                    <button
                        type="button"
                        className="btn btn-success"
                        style={
                            controlExistsResult === "ok" ?
                                {
                                    backgroundColor: '#2eb62c',
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    color: "white",
                                    width: 50,
                                    borderLeftColor: 'white',
                                    borderColor: '#E3E3E3',
                                }
                                :
                                {
                                    backgroundColor: 'white',
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    color: "black",
                                    width: 50,
                                    borderLeftColor: 'white',
                                    borderColor: '#E3E3E3'
                                }}

                        onClick={() => {
                            if (!controlExists) {
                                dispatch(
                                    saveFormVehicleControl({
                                        ...vehicleControlEditForm,
                                        controlList:
                                            [...vehicleControlEditForm.controlList,
                                                {
                                                    ...controlType,
                                                    fecAlta: new Date(),
                                                    result: "ok",
                                                    observaciones: ""
                                                }]
                                    })
                                )
                            } else {
                                dispatch(
                                    saveFormVehicleControl({
                                        ...vehicleControlEditForm,
                                        controlList: vehicleControlEditForm.controlList.map((control) => {
                                            if (control.description === controlType.description) {
                                                return {
                                                    ...control,
                                                    result: "ok",
                                                    observaciones: ""
                                                }
                                            }
                                            return control;
                                        })
                                    })
                                )
                            }
                        }}
                    >
                        Bien
                    </button>
                </div>

                {(vehicleControlEditForm.controlList.length < 1) ?
                    <span style={{color: '#E23F3F'}}> &nbsp; {controlType.description} &nbsp;&nbsp;&nbsp;&nbsp; </span>
                    :
                    <span> &nbsp; {controlType.description} &nbsp;&nbsp;&nbsp;&nbsp; </span>
                }
                {(controlExistsResult === "nok") &&
                    <input
                        type="text"
                        value={observaciones}
                        onChange={(event) => {
                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                            dispatch(
                                saveFormVehicleControl({
                                    ...vehicleControlEditForm,
                                    controlList: vehicleControlEditForm.controlList.map((control) => {
                                        if (control.description === controlType.description) {
                                            return {
                                                ...control,
                                                observaciones: value
                                            }
                                        }
                                        return control;
                                    })
                                })
                            );
                        }}
                        className="form-control red-placeholder"
                        placeholder="Anota las irregularidades"
                        id="Notas"
                    />
                }
            </div>
        )
    });

    const listVehiclesControlTypesEditDone: JSX.Element[] = CalculatelistControls.realizados.map((controlType, index) => {
        return (
            <div key={controlType.id}>
                {controlType.description + " - proximo control el " + getFormattedDate(CalculatelistControls.proxDates[index])}
            </div>
        )
    });

    const listVehiclesControlTypes: JSX.Element[] = vehicleControlForm.controlList.map((controlType) => {
        return (
            <li key={controlType.id}>
                <b>{controlType.result}</b>
                {" - "}
                <u>{controlType.description}</u>
                {(controlType.observaciones !== "" && controlType.observaciones !== null) && <><b> -
                    Nota: </b> {" " + controlType.observaciones} </>}
            </li>
        )
    });

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addOrEditControlVehicles !== addingOrEditing.adding) &&
                            <span> {"# " + vehicleControlEditForm.id.toString()}</span>
                        }
                        {(addOrEditControlVehicles === addingOrEditing.adding) &&
                            <span> {" Añadir Control Vehículo "}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">
                    <form>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Fecha de alta</label>
                                    <div>{getFormattedDateAndHour(vehicleControlEditForm.fecAlta)}</div>
                                </div>
                            </div>
                            {(props.isEdditing !== addingOrEditing.edditing) &&
                                <div className="form-group col-md-4">
                                    <div>
                                        <label style={{fontWeight: 'bold'}} htmlFor="fecAlta">Insertado por</label>
                                        <input
                                            name="vehConInsertadoPor"
                                            disabled={true}
                                            type="text"
                                            value={vehicleControlEditForm.user.name + " " + vehicleControlEditForm.user.apellido1 + " " + vehicleControlEditForm.user.apellido2 + " "}
                                            className="form-control undefined"
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        &nbsp;

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{fontWeight: 'bold'}} htmlFor="name">Vehículo</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <VehicleAutocomplete
                                            vehicleName={vehicleControlEditForm.vehicle.matricula}
                                            setVehicle={(vehicle) => {
                                                dispatch(
                                                    saveFormVehicleControl({
                                                        ...vehicleControlEditForm,
                                                        vehicle: vehicle,
                                                        remolque: initialVehicle,
                                                        fecAlta: addOrEditControlVehicles === addingOrEditing.adding ? new Date() : vehicleControlEditForm.fecAlta
                                                    })
                                                );
                                            }}
                                            setErrorForm={setErrorVehicleForm}
                                            whatShow={"camiones"}
                                        />
                                        :
                                        <input
                                            name="vehConVehicle"
                                            disabled={true}
                                            type="text"
                                            value={formatearMatricula(vehicleControlForm.vehicle.matricula + " - " + vehicleControlForm.vehicle.marca + " " + vehicleControlForm.vehicle.modelo)}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>

                            {(
                                    vehicleControlEditForm.vehicle.vehicleType.estructura === tiposEstructuraVehiculos.articulado &&
                                    vehicleControlEditForm.vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.tractora
                                ) &&
                                <div className="form-group col-md-4" key={vehicleControlEditForm.remolque.matricula}>
                                    <div>
                                        <label style={{fontWeight: 'bold'}} htmlFor="name">Remolque</label>
                                        {(props.isEdditing === addingOrEditing.edditing) ?
                                            <VehicleAutocomplete
                                                vehicleName={vehicleControlEditForm.remolque.matricula}
                                                setVehicle={(vehicle) => {
                                                    dispatch(
                                                        saveFormVehicleControl({
                                                            ...vehicleControlEditForm,
                                                            remolque: vehicle,
                                                            fecAlta: addOrEditControlVehicles === addingOrEditing.adding ? new Date() : vehicleControlEditForm.fecAlta
                                                        })
                                                    );
                                                }}
                                                setErrorForm={setErrorVehicleForm}
                                                whatShow={"remolques"}
                                            />
                                            :
                                            <input
                                                name="vehConRemolque"
                                                disabled={true}
                                                type="text"
                                                value={formatearMatricula(vehicleControlForm.remolque.matricula + " - " + vehicleControlForm.remolque.marca + " " + vehicleControlForm.remolque.modelo)}
                                                className="form-control undefined"
                                            />
                                        }
                                    </div>
                                </div>
                            }

                        </div>

                        &nbsp;

                        {(
                                (
                                    props.isEdditing === addingOrEditing.edditing &&
                                    vehicleControlEditForm.vehicle.matricula !== "" &&
                                    (
                                        (
                                            vehicleControlEditForm.vehicle.vehicleType.estructura === tiposEstructuraVehiculos.rigido
                                        ) ||
                                        (
                                            vehicleControlEditForm.vehicle.vehicleType.estructura === tiposEstructuraVehiculos.articulado &&
                                            vehicleControlEditForm.remolque.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.remolque
                                        )
                                    )
                                )
                                ||
                                (
                                    props.isEdditing !== addingOrEditing.edditing &&
                                    vehicleControlForm.vehicle.matricula !== ""
                                )
                            ) &&

                            <div>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <div>
                                                <label style={{fontWeight: 'bold'}} htmlFor="name">Controles por
                                                    realizar:</label>
                                                {listVehiclesControlTypesEditNoDone}
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div>
                                                <label style={{fontWeight: 'bold'}} htmlFor="name">Controles ya
                                                    realizados :</label>
                                                {CalculatelistControls.realizados.length > 0 ? listVehiclesControlTypesEditDone : " (0)"}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group md-6">
                                        <div>
                                            <label style={{fontWeight: 'bold'}} htmlFor="name">Controles
                                                realizados</label>
                                            {listVehiclesControlTypes}
                                        </div>
                                        <div>&nbsp;</div>
                                    </div>
                                }

                                <div className="form-row">
                                    <InputNumber
                                        colMesure="4"
                                        editEnabled={props.isEdditing}
                                        addOrEdit={addingOrEditing.adding}
                                        name="Km"
                                        valueEdit={vehicleControlEditForm.kms}
                                        value={vehicleControlForm.kms}
                                        onChange={(value) => {
                                            dispatch(
                                                saveFormVehicleControl({
                                                    ...vehicleControlEditForm,
                                                    kms: value.floatValue ? value.floatValue : 0
                                                })
                                            );
                                        }}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        withoutformRow={true}
                                    />
                                </div>


                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="form-group md-12">
                                        <div>
                                            <label style={{fontWeight: 'bold'}} htmlFor="email">Observaciones</label>
                                            <input
                                                type="text"
                                                value={vehicleControlEditForm.observaciones}
                                                onChange={(event) => {
                                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                    dispatch(
                                                        saveFormVehicleControl({
                                                            ...vehicleControlEditForm,
                                                            observaciones: value
                                                        })
                                                    );
                                                }}
                                                className="form-control undefined"
                                                placeholder="Alguna otra observacion..."
                                                id="email"/>
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group md-12">
                                        <label style={{fontWeight: 'bold'}} htmlFor="email">Observaciones</label>
                                        <input
                                            name="vehConDesc"
                                            disabled={true}
                                            type="text"
                                            value={(vehicleControlForm.observaciones === "") ? "Sin observaciones" : vehicleControlForm.observaciones}
                                            className="form-control undefined"
                                        />
                                    </div>
                                }

                            </div>
                        }

                    </form>
                </div>
            </div>

        </Fragment>

    );
};

export default VehiclesControlsForm;

