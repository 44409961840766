"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAlphaNumeric = validateAlphaNumeric;
exports.validateNumeric = validateNumeric;
exports.validateNumericWithSum = validateNumericWithSum;
exports.validateNumericWithSumAndMultiplicate = validateNumericWithSumAndMultiplicate;
exports.validateEmail = validateEmail;
exports.toCapitalLetters = toCapitalLetters;
exports.validateFormEmpty = validateFormEmpty;
exports.validateNumberFormEmpty = validateNumberFormEmpty;
exports.validateFormEmail = validateFormEmail;
exports.validateManyEmails = validateManyEmails;
exports.isEmailValidate = isEmailValidate;
exports.isManyEmailValidate = isManyEmailValidate;
exports.validateDateLteTomorrow = validateDateLteTomorrow;
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
function validateAlphaNumeric(value, withoutSpaces, withAccents) {
    return value;
    /*let alphaNumeric: RegExp
    if (withoutSpaces) {
        alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u00e1\u00e0\u00e8\u00ec\u00f2\u00f9\u00e9\u00ed\u00f3\u0027\u0026\u2019\u00fa\u002E\u00c1\u00c9\u005F\u002D\u00cd\u002C\u00d3\u00da]+)/ //quitamos el espacio como valido y aceptamos lo de abajo
    } else {
        alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u00e1\u00e0\u00e8\u00ec\u00f2\u00f9\u00e9\u00ed\u00f3\u0027\u0026\u2019\u00fa\u002E\u00c1\u00c9\u005F\u002D\u00cd\u002C\u00d3\u00da\ ]+)/ //ACEPTAMOS DE A-Z, a-z, 0-9, los caracteres �,�,�,�, acento, punto, coma, guion, guion bajo, apostrofe y ampersand (caracteres unicode)
    }
    let ReturnAlphaNumeric = value.replace(alphaNumeric, '')
    ReturnAlphaNumeric = ReturnAlphaNumeric.trimStart()
    return ReturnAlphaNumeric*/
}
function validateNumeric(value) {
    var regexNumeric = /([^0-9]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
function validateNumericWithSum(value) {
    var regexNumeric = /([^0-9\u002B\u002C]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
function validateNumericWithSumAndMultiplicate(value) {
    var regexNumeric = /([^0-9\u002B\u002C\u002A\u002E]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
function validateEmail(value) {
    var regexEmail = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u0040\u002E\u002D\u005F]+)/; /* alfanumerico mas punto, _, -, y @*/
    var email = value.replace(regexEmail, '');
    email = email.toLowerCase().trimStart();
    return email;
}
/*export function validateText(value: string): string {
    var regexEmail = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u0040\u002E\u002D\u005F\u002C]+)/   //email mas coma
    var email = value.replace(regexEmail, '')
    return email
}*/
function toCapitalLetters(value) {
    return value.toUpperCase();
}
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
function validateFormEmpty(value, valueLenghtMin) {
    if (value !== undefined && value.length !== undefined && valueLenghtMin !== undefined) {
        if (value.length < valueLenghtMin) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
    else {
        return "form-control undefined";
    }
}
function validateNumberFormEmpty(value, valueToBeMin) {
    if (valueToBeMin && valueToBeMin > 0) {
        if (value < valueToBeMin) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
    else {
        if (value === 0) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
}
function validateFormEmail(value) {
    if (isEmailValidate(value) === false) {
        return "form-control is-invalid";
    }
    else {
        return "form-control undefined";
    }
}
function validateManyEmails(emailsInput) {
    const emails = emailsInput.split(" ");
    for (var email of emails) {
        if (isEmailValidate(email) === false) {
            return "form-control is-invalid";
        }
    }
    return "form-control undefined";
}
function isEmailValidate(search) {
    var serchfind;
    let regexp2 = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
    serchfind = regexp2.test(search);
    return serchfind;
}
function isManyEmailValidate(search) {
    const emails = search.split(/\s+/);
    let regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
    for (let email of emails) {
        if (!regexp.test(email)) {
            return false;
        }
    }
    return true;
}
function validateDateLteTomorrow(date) {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var tomorrow = new Date(year, month, day + 1);
    if (date <= tomorrow) {
        return "form-control is-invalid";
    }
    else {
        return "form-control";
    }
}
